<template>
  <f7-page
    class="content-list-order main-selectAction  bg-cream"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${$t('SelectAction_Title')}`"
    />
    <div class="container">
      <!-- PESTS -->
      <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        @click="navigateToForm('pests')"
      >
        {{ $t("SelectAction_Button_Pests") }}
      </f7-button>

      <!-- DISEASES -->
      <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        @click="navigateToForm('diseases')"
      >
        {{ $t("SelectAction_Button_Diseases") }}
      </f7-button>

      <!-- PHENOLOGY -->
      <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        @click="navigateToForm('phenology')"
      >
        {{ $t("SelectAction_Button_Phenology") }}
      </f7-button>

      <!-- EXTREME WEATHER CONDICIONS -->
      <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        @click="navigateToForm('extremeWeatherConditions')"
      >
        {{ $t("SelectAction_Button_ExtremeWeatherConditions") }}
      </f7-button>

      <!-- OTHERS -->
      <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        @click="navigateToForm('others')"
      >
        {{ $t("SelectAction_Button_Others") }}
      </f7-button>

      <!-- FINISH -->
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green margin"
        col
        button
        button-large
        button-raised
        fill
        @click="finish()"
      >
        {{ $t("SelectAction_Button_Finish") }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import navbar from '../../components/NavBar.vue';
import PhenologicalStates from '../../js/models/PhenologicalStates';
import PlaguesAndDiseases from '../../js/models/plaguesAndDiseases';

export default {
  name: 'SelectAction',
  components: {
    navbar,
  },
  props: {
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const phenological = await PhenologicalStates.getAll();
      const plagues = await PlaguesAndDiseases.getAll();
      this.setPhenologicalStates(phenological);
      this.setPlaguesAndDiseases(plagues);
    } catch (error) {
      console.warn(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  methods: {
    navigateToForm(url) {
      this.setActualSelection(url);
      this.$f7.views.main.router.navigate('/formAction/', { reloadCurrent: true });
    },
    finish() {
      this.$f7.views.main.router.navigate('/fixedMonitoringPoint/', { reloadCurrent: true });
    },
    ...mapActions('scouting', ['setActualSelection', 'setPhenologicalStates', 'setPlaguesAndDiseases']),
  },
};

</script>

<style lang="scss">
.main-selectAction{
  .content-list{
    &__title{
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 5px;
      opacity: 0.7;
    }
    ul{
      &::before, &::after{
        height: 0!important;
      }
    }
    .smart-border {
      padding-bottom: 10px;
      .item-link {
        .item-content {
          padding: 0;
          .item-inner {
            background-color: #ffffff;
            min-height: 56px;
            border-radius: 8px;
            border: 1px solid #d8d8d8 !important;
            color: var(--f7-badge-bg-color);
            font-weight: 500;
            line-height: 21px;
            padding: 5px 15px;
            width: 100%;
            .item-after {
              margin-left: 0;
              padding: 0;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              color: var(--ha-darkgray);
            }
          }
          .item-title {
            display: none;
          }
        }
      }
    }
  }
  .stepper{
    display: flex;
    justify-content: center;
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}

</style>
