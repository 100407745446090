<template>
  <f7-page
    class="content-list-order main-startorder"
    @page:beforein="beforein"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${activity} - ${$t('Nfc_navbarTask_startorder')}`"
      :path-back="`/${pathBack}/`"
    />
    <div class="container">
      <div class="block block-header header-space">
        <div class="item-inner">
          <div class="item-cell">
            <div class="block-module">
              <img
                class="icon-line float-left"
                width="38"
                height="38"
                :src="images.workers"
              >
              <span class="number-icon">{{ workers.length }} / {{ count(plan_workers) }}</span>
            </div>
          </div>
          <div class="item-cell">
            <div class="block-module">
              <f7-button
                name="btnContinue"
                class="button botonPrimary color-green botonWorkerSelect"
                col
                button
                button-large
                button-raised
                fill
                @click="navigateTo('/nfc/')"
              >
                <img
                  width="40"
                  height="40"
                  :src="images.ic_nfc_2"
                >{{ $t("Start_order") }}
              </f7-button>
            </div>
          </div>
        </div>
      </div>
      <f7-list
        accordion-item-opened
        class="star-order-list"
      >
        <!-- General item -->
        <f7-list-item
          accordion-item
          :title="`${$t('Startorder_titleGeneral')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("EditOrder_ID") }}
                </f7-col>
                <f7-col>
                  {{ short_id }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_date") }}
                </f7-col>
                <f7-col>
                  {{ date() }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_activityName") }}
                </f7-col>
                <f7-col>
                  {{ activity }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_activityCode") }}
                </f7-col>
                <f7-col>
                  {{ activityId }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_taskName") }}
                </f7-col>
                <f7-col>
                  {{ task_name }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_taskCode") }}
                </f7-col>
                <f7-col>
                  {{ task_code }}
                </f7-col>
              </f7-row>
              <f7-row
                v-if="planner.goal_performance.unit != ''"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("EditOrder_productivityGoal") }}
                </f7-col>
                <f7-col>
                  {{ planner.goal_performance.count }} {{ planner.goal_performance.unit }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of General item -->

        <!-- Location item -->
        <f7-list-item
          v-if="loaded"
          accordion-item
          :title="`${$t('Editorder_titleLocation')}`"
          :badge="`${planner.goal_performance.working_area} HA`"
          accordion-item-opened
          class="star-order-list__location"
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-block
                class="no-padding-horizontal no-margin"
              >
                <f7-row>
                  <!-- <f7-col>
                    {{ $t("StartOrder_area_effective") }}
                  </f7-col> -->
                  <f7-col
                    width="100"
                    margin-bottom
                  >
                    <div class="location-item">
                      {{ getLocations(addLocation2) }}
                    </div>
                  </f7-col>
                </f7-row>
                <!-- <f7-treeview>
                  <f7-treeview-item
                    v-for="(item, index) in locationsTree"
                    :key="index"
                    class="content-tree"
                    :label="`${item.name}`"
                    :opened="isChecked(item) || isIndeterminate(item)"
                  >
                    <f7-treeview-item
                      v-for="(item2, index2) in item.children"
                      v-if="isChecked2(item2) || isIndeterminate2(item2)"
                      :key="index2"
                      :label="`${item2.name}`"
                    >
                      <f7-treeview-item
                        v-for="item3 in item2.children"
                        v-if="isChecked3(item3) || isIndeterminate3(item3)"
                        :key="item3.ID"
                        :label="`${item3.name}`"
                        :opened="isChecked3(item3) || isIndeterminate3(item3)"
                      >
                        <f7-treeview-item
                          v-for="(item4, index4) in item3.children"
                          v-if="isChecked4(item4) || isIndeterminate4(item4)"
                          :key="index4"
                          :label="`${item4.name}`"
                          :opened="isChecked4(item4) || isIndeterminate4(item4)"
                        >
                          <f7-treeview-item
                            v-for="(item5, index5) in item4.children"
                            v-if="isEquals(item5, locationId)"
                            :key="index5"
                            :label="`${item5.name}`"
                          />
                        </f7-treeview-item>
                      </f7-treeview-item>
                    </f7-treeview-item>
                  </f7-treeview-item>
                </f7-treeview> -->
              </f7-block>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Location item -->

        <!-- Machinery item -->
        <f7-list-item
          v-if="itemsDrivers.length > 0"
          accordion-item
          :title="`${$t('Startorder_titleMachinery')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in items2"
                :key="'machinery'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ item.subfamily }}
                </f7-col>
                <f7-col>
                  {{ item.name }}
                </f7-col>
              </f7-row>
              <f7-row
                v-for="(item, index) in itemsDrivers"
                :key="'Driver'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_machineDriver") + (index + 1) }}
                </f7-col>
                <f7-col>
                  {{ item.name }} {{ item.surname }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Machinery item -->
        <!-- Attachment Item -->
        <f7-list-item
          v-if="attachment_vehicles.length > 0"
          accordion-item
          :title="`${$t('Startorder_attachments')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in attachment_vehicles"
                :key="'Tools'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col class="enfasis padding-right">
                  {{ item.attachment_data.description }}
                </f7-col>
                <f7-col>
                  {{ item.vehicle_data.description }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Attachment item -->
        <!-- additonal information item -->
        <f7-list-item
          v-if="comment !== ''"
          accordion-item
          :title="`${$t('Startorder_additionalInformation')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ comment }}
                </f7-col>
                <!-- <f7-col>
                  {{ item.name }}
                </f7-col> -->
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of additional information item -->
        <!-- Product Item -->
        <f7-list-item
          v-if="products.length > 0"
          accordion-item
          :title="`${$t('Startorder_ProductTitle')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in planner.products"
                :key="'Product'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col
                  class="enfasis"
                  width="20%"
                >
                  {{ item.product.description }}
                </f7-col>
                <f7-col
                  v-for="(item2, index2) in item.product.properties"
                  v-if="item2.key === 'family'"
                  :key="index2"
                  class="padding"
                >
                  {{ calculateDosisTotal(products[index]) }}
                </f7-col>
                <f7-col
                  width="30"
                  class="padding enfasis"
                />
              </f7-row>
              <f7-row
                class="enfasis"
              >
                {{ `${$t('Start_edit_order_disolucion')} (L)` }}
              </f7-row>
              <f7-row
                class="padding"
              >
                {{ calculateTotalSolutions(products) }}
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Product item -->

        <!-- tool Item -->
        <f7-list-item
          v-if="tools.length > 0"
          accordion-item
          :title="`${$t('Startorder_Tools')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in tools"
                :key="'Tools'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col
                  v-for="(item2, index2) in item.data.properties"
                  v-if="item2.key === 'subfamily'"
                  :key="index2"
                >
                  {{ item2.value }}
                </f7-col>
                <f7-col>
                  {{ item.count }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Tool item -->
        <!-- Price item -->
        <!-- Se oculta porque en Ferrero se han quejado de que no tiene sentido
             mostrar datos económicos en la app de supervisor (SFR-133)
        <f7-list-item
          v-if="task_price != 0.00"
          accordion-item
          class="accordion-off"
          :title="`${$t('Startorder_titlePrice')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_unitPrice") }}
                </f7-col>
                <f7-col>
                  {{ task_price }} €
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        -->
        <!-- End of Price item -->
        <!-- Nursery item -->
        <f7-list-item
          v-if="nursery.length > 0"
          accordion-item
          :title="`${$t('EditOrder_Nursery')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in nursery"
                :key="'bin'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("EditOrder_BinCode") + (index + 1) }}
                </f7-col>
                <f7-col>
                  {{ item.code }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Nursery item -->
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import navbar from '../components/NavBar.vue';
import img from '../static/img/useron.svg';
import img2 from '../static/img/ic_nfc.svg';
import ImageNfc2 from '../static/img/ic_nfc_2.svg';
import ImageUser from '../static/img/ic_useron.svg';
import Locations from '../js/models/Locations';
import Items from '../js/models/Items';
import Employees from '../js/models/Employees';

export default {
  components: {
    navbar,
  },
  props: {
    pathBack: { type: String, default: 'tasks/list' },
  },
  data() {
    return {
      btnDisable: true,
      images: {
        workers: ImageUser,
        ic_nfc_2: ImageNfc2,
      },
      locationsTree: [],
      addLocation: [],
      fullNameFilter: 0,
      farm2: null,
      area: null,
      lote: null,
      parcel: null,
      subparcel: null,
      machinery: [],
      items2: [],
      finalLevelLocation: [],
      itemsDrivers: [],
      addLocation2: [],
      ha: 0,
      loaded: false,
      family: '',
    };
  },
  computed: {
    ...mapState('TaskManager', ['id', 'items', 'workers', 'activityId', 'activity', 'task_name',
      'locationName', 'locationId', 'task_code', 'farm', 'task_unit', 'task_price', 'plan_workers',
      'planner', 'locations', 'comment', 'products', 'tools', 'attachment_vehicles', 'area_effective',
      'short_id', 'nursery']),
    ...mapState('worker', ['type', 'code', 'selectedWorker']),
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        this.drivers = await Employees.getDrivers();
        this.locationsTree = await Locations.getAll();
        this.getFinalLevelLocation(this.locations);
        this.drivers = await Employees.getDrivers();
        for (let i = 0; i < this.items.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const machinery = await Items.getItemsById(this.items[i].item);
          for (let j = 0; j < this.drivers.length; j += 1) {
            if (this.items[i].driver === this.drivers[j].id) {
              this.itemsDrivers.push({
                id: this.drivers[j].id,
                name: this.drivers[j].name,
                surname: this.drivers[j].surname,
              });
            }
          }
          this.items2.push({
            id: machinery[0].id,
            name: `${machinery[0].item.description} ${this.$helpers.getProperty(machinery[0].item.properties, 'internal_code')}`,
            subfamily: machinery[0].subfamily,
          });
        }
        for (let i = 0; i < this.locationId.length; i += 1) {
          this.checkRestChild(this.locationId[i], this.locationsTree);
          this.checkRestChild2(this.locationId[i], this.locationsTree);
          this.addLocation.push(this.locationId[i]);
        }
        this.getFamily();
      } catch (error) {
        console.debug(error);
      } finally {
        this.$f7.preloader.hide();
      }
      this.loaded = true;
    },
    calculateDosisTotal(product) {
      let calcDosisTotal = (parseFloat(product.dosis) * parseFloat(this.planner.goal_performance.working_area)).toFixed(2);
      const dilutionFactor = parseFloat(product.solution) / 100;
      const unit = this.calculateUnitDosisTotal(product);

      calcDosisTotal = (!this.isKilogrameOrliter(product)) ? ((calcDosisTotal * dilutionFactor) / 1000).toFixed(2) : calcDosisTotal;
      return `${calcDosisTotal} ${unit}`;
    },
    calculateUnitDosisTotal(product) {
      if (product.dosisUt.toLowerCase().includes('gr/')
        || product.dosisUt.toLowerCase().includes('kg/')) return 'Kg';
      return 'L';
    },
    calculateTotalSolutions(products) {
      for (const product of products) {
        return parseFloat(this.calculateTotalSolution(product));
      }
      return 0;
    },
    calculateTotalSolution(product) {
      if (this.isKilogrameOrliter(product)) {
        return '0.00';
      }
      const calcTotalSol = (product.solution * this.planner.goal_performance.working_area).toFixed(2);
      return `${calcTotalSol}`;
    },
    isKilogrameOrliter(product) {
      return typeof (product.dosisUt) === 'undefined' || product.dosisUt.toLowerCase().includes('kg/') || product.dosisUt.toLowerCase().includes('l/');
    },
    isChecked(item) {
      let select = 0;
      for (let i = 0; i < item.children.length; i += 1) {
        if (this.isChecked2(item.children[i]) === true) {
          select += 1;
        }
      }
      if (select === item.children.length) {
        item.level = true;
        return true;
      }
      return false;
    },
    isChecked2(item) {
      let select = 0;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          if (this.isChecked3(item.children[i]) === true) {
            select += 1;
          }
        }
        if (select === item.children.length) {
          item.level = true;
          return true;
        }
        return false;
      }
      return this.isEquals(item, this.locationId);
    },
    isChecked3(item) {
      let select = 0;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          if (this.isChecked4(item.children[i]) === true) {
            select += 1;
          }
        }
        if (select === item.children.length) {
          item.level = true;
          return true;
        }
        return false;
      }
      return this.isEquals(item, this.locationId);
    },
    isChecked4(item) {
      let children = [];
      let select = 0;
      if (item.level === true) {
        return true;
      }
      if (item.children.length > 0) {
        children = item.children;
        for (let i = 0; i < children.length; i += 1) {
          if (this.isEquals(children[i], this.locationId) || children[i].level === true) {
            select += 1;
          }
        }
        if (select === children.length) {
          item.level = true;
          return true;
        }
        return false;
      }
      return this.isEquals(item, this.locationId);
    },
    isIndeterminate4(item) {
      let children = [];
      let cont = 0;
      let select = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (this.isEquals(children[i], this.locationId)) {
          select += 1;
        } else if (children[i].level === item.level + 1 || children[i].level === false) {
          cont += 1;
        } else {
          select += 1;
        }
      }
      if (cont > 0 && select > 0) {
        return true;
      }
      return false;
    },
    isIndeterminate(item) {
      let children = [];
      let select = 0;
      let cont = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (children[i].level === item.level + 1 || children[i].level === true) {
          if (this.isIndeterminate2(children[i]) === true) select += 1;
          else if (this.isChecked2(children[i]) === true) cont += 1;
        }
      }
      if (select > 0 || (cont > 0 && cont < children.length)) {
        return true;
      }
      return false;
    },
    isIndeterminate3(item) {
      let children = [];
      let select = 0;
      let cont = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (this.isIndeterminate4(children[i]) === true) select += 1;
        else if (this.isChecked4(children[i]) === true) cont += 1;
      }
      if (select > 0 || (cont > 0 && cont < children.length)) {
        return true;
      }
      return false;
    },
    isIndeterminate2(item) {
      let children = [];
      let select = 0;
      let count = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (this.isIndeterminate3(children[i]) === true) select += 1;
        else if (this.isChecked3(children[i]) === true) count += 1;
      }
      if (select > 0 || (count > 0 && count < children.length)) return true;
      return false;
    },
    checkRestChild(item, locationsTree) {
      for (let i = 0; i < locationsTree.length; i += 1) {
        if (locationsTree[i].children.length > 0) {
          for (let j = 0; j < locationsTree[i].children.length; j += 1) {
            if (locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < locationsTree[i].children[j].children.length; k += 1) {
                if (locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < locationsTree[i].children[j].children[k].children.length; l += 1) {
                    if (locationsTree[i].children[j].children[k].children[l].children.length > 0) {
                      for (let m = 0; m < locationsTree[i].children[j].children[k].children[l].children.length; m += 1) {
                        if (locationsTree[i].children[j].children[k].children[l].children[m].bd_id === item) {
                          this.ha += locationsTree[i].children[j].children[k].children[l].children[m].area;
                        }
                      }
                    } else if (locationsTree[i].children[j].children[k].children[l].bd_id === item) {
                      this.ha += locationsTree[i].children[j].children[k].children[l].area;
                    }
                  }
                } else if (locationsTree[i].children[j].children[k].bd_id === item) {
                  this.ha += locationsTree[i].children[j].children[k].area;
                }
              }
            } else if (locationsTree[i].children[j].bd_id === item) {
              this.ha += locationsTree[i].children[j].area;
            }
          }
        } else if (locationsTree[i].bd_id === item) {
          this.ha += locationsTree[i].area;
        }
      }
    },
    isEquals(item, locationId) {
      for (let i = 0; i < locationId.length; i += 1) {
        if (item.bd_id === locationId[i]) {
          return true;
        }
      }
      return false;
    },
    notChange() {
      this.$f7.dialog.alert('this action can not be edited');
      return false;
    },
    photoSrc() {
      return img;
    },
    photoSrc2() {
      return img2;
    },
    navigateTo(path) {
      this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
    },
    date() {
      const aux = moment(new Date());
      return aux.format('YYYY/MM/DD');
    },
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.viewChildren(item, locations);
      }
      return this.finalLevelLocation;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (locations.find((element) => element === item.bd_id)) {
          this.finalLevelLocation.push(item.name);
        }
      }
    },
    count(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        x += item[i].count;
      }
      return x;
    },
    getFamily() {
      for (let i = 0; i < this.products.length; i += 0) {
        for (let j = 0; j < this.products[i].product.properties.length; j += 1) {
          if (this.products[i].product.properties[j].key === 'family' || this.products[i].product.properties[j].key === 'subfamily') {
            this.family = this.products[i].product.properties[j].value;
            return this.family;
          }
        }
      }
      return this.family;
    },
    checkRestChild2(item, locationsTree) {
      for (let i = 0; i < locationsTree.length; i += 1) {
        if (locationsTree[i].children.length > 0) {
          for (let j = 0; j < locationsTree[i].children.length; j += 1) {
            if (locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < locationsTree[i].children[j].children.length; k += 1) {
                if (locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < locationsTree[i].children[j].children[k].children.length; l += 1) {
                    if (locationsTree[i].children[j].children[k].children[l].children.length > 0) {
                      for (let m = 0; m < locationsTree[i].children[j].children[k].children[l].children.length; m += 1) {
                        if (locationsTree[i].children[j].children[k].children[l].children[m].bd_id === item) {
                          this.addLocation2.push(locationsTree[i].children[j].children[k].name);
                          for (let n = 0; n < this.addLocation2.length - 1; n += 1) {
                            if (this.addLocation2[n] === this.addLocation2[n + 1]) {
                              this.addLocation2.splice(n, 1);
                            }
                          }
                        }
                      }
                    } else if (locationsTree[i].children[j].children[k].children[l].bd_id === item) {
                      this.addLocation2.push(locationsTree[i].children[j].children[k].name);
                      for (let n = 0; n < this.addLocation2.length - 1; n += 1) {
                        if (this.addLocation2[n] === this.addLocation2[n + 1]) {
                          this.addLocation2.splice(n + 1, 1);
                        }
                      }
                    }
                  }
                } else if (locationsTree[i].children[j].children[k].bd_id === item) {
                  this.addLocation2.push(locationsTree[i].children[j].children[k].name);
                  for (let n = 0; n < this.addLocation2.length - 1; n += 1) {
                    if (this.addLocation2[n] === this.addLocation2[n + 1]) {
                      this.addLocation2.splice(n + 1, 1);
                    }
                  }
                }
              }
            } else if (locationsTree[i].children[j].bd_id === item) {
              this.addLocation2.push(locationsTree[i].children[j].name);
              for (let n = 0; n < this.addLocation2.length - 1; n += 1) {
                if (this.addLocation2[n] === this.addLocation2[n + 1]) {
                  this.addLocation2.splice(n + 1, 1);
                }
              }
            }
          }
        } else if (locationsTree[i].bd_id === item) {
          this.addLocation2.push(locationsTree[i].name);
          for (let n = 0; n < this.addLocation2.length - 1; n += 1) {
            if (this.addLocation2[n] === this.addLocation2[n + 1]) {
              this.addLocation2.splice(n, 1);
            }
          }
        }
      }
      this.addLocation2 = this.addLocation2.filter(this.onlyUnique);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getLocations(item) {
      let aux = '';
      if (item.length > 0) {
        for (let i = 0; i < item.length; i += 1) {
          if (i === 0) aux += `${item[i]}`;
          else aux += `, ${item[i]}`;
        }
      }
      return aux;
    },
    ...mapActions('TaskManager', ['setItemsDrivers']),
  },
};

</script>

<style lang="scss">
.main-startorder{
  .item-inner{
    .item-title{
      color: #FFFFFF;
    }
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-title{
        max-width: 195px;
        color: #ffffff;
      }
      .item-after{
        display: flex;
        align-items: center;
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .item-link{

      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}

</style>
