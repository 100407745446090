import Bins from '../../js/models/Bins';
import Locations from '../../js/models/Locations';
import Egress from '../../js/models/Egress';
import db from '../../services/Database';

export default {
  namespaced: true,

  state: {
    selectedBin: { },
    locationDestination: '',
    contentBin: [],
    locationTree: [],
    selectedLocations: [],
    parentLocationsSelected: [],
    binsContentVarieties: [],
    binsContentCategories: [],
    binsContentClassification: [],
    idsBinsToMove: [],
    egressAvailable: [],
  },

  getters: {
    selectedBin: (state) => state.selectedBin,
    locationDestination: (state) => state.locationDestination,
    contentBin: (state) => state.contentBin,
    binsContentVarieties: (state) => state.binsContentVarieties,
    binsContentCategories: (state) => state.binsContentCategories,
    binsContentClassification: (state) => state.binsContentClassification,
    selectedLocations: (state) => state.selectedLocations,
    parentLocationsSelected: (state) => state.parentLocationsSelected,
    idsBinsToMove: (state) => state.idsBinsToMove,
    egressAvailable: (state) => state.egressAvailable,
    locationTreeTreated(state) {
      const recursiveTreatChildren = (idParent, children) => {
        for (let i = 0; i < children.length; i += 1) {
          children[i].modified = true;
          children[i].parent = idParent;
          recursiveTreatChildren(children[i].bd_id, children[i].children);
        }
      };
      for (const item of state.locationTree) {
        item.modified = true;
        recursiveTreatChildren(item.bd_id, item.children);
      }
      return state.locationTree;
    },
    formattedTree(state) {
      const locationsTreeFormatted = [];
      const setLocationTreeFormat = (item, idParent) => {
        if (item.level > 3) {
          locationsTreeFormatted.push({
            location: `${item.name} -${item.properties.variety_code}- ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
            parent: idParent,
          });
        } else {
          locationsTreeFormatted.push({
            location: `${item.name} - ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
            parent: idParent,
          });
        }
      };
      const recursiveAddChildren = (children, idParent) => {
        for (let i = 0; i < children.length; i += 1) {
          setLocationTreeFormat(children[i], idParent);
          recursiveAddChildren(children[i].children, children[i].bd_id);
        }
      };

      for (const item of state.locationTree) {
        setLocationTreeFormat(item, undefined);
        recursiveAddChildren(item.children, item.bd_id);
      }
      return locationsTreeFormatted;
    },
  },

  actions: {
    resetStates({ commit }) {
      commit('SET_SELECTED_BIN', {});
      commit('SET_LOCATION_DESTINATION', '');
      commit('SET_CONTENT_BIN', []);
      commit('SET_EGRESS_AVAILABLE', []);
      commit('SET_LOCATIONS_TREE', []);
      commit('SET_SELECTED_LOCATIONS', []);
      commit('SET_BINS_CONTENT_VARIETIES', []);
      commit('SET_BINS_CONTENT_CATEGORIES', []);
      commit('SET_BINS_CONTENT_CLASSIFICATION', []);
      commit('SET_PARENT_LOCATIONS_SELECTED', []);
      commit('SET_IDS_BINS_TO_MOVE', []);
    },
    setSelectedBin({ commit }, value) {
      commit('SET_SELECTED_BIN', value);
    },
    setLocationDestination({ commit }, value) {
      commit('SET_LOCATION_DESTINATION', value);
    },
    setContentBin({ commit }, value) {
      commit('SET_CONTENT_BIN', value);
    },
    async fetchEgress({ commit }) {
      try {
        const egressAvailable = await Egress.getAll();
        commit('SET_EGRESS_AVAILABLE', egressAvailable);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchLocationTree({ commit }) {
      try {
        const locationTree = await Locations.getAll();
        commit('SET_LOCATIONS_TREE', locationTree);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    setSelectedLocations({ commit }, value) {
      commit('SET_SELECTED_LOCATIONS', value);
    },
    setParentLocationsSelected({ commit }, value) {
      commit('SET_PARENT_LOCATIONS_SELECTED', value);
    },
    setIdsBinsToMove({ commit }, value) {
      commit('SET_IDS_BINS_TO_MOVE', value);
    },
    async fetchBinsContentFamilies({ dispatch }) {
      try {
        await dispatch('fetchBinsContentVarieties');
        await dispatch('fetchBinsContentCategories');
        await dispatch('fetchBinsContentClassification');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentVarieties({ commit }) {
      try {
        const binsContentVarieties = await db.binsVariety.toArray();
        commit('SET_BINS_CONTENT_VARIETIES', binsContentVarieties);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentCategories({ commit }) {
      try {
        const binsContentCategories = await db.binsCategory.toArray();
        commit('SET_BINS_CONTENT_CATEGORIES', binsContentCategories);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentClassification({ commit }) {
      try {
        const binsContentClassification = await db.binsClassification.toArray();
        commit('SET_BINS_CONTENT_CLASSIFICATION', binsContentClassification);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchUpdateBinInDatabase({ commit, state }) {
      try {
        await Bins.updateFullBin(state.selectedBin);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
  },

  mutations: {
    SET_SELECTED_BIN(state, payload) {
      state.selectedBin = payload;
    },
    SET_LOCATION_DESTINATION(state, payload) {
      state.locationDestination = payload;
    },
    SET_CONTENT_BIN(state, payload) {
      state.contentBin = payload;
    },
    SET_SELECTED_LOCATIONS(state, payload) {
      state.selectedLocations = payload;
    },
    SET_PARENT_LOCATIONS_SELECTED(state, payload) {
      state.parentLocationsSelected = payload;
    },
    SET_EGRESS_AVAILABLE(state, payload) {
      state.egressAvailable = payload;
    },
    SET_LOCATIONS_TREE(state, payload) {
      state.locationTree = payload;
    },
    SET_BINS_CONTENT_VARIETIES(state, payload) {
      state.binsContentVarieties = payload;
    },
    SET_BINS_CONTENT_CATEGORIES(state, payload) {
      state.binsContentCategories = payload;
    },
    SET_BINS_CONTENT_CLASSIFICATION(state, payload) {
      state.binsContentClassification = payload;
    },
    SET_IDS_BINS_TO_MOVE(state, payload) {
      state.idsBinsToMove = payload;
    },
  },
};
