import { render, staticRenderFns } from "./pestsAndDiseases.vue?vue&type=template&id=2a33167f&"
import script from "./pestsAndDiseases.vue?vue&type=script&lang=js&"
export * from "./pestsAndDiseases.vue?vue&type=script&lang=js&"
import style0 from "./pestsAndDiseases.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports