<template>
  <div>
    <f7-block
      v-if="imageSrc.length > 0"
      class="margin"
    >
      <f7-swiper
        :key="numPictures"
        pagination
      >
        <f7-swiper-slide
          v-for="(element, index) in getPicturesToDisplay"
          :key="index"
        >
          <img
            :src="element"
            class="lazy lazy-fade-in demo-lazy photo-scouting"
            @click="deleteElement(index)"
          >
        </f7-swiper-slide>
      </f7-swiper>
    </f7-block>
    <f7-block>
      <!-- Take Picture -->
      <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        @click="cameraTakePicture()"
      >
        <img class="ic-piccam">
        {{ $t("Pests_TakePicture") }}
      </f7-button>
    </f7-block>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
  },
  props: {
    // Si se pasa el enableCamera: false, se habilitará el administrador de archivos en lugar de la cámara
    enableCamera: { type: Boolean, default: true },
  },
  data() {
    return {
      imageSrc: [],
      numPictures: 0,
      imageBase64: '',
      picturesToDisplay: [],
    };
  },
  computed: {
    getPicturesToDisplay() {
      return this.picturesToDisplay;
    },
    ...mapGetters('scouting', ['pictures']),
  },
  methods: {
    // Al hacer click en una imagen, esta se podrá eliminar
    deleteElement(index) {
      this.$f7.dialog.confirm(
        `${this.$t('TakePicture_ConfirmDeletePicture')}`,
        () => {
          const newPictures = this.pictures;
          newPictures.splice(index, 1);
          this.setPictures(newPictures);
          this.numPictures -= 1;
          this.picturesToDisplay.splice(index, 1);
        },
      );
    },
    setOptions(srcType) {
      const options = {
        quality: 100, // % of quality to save
        destinationType: Camera.DestinationType.FILE_URI, // DATA_URL (base 64), FILE_URI (android), NATIVE_URI (iOS)
        sourceType: srcType,
        encodingType: Camera.EncodingType.JPEG, // JPEG, PNG
        mediaType: Camera.MediaType.PICTURE, // PICTURE, VIDEO, ALLMEDIA (Picture and Video)
        allowEdit: false, // Allow to edit before save picture
        correctOrientation: true, // Corrects Android orientation quirks
        targetHeight: 1920, // Se usa para redimensionar la imagen (valor en px)
        targetWidth: 1080, // Se usa para redimensionar la imagen (valor en px)
      };
      return options;
    },

    cameraTakePicture() {
      let srcType;
      // Options: PHOTOLIBRARY / CAMERA / SAVEDPHOTOALBUM
      if (this.enableCamera === true) {
        srcType = Camera.PictureSourceType.CAMERA;
      } else {
        srcType = Camera.PictureSourceType.PHOTOLIBRARY;
      }
      const options = this.setOptions(srcType);
      const newPictures = this.pictures;
      if (this.pictures.length < 5) {
        navigator.camera.getPicture((imageUri) => {
          newPictures.push(imageUri);
          // Si se elige como destinationType: DATA_URL,
          // faltaría añadirle el prefijo de esta manera: `data:image/jpeg;base64,${imageUri}`
          this.setPictures(newPictures);
          this.fillPicturesToDisplay(newPictures);
          this.imageSrc.push(newPictures);
          this.numPictures += 1;
        }, (error) => {
          console.debug(`Unable to obtain picture: ${error}`, 'app');
        }, options);
      } else {
        this.$f7.dialog.alert(this.$t('Error_TooManyPictures'));
      }
    },

    // Convertir imagen a base64
    convertImgToBase64(url, callback, outputFormat) {
      let canvas = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL(outputFormat || 'image/jpeg');
        callback.call(this, dataURL);
        // Clean up
        canvas = null;
      };
      img.src = url;
    },

    fillPicturesToDisplay(picturesArray) {
      this.picturesToDisplay = [];
      picturesArray.forEach((element) => {
        // Se añade al array de fotos a mostrar el base64 de la foto
        this.convertImgToBase64(element, (base64Img) => {
          this.picturesToDisplay.push(base64Img);
        });
      });
    },
    ...mapActions('scouting', ['setPictures']),
  },
};
</script>
<style>
.photo-scouting {
  object-fit: cover;
  width: 100%;
  height: 240px;
  border-radius: 3px;
}
</style>
