import db from '../../services/Database';
import ModifiedRecords from './ModifiedRecords';

export default {
  getAll() {
    return db.scoutingPoint.toArray();
  },
  async insertScoutingForm(form) {
    const idForm = await db.scoutingForm.put(form);
    const id = `scoutingForm-${idForm}`;
    ModifiedRecords.insert({ type: 'scoutingForm', idTask: id, idLocation: idForm });
  },
  async countFormElement() {
    const countElements = await db.scoutingForm.count();
    return countElements;
  },
  async getNewScoutingForms() {
    const newForms = await db.scoutingForm.filter(
      (element) => (element.scouting_point_id === ''),
    ).toArray();
    return newForms;
  },
  async getPreviousScoutingForms() {
    const newForms = await db.scoutingForm.filter(
      (element) => (element.scouting_point_id !== ''),
    ).toArray();
    return newForms;
  },
  async getAllScoutingFormsToSync() {
    const formsToSync = await db.scoutingForm.toArray();
    return formsToSync;
  },
};
