import db from '../../services/Database';

export default {
  getAll() {
    return db.families.toArray();
  },
  getScoutingType() {
    return db.families.filter((element) => element.family === 'SCOUTING_TYPE').toArray();
  },
  getPlaguesStates() {
    return db.families.filter((element) => element.family === 'PLAGUES_STATES').toArray();
  },
  getDiseasesStates() {
    return db.families.filter((element) => element.family === 'DISEASES_STATES').toArray();
  },
};
