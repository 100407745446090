const dictionary = {
  es: {
    translation: {
      Login_txtWelcome: 'Acceso',
      Login_inpUsername_placeholder: 'Usuario',
      Login_inpPassword_label: 'Contraseña',
      Login_inpPassword_placeholder: 'Contraseña',
      Login_chkRememberMe: 'Recordar contraseña',
      Login_txtRestorePass: 'Cambiar contraseña',
      Login_txtNeedHelp: '¿Necesitas ayuda?',
      Login_btnSignIn: 'ENTRAR',
      Login_btnSignUp_question: '¿Todavía no te has registrado?',
      Login_btnSignUp_link: 'Registrate aquí',
      Login_msgEmail_title: 'Información',
      Login_msgEmail_content: 'En breve recibirá un email para cambiar su contraseña',
      Login_msgEmail_btnClose: 'Cerrar',
      loggOut_Message: '¿Estás seguro que quieres salir de la aplicación?',
      loggOut_Warning_Message: 'Si se desloguea perderá los datos no sincronizados. ¿Estás seguro que quieres salir de la aplicación?',
      Delete_Message: '¿Estas seguro que quieres eliminar esta tarea?',
      stop_task: '¿Estas seguro que quieres terminar esta tarea?',
      RecoveryPassword_txtDataAccess: 'Introduzca su email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Restrablecer contraseña',
      RecoveryPassword_btnGoBack: 'Volver',
      ChangePassword_txtDataAccess: 'Introduzca su nueva contraseña',
      ChangePassword_inpEmail_placeholder: 'Contraseña',
      ChangePassword_inpRepeatEmail_placeholder: 'Repita contraseña',
      ChangePassword_btnChangePassword: 'Cambiar contraseña',
      CheckOutMulti_note_lable: 'Nota',
      CheckOutMulti_note_placeholder: 'Click aquí para añadir una nota',
      CheckOutMulti_note_alert: 'Es posible escribir un máximo de 50 caracteres en la nota, actualmente hay ',
      assigned: 'Se le asgina',
      CheckIn: 'CheckIn',
      CheckOut: 'CheckOut',
      nfcManuallySearch: 'Buscar nombre o apellido del trabajador/a',
      ManualListSearch: 'Buscar nombre o apellido',
      ManualListAccesSearch: 'Buscar',
      nfcManuallyCheckOut: 'asignando CheckOut',
      Form_fitosanitary_quantityPlanned: 'Cantidad planeada',
      Form_fitosanitary_doseCollected: 'Cantidad entregada ',
      Form_fitosanitary_quantityUsed: 'Cantidad utilizada',
      Form_fitosanitary_dosisUsed: 'Cantidad utilizada',
      Form_fitosanitary_doseReturn: 'Cantidad a retornar al almacén',
      Form_fitosanitary_totalrealprescripcion: 'Mojamiento utilizada (L)',
      Form_fitosanitary_unit: 'Unidad',
      Form_machinery_title: 'Maquinaria',
      Form_fitosanitary_title: 'Fitosanitario',
      Form_machinery_KMInicial: 'KM inicial',
      Form_machinery_KMFinal: 'KM final',
      Form_machinery_horometroInicial: 'Horómetro inicial',
      Form_machinery_workingArea: 'Área trabajo',
      Form_machinery_fuelType: 'Tipo combustible',
      Form_machinery_horometroFinal: 'Horómetro final',
      Form_machinery_literConsumed: 'Litros consumidos',
      Form_machinery_observations: 'Observaciones',
      Form_machinery_ERRORKM: 'Km inicial no puede ser mayor que Km final',
      Form_machinery_ERRORHORO: 'Horómetro inicial no puede ser mayor que horómetro final',
      Register_btnEnter: 'ENTRAR',
      Register_inpName_name: 'Nombre',
      Register_inpName_placeholder: 'Nombre',
      Register_inpSurnames_name: 'Apellidos',
      Register_inpSurnames_placeholder: 'Apellidos',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Contrasena',
      Register_inpPassword_placeholder: 'Contraseña',
      Register_inpPasswordVerification_name: 'RepetirContrasena',
      Register_inpPasswordVerification_placeholder: 'Repetir contraseña',
      Register_inpUsername_name: 'NombreUsuario',
      Register_inpUsername_placeholder: 'Nombre de usuario',
      Nfc_into_error_404: 'El empleado no está registrado',
      Nfc_into_error_400: 'El formato de nfc no es válido',
      Nfc_into_error_406: 'La tarea actual ya está creada en esta localización',
      Nfc_into_error_409: 'El trabajador ya está trabajando en otra tarea',
      Nfc_info_txtTitle: 'Lectura de tarjeta',
      Nfc_info_txtSubTitle: 'Aproxime la tarjeta para su lectura',
      Nfc_navbarpresence_incidence: 'Escaneo de NFC',
      Nfc_navbarTask_salida: 'Productividad',
      Nfc_navbarTask_checkout: 'Productividad',
      Nfc_navbarTask_startorder: 'Empezar Orden',
      Editorder_title: 'Editar Orden',
      Editorder_driver: 'Conductor',
      Nfc_navbarpresence_manual: 'Añadir NFC manual',
      Nfc_navbarpresence_btnContinue: 'Continuar',
      NfcManually_navbarpresence_gotoproduct: 'IR A PRODUCTOS',
      NfcManually_navbarpresence_skipall: 'SALTAR TODOS',
      NfcManually_navbarpresence_btnContinue: 'Continuar',
      NfcManually_listInput_placeholder: 'Nombre o apellido',
      LeftPanel_title_taskManagement: 'GESTIÓN DE TAREAS',
      LeftPanel_Subtitle_DAILYsUMMARY: 'Resumen diario',
      LeftPanel_Subtitle_searchworker: 'Buscar empleados',
      LeftPanel_Subtitle_historyboards: 'Historial tareas',
      LeftPanel_title_information: 'INFORMACIÓN',
      LeftPanel_Subtitle_SIGNOUT: 'Salir',
      Profile_info_profile: 'Información de perfil',
      Profile_name_placeholder: 'Nombre',
      Profile_surname_label: 'Apellidos',
      Profile_surname_placeholder: 'Apellidos',
      Profile_email_label: 'E-mail',
      Profile_email_placeholder: 'E-mail',
      Profile_mobile_label: 'Teléfono',
      Profile_mobile_placeholder: 'Teléfono',
      Profile_password_profile: 'Contraseña',
      Profile_oldPass_label: 'Contraseña anterior',
      Profile_oldPass_placeholder: 'Contraseña anterior',
      Profile_newPass_label: 'Nueva contraseña',
      Profile_newPass_placeholder: 'Nueva contraseña',
      Profile_checkNewPass_label: 'Confirmar nueva contraseña',
      Profile_change_password: 'Guardar contraseña',
      Profile_photo_profile: 'Foto perfil',
      Profile_update_photo: 'Actualizar foto perfil',
      Profile_setup_profile: 'Configuración',
      Profile_language_label: 'Idioma',
      Profile_theme_label: 'Tema',
      Profile_save_changes: 'Guardar cambios',
      Home_info_txtTitle: 'No hay empleados',
      ExportWorkerList_btnExport: 'Exportar a Excel',
      ExportExcel_thNombre: 'Nombre',
      ExportExcel_thTipo: 'Tipo',
      ExportExcel_thFecha: 'Fecha',
      ExportExcel_captionListaEmpleados: 'Lista de empleados',
      editorder_noSelectSameVehicle_error: 'No se puede seleccionar el mismo vehiculo en dos selectores distintos, selecciona otro vehículo.',
      SearchEmployee_title: 'Buscar empleado',
      SheetExport_sheet_export_ok: 'Fichero descargado: ',
      SheetExport_sheet_export_error: 'Error en la descarga',
      StartOrder_WorkOrderId: 'ID orden trabajo',
      StartOrder_date: 'Fecha',
      StartOrder_activityName: 'Nombre actividad',
      StartOrder_activityCode: 'Código actividad',
      StartOrder_taskName: 'Nombre tarea',
      StartOrder_taskCode: 'Código Tarea',
      StartOrder_grouped: 'Agrupado',
      StartOrder_group: 'Grupo',
      StartOrder_shift: 'Variación',
      startorder_dosistotal: 'Dosis en disolución',
      startorder_productname: 'Nombre',
      startorder_producttype: 'Tipo',
      Startorder_ProductTitle: 'Producto',
      Startorder_attachments: 'Aperos',
      yes: 'Si',
      no: 'No',
      StartOrder_productivity_working_area_goal: 'Objetivo area trabajo',
      StartOrder_productivity_goal: 'Productividad objetivo',
      StartOrder_farm: 'Unidad territorial',
      StartOrder_area: 'Area',
      StartOrder_lote: 'Lote',
      StartOrder_parcel: 'Parcela',
      StartOrder_subParcel: 'Subparcela',
      StartOrder_ha: 'Ha',
      StartOrder_machineFamily: 'Familia Maquinaria',
      StartOrder_machineDriver: 'Conductor ',
      StartOrder_taskUnit: 'Unidad tarea',
      StartOrder_unitPrice: 'Precio unidad',
      Start_order: 'Selección empleado',
      Startorder_titlePrice: 'Precio',
      Startorder_titleMachinery: 'Maquinaria',
      Startorder_additionalInformation: 'Información Adicional',
      Startorder_titleLocation: 'Localización',
      Editorder_titleLocation: 'Localización - Area trabajo : ',
      Startorder_titleGeneral: 'General',
      TasksList_navbar_text: 'Planificación diaria',
      TasksList_lstNewTasks_title: 'Iniciar tarea',
      TasksList_lstInProgressTasks_title: 'Tareas en progreso',
      TasksList_lstCompletedTasks_title: 'Tareas completadas',
      AccordionList_workers: 'trabajadores',
      AccordionList_worker: 'trabajador',
      Home_conductor: 'Conductor',
      ManualList_listItem_title: 'Ningun empleado encontrado',
      checkOut_Productivity_placeholder: 'Campo numérico',
      checkOut_Productivity: 'Productividad',
      checkOut_workingArea: 'Area trabajo',
      checkOut_Task: 'Tarea',
      checkOut_observations: 'Observaciones',
      checkOut_assigned: 'Estas asignando productividad para',
      checkOut_assigned_single: 'un trabajador',
      Hisotry_navbar_text: 'Tablero de historia',
      checkOut_assigned_multiple: 'todos los empleados',
      list_content_farm_img_alt: 'Cambiar de unidad territorial',
      farmSelection_navbar_text: 'Seleccionar unidad territorial',
      locationSelect_navbar_text: 'Seleccionar localización',
      TasksList_filterTask_title: 'Seleccionar tarea',
      WorkersInTask: 'Trabajadores',
      workersSelection_buttomPanelConfig_multiple: 'Varios trabajadores',
      workersSelection_buttomPanelConfig_individual: 'Asignación individual',
      workersSelection_buttomPanelConfig_title: 'Selecciona tu registro de productividad',
      SyncingModal_infoUpdate_title: 'Actualización de datos',
      SyncingModal_infoUpdate_text: 'La sincronización con el servidor es necesaria para que puedas seguir trabajando incluso sin conexión.',
      SyncingModal_infoLoading_title: 'Cargando datos…',
      SyncingModal_infoLoading_text: 'Este proceso puede durar unos minutos, según la velocidad de la conexión del dispositivo.',
      SyncingModal_txtError: 'Error:',
      SyncingModal_btnSync: 'Sincronizar',
      Status_Completed: 'Completa',
      Status_Open: 'Pendiente',
      Status_Deleted: 'Eliminada',
      Status_InProgress: 'En progreso',
      onlyStop: 'DETENER',
      EditOrder_ID: 'ID',
      EditOrder_productivityGoal: 'Objetivo de productividad',
      binsConsult_search_text: 'Buscar nombre o código de bin',
      binsConsult_listInput_placeholder: 'Nombre o código',
      LeftPanel_title_nursery: 'SEMILLERO',
      LeftPanel_Subtitle_ConsultBins: 'Consultar Bins',
      ManualListBins_listItem_title: 'Ningun bin encontrado',
      binContent_NBins: 'Nº Bins:',
      binContent_NBatch: 'Nº Lote:',
      binContent_Origin: 'Farm Origen:',
      binContent_Destination: 'Farm Destino:',
      binContent_State: 'Estado:',
      binContent_LastLevel: 'Último Nivel:',
      binContent_Variety: 'Variedad:',
      binContent_Category: 'Categoría:',
      binContent_Classification: 'Clasificación:',
      binContent_NumPlants: 'Nº Plantas',
      binContent_State_Created: 'Creado',
      binContent_State_Registered: 'Registrado',
      binContent_State_Sent: 'Enviado',
      binContent_State_ToReceive: 'Pendiente de recibir',
      binContent_State_Received: 'Recibido',
      binContent_State_Closed: 'Cerrado',
      BinsContent_Button_btnReclassify: 'RECLASIFICAR',
      BinsContent_Button_btnConfirm: 'CONFIRMAR',
      BinsContent_Button_btnMove: 'MOVER',
      BinsContent_Button_btnContinue: 'CONTINUAR',
      BinsContent_Button_btnSave: 'GUARDAR',
      binsNewContent_Select_ParentCode: 'Código del padre:',
      binsNewContent_Select_Category: 'Categoría:',
      binsNewContent_Select_Classification: 'Clasificación:',
      binsNewContent_Alert: 'Código del padre, Categoría y Clasificación deben estar seleccionados.',
      binsMove_CheckBox_SelectAll: 'Seleccionar Todos',
      binsMove_Alert_ButtonMove: 'Debe seleccionar al menos un contenido',
      binsChangeLocation_Advice_SelectLastLevel: '*IMPORTANTE: Puede seleccionar sólo un último nivel',
      binsChangeLocation_Title: 'Cambiar Ubicación',
      binsChangeLocation_Alert_SelectOneLocation: 'Debe seleccionar una y sólo una ubicación',
      EditOrder_BinCode: 'Bin-Code',
      EditOrder_Nursery: 'Semillero',
      LeftPanel_title_scouting: 'Exploración',
      LeftPanel_Subtitle_PestsAndDiseases: 'Plagas y Enfermedades',
      LeftPanel_Subtitle_PhenologicalStates: 'Estados Fenológicos',
      LeftPanel_Subtitle_FixedMonitoringPoint: 'Punto de Monitoreo Fijo',
      LeftPanel_Subtitle_CasualRegister: 'Registro Casual',
      pestsAndDiseases_search_text: 'Nombre de Plaga o Enfermedad',
      pestsAndDiseases_listInput_placeholder: 'Nombre',
      PestsAndDiseases_listItem_title: 'Ninguna Plaga ni Enfermedad encontrada',
      pestAndDiseasesContent_title_ImageDescription: 'Descripción de Imagen',
      CasualRegister_Title_Date: 'Fecha',
      CasualRegister_Title_IncidentName: 'Nombre del Incidente',
      CasualRegister_Title_Type: 'Tipo',
      CasualRegister_Title_Species: 'Especies',
      CasualRegister_Title_Comments: 'Comentarios',
      CasualRegister_Title_Location: 'Ubicación',
      CasualRegister_Button_Finish: 'Terminar',
      FixedMonitoringPoint_Title_MonitoringPoint: 'Punto de Monitoreo',
      FixedMonitoringPoint_Button_Continue: 'Continuar',
      SelectAction_Title: 'Seleccione Acción',
      formAction_Title_Pests: 'Plagas',
      formAction_Title_Diseases: 'Enfermedades',
      formAction_Title_Phenological: 'Fenología',
      formAction_Title_WeatherConditions: 'Condiciones Climáticas Extremas',
      formAction_Title_Other: 'Otros',
      SelectAction_Button_Finish: 'Terminar',
      Pests_Phenological_State_of_the_Crop: 'Estado Fenológico',
      Pests_Species: 'Especies',
      Pests_State_of_Development: 'Estado de Desarrollo',
      Pests_Comments: 'Comentarios',
      Pests_NDetected: 'Nº Detectados',
      Pests_Next: 'Siguiente',
      Pests_Finish: 'Terminar',
      Pests_TakePicture: 'Tomar una Foto',
      ExtremeWeatherConditions_Type: 'Tipo',
      phenologicalStates_search_text: 'Nombre o Código de Estado Fenológico',
      phenologicalStates_listInput_placeholder: 'Nombre o Código',
      phenologicalStates_listItem_title: 'Ningún Estado Fenológico Encontrado',
      formAction_Dialog_Confirm: '¿Está seguro de que desea salir? Si sale no se guardará ningún dato añadido.',
      formAction_Alert_MissingData: 'Faltan datos por rellenar',
      formAction_Dialog_Finish: 'Si finaliza no se guardarán los elementos de la pantalla actual, pero sí los añadidos hasta ahora. ¿Está seguro?',
      AccordionItem_Notes: 'Notas',
      Register_registerText: 'REGISTRAR',
      Startorder_Tools: 'Herramientas',
      TasksList_Workers_title: 'Trabajadores',
      TasksList_WorkersSelection_title: 'No hay trabajadores en la tarea',
      TasksList_WorkersSelection_subtitle: 'Pulsar el botón superior para seleccionar trabajadores',
      Generic_Error: 'Ha ocurrido un error inesperado. Por favor, póngase en contacto con soporte.',
      NoConnection_Error: 'Ha ocurrido un error inesperado. (Asegúrese de tener conexión)',
      Code_Error: 'Error de sincronización. Código de soporte: ',
      TakePicture_ConfirmDeletePicture: '¿Está seguro de que desea eliminar esta imagen?',
      pestAndDiseases_ViewAll: 'Ver todos',
      Error_TooManyPictures: 'Ha alcanzado el número máximo de fotografías en este reporte',
      formAction_Alert_EnableGPS: 'Debe conceder permisos de ubicación y habilitar el GPS',
      checkOut_checkInLabel: 'CheckIn',
      checkOut_checkOutLabel: 'CheckOut',
      checkOut_select_date: 'Seleccionar fecha',
      checkOut_select_time: 'Seleccionar hora',
      checkOut_newCheckIn: 'Nuevo CheckIn',
      checkOut_newCheckOut: 'Nuevo CheckOut',
      CheckOutMulti_checkout_alert: 'El CheckIn debe ser anterior al CheckOut',
      binContent_Egress: 'Egreso:',
      Start_edit_order_disolucion: 'DISOLUCIÓN PLANIFICADA',
    },
  },
};
export default dictionary;
