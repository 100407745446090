<template>
  <f7-page
    class="content-list-order main-fixedMonitoringPoint  bg-cream"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${$t('LeftPanel_Subtitle_FixedMonitoringPoint')}`"
      :show-button-sync="true"
    />
    <div class="container">
      <f7-block class="content-list padding-top">
        <!-- SELECT ACTION -->
        <f7-list>
          <f7-list-group>
            <div class="content-list__title text-color-nutella margin-left">
              {{ $t('SelectAction_Title') }}
            </div>
            <f7-list-item
              :title="`${$t('SelectAction_Title')}`"
              smart-select
              :smart-select-params="{openIn: 'popover', closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="selectAction"
                v-model="selectedScoutingType"
                @change="changeScoutingType($event.target.value)"
              >
                <option
                  v-for="(item, index) in scoutingTypes"
                  :key="'scouting-'+index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- SCOUTING POINTS -->
        <f7-list>
          <f7-list-group>
            <div class="content-list__title text-color-nutella margin-left">
              {{ $t('FixedMonitoringPoint_Title_MonitoringPoint') }}
            </div>
            <f7-list-item
              id="classificationElement"
              :title="`${$t('FixedMonitoringPoint_Title_MonitoringPoint')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="classification"
                v-model="selectedScoutingPoint"
                @change="changeScoutingPoint($event.target.value)"
              >
                <option
                  v-for="(item, index) in filteredScoutingPoints"
                  :key="'point-'+index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
      </f7-block>
      <div
        v-if="selectedScoutingPoint !== ''"
        class="list-order-box no-padding-vertical"
      >
        <div class="card card-outline no-margin bg-gray">
          <div class="card-content card-content-padding">
            <div class="list media-list no-chevron">
              <ul>
                <li class="item-link item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title x2 text-color-kinder">
                        {{ pointLocation }}
                      </div>
                      <div class="item-after">
                        <div class="number-plant">
                          {{ pointNumber_plants }}
                          <img
                            class="icon-line margin-left-half"
                            width="20"
                            height="20"
                            :src="images.leaf"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="item-subtitle padding-bottom">
                      {{ pointPlanting_year }} {{ pointVariety }}
                    </div>
                    <div class="item-text">
                      <f7-row>
                        <f7-col
                          width="100"
                          class="no-padding-bottom padding-top display-flex align-items-center"
                        >
                          <img
                            class="icon-line"
                            width="16"
                            height="16"
                            :src="images.compass"
                          > 
                          &nbsp;
                          <span class="text-color-nutella"> {{ pointLatitude }} {{ pointLongitude }}</span>
                        </f7-col>
                      </f7-row>
                    </div>
                    <div
                      class="item-extra margin-top-half text-color-avellana"
                    >
                      {{pointNote}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green margin"
        col
        button
        button-large
        button-raised
        fill
        @click="navigateTo('/formAction/')"
      >
        {{ $t("FixedMonitoringPoint_Button_Continue") }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';
import PhenologicalStates from '../../js/models/PhenologicalStates';
import PlaguesAndDiseases from '../../js/models/plaguesAndDiseases';
import ActionTypes from '../../js/models/Families';
import ScoutingPoint from '../../js/models/ScoutingPoint';

import ImageLeaf from '../../static/img/ic_leaf_circle.svg';
import ImageCompass from '../../static/img/ic_compass.svg'

export default {
  name: 'FixedMonitoringPoint',
  components: {
    navbar,
  },
  props: {
  },
  data() {
    return {
      loaded: false,
      images: {
        leaf: ImageLeaf,
        compass: ImageCompass,
      },
      scoutingTypes: [],
      selectedScoutingType: '',
      scoutingPoints: [],
      filteredScoutingPoints: [],
      selectedScoutingPoint: '',
      pointName: '',
      pointPlanting_year: '',
      pointVariety: '',
      pointLatitude: '',
      pointLongitude: '',
      pointNumber_plants: '',
      pointNote: '',
      pointLocation: '',
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['formattedTree']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.syncTasksSync()
        .catch((error) => { this.$f7.dialog.alert(error); });
      this.resetScoutingStates();
      this.resetStates();
      await this.fetchLocationTree();
      const phenological = await PhenologicalStates.getAll();
      const plagues = await PlaguesAndDiseases.getAll();
      const scoutings = await ActionTypes.getScoutingType();
      if (scoutings.length > 0) {
        this.scoutingTypes = scoutings[0].values;
      }
      this.scoutingPoints = await ScoutingPoint.getAll();
      this.setPhenologicalStates(phenological);
      this.setPlaguesAndDiseases(plagues);
    } catch (error) {
      console.warn(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  methods: {
    changeScoutingType(item) {
      this.selectedScoutingType = item;
      this.selectedScoutingPoint = '';
      this.filteredScoutingPoints = this.scoutingPoints.filter((element) => element.type === item);
      this.setActualSelection(item.toLowerCase());
    },
    navigateTo(path) {
      if (this.selectedScoutingPoint === '') {
        this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
      } else {
        this.setNewFormMonitoringPoint();
        this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
      }
    },
    changeScoutingPoint(item) {
      this.selectedScoutingPoint = item;
      const pointObject = this.scoutingPoints.filter((element) => element.id === item);
      this.pointName = pointObject[0].name;
      this.pointPlanting_year = pointObject[0].planting_year;
      this.pointVariety = pointObject[0].variety;
      this.pointLatitude = pointObject[0].coordinates.latitude;
      this.pointLongitude = pointObject[0].coordinates.longitude;
      this.pointNumber_plants = pointObject[0].number_plants;
      this.pointNote = pointObject[0].note;
      this.pointLocation = this.getNameLocationById(pointObject[0].location);
      this.setSelectedScoutingPoint(item);
    },
    getNameLocationById(idLocation) {
      let nameLocation = '';
      const filteredElements = this.formattedTree.filter((element) => element.bd_id === idLocation);
      if (filteredElements.length > 0) {
        nameLocation = filteredElements[0].name;
      }
      return nameLocation;
    },
    ...mapActions('scouting', ['setActualSelection', 'setPhenologicalStates', 'setPlaguesAndDiseases', 'resetScoutingStates', 'setSelectedScoutingPoint', 'setNewFormMonitoringPoint']),
    ...mapActions('greenhouse', ['fetchLocationTree', 'resetStates']),
    ...mapActions('databaseSync', ['syncTasksSync']),
  },
};

</script>

<style lang="scss">
.main-fixedMonitoringPoint{
  .content-list{
    &__title{
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 5px;
    }
    ul{
      &::before, &::after{
        height: 0!important;
      }
    }
    .smart-border {
      padding-bottom: 10px;
      .item-link {
        .item-content {
          padding: 0;
          .item-inner {
            background-color: #ffffff;
            min-height: 56px;
            border-radius: 8px;
            border: 1px solid #d8d8d8 !important;
            color: var(--f7-badge-bg-color);
            font-weight: 500;
            line-height: 21px;
            padding: 5px 15px;
            width: 100%;
            .item-after {
              margin-left: 0;
              padding: 0;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              color: var(--ha-darkgray);
            }
          }
          .item-title {
            display: none;
          }
        }
      }
    }
  }
  .stepper{
    display: flex;
    justify-content: center;
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}

</style>
