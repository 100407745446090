import tab from 'framework7-vue/components/tab';
import moment from 'moment';
import db from '../../services/Database';
import Employees from './Employees';
import ModifiedRecords from './ModifiedRecords';

export default {
  getAll() {
    return db.tasksSync.toArray();
  },
  getFilterActivity(activityCode) {
    return db.tasksSync.where('activity_code').equals(activityCode).toArray();
  },
  async insert(data) {
    try {
      await db.tasksSync.put(data);
      return true;
    } catch (e) {
      const error = { error: 'ERROR', text: 'Error: Task could not be created' };
      return error;
    }
  },

  // TODO: Sería interesante sacar todas las comprobaciones a funciones auxiliares
  async initTask(id, idLocation) {
    try {
      const check = await db.tasksSync.where({ task: id }).and((item) => item.location_id === idLocation
      && (item.status === 'IN_PROGRESS' || item.status === 'OPEN' || item.status === 'PENDING')).toArray();
      if (check.length > 0) {
        const error = { error: 'ERROR', text: 'Error: A task with that location already exists in this moment' };
        return error;
      }
      let idOff = 1;
      const task = await db.tasksSync.where({ task: id }).and((item) => item.location_id === idLocation).toArray();
      if (task.length > 0) {
        idOff = task.length + 1;
      }
      const tasks = await db.tasksDefinition.where('id').equals(id).toArray();
      const locations = await db.locations.where('id').equals(idLocation).toArray();

      const newTask = {};
      newTask.activity = tasks[0].activity;
      newTask.activity_code = tasks[0].activity_code;
      newTask.name = tasks[0].name;
      newTask.task_code = tasks[0].code;
      newTask.task = tasks[0].id;
      newTask.status = 'OPEN';
      newTask.location = locations[0].code;
      newTask.location_id = locations[0].id;
      newTask.farm = locations[0].farm;
      newTask.workers = [];
      newTask.numWorkerInWorking = 0;
      newTask.idOffline = idOff;
      newTask.id = idOff.toString();
      this.insert(newTask);
      ModifiedRecords.insert({ type: 'task', idTask: newTask.id, idLocation: newTask.location_id });
      return newTask;
    } catch (e) {
      const error = { error: 'ERROR', text: 'Error: Task could not be created' };
      return error;
    }
  },
  checkLocation(task, idLocation) {
    if (task.length === 0) {
      return false;
    }
    const alreadyCreated = task.filter((obj) => obj.location_id === idLocation);
    if (alreadyCreated.length > 0) {
      return true;
    }
    return false;
  },

  async checkWorkerInTask(idTask, type, code) {
    let task = null;
    if (type === 'id') {
      task = await db.tasksSync.where({ id: idTask })
        .and((tas) => tas.workers.some((item) => item.id === code)).toArray();
    } else if (type === 'NFC') {
      task = await db.tasksSync.where({ id: idTask })
        .and((tas) => tas.workers.some((item) => (item.badge_id === code)
        || (item.auth !== undefined && item.auth.some((el) => el.type === 'NFC' && el.code === code))))
        .toArray();
    }
    let employee = null;
    if (task.length > 0) {
      for (let i = 0; i < task[0].workers.length; i += 1) {
        if (task[0].workers[i].badge_id === code
          || task[0].workers[i].id === code
          || (task[0].workers[i].auth !== undefined && task[0].workers[i].auth.filter((el) => el.type === 'NFC' && el.code === code))
        ) {
          employee = task[0].workers[i];
          break;
        }
      }
    }
    return employee;
  },

  async checkin(idTask, locationId, emplo) {
    const task = await db.tasksSync.where({ id: idTask }).and((item) => item.location_id.some((id) => id === locationId[0])).toArray();
    // Comprobamos si existe el empleado
    let employee;
    if (emplo.auth.type === 'id') {
      employee = await Employees.getEmployeeById(emplo.auth.code);
    } else {
      employee = await Employees.getEmployee(emplo.auth.type, emplo.auth.code);
    }
    if (employee === null) {
      const error = { error: 'ERROR', text: `Error: There is no employee with that code: ${emplo.auth.code}` };
      return error;
    }
    // Comprobamos si el trabajador está ya trabajando en esta tarea para hacer checkout
    const alreadyWorking = task.filter((obj) => obj.workers.some((worker) => worker.id === employee.id));
    if (alreadyWorking.length > 0) {
      for (let i = 0; i < task[0].workers.length; i += 1) {
        if (task[0].workers[i].id === employee.id) {
          // Cuando encontremos el trabajador dentro de la tarea, comprobamos si ya ha hecho checkout
          if (task[0].workers[i].end_date != null) {
            const error = { error: 'ERROR', text: 'Error: This worker has already checked out before' };
            return error;
          }
        }
      }
      // Si no he hecho checkout, lo hacemos
      return 'checkout';
    }
    // Comprobamos si el trabajador está ya en otra tarea in_progress
    const workerInAnotherTaskWorking = await db.tasksSync.where({ status: 'IN_PROGRESS' }).and((item) => item.workers.some((worker) => worker.id === employee.id && worker.end_date === null)).toArray();
    if (workerInAnotherTaskWorking.length > 0) {
      const error = { error: 'ERROR', text: 'Error: This employee is working on another task' };
      return error;
    }

    employee.init_date = moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    employee.end_date = null;
    employee.performance = 0;
    employee.working_area = 0;
    employee.unit = '';
    employee.comment = '';

    task[0].numWorkerInWorking = this.inicializarNumWorkerInWorking(task[0]);
    task[0].numWorkerInWorking += 1;
    task[0].workers.push(employee);
    task[0].status = 'IN_PROGRESS';
    this.actualizar(task[0]);
    ModifiedRecords.insert({ type: 'task', idTask: task[0].id, idLocation: task[0].location_id[0] });
    return task[0];
  },

  async checkOut(newTask, worker, productivity, productivity2, note, unit, initDate = null, endDate = null) {
    const task = await db.tasksSync.where({ id: newTask }).toArray();
    let employee;
    let id;
    if (worker.auth.type === 'id') {
      id = worker.auth.code;
    } else {
      employee = await Employees.getEmployee(worker.auth.type, worker.auth.code);
      if (employee === null) {
        const error = { error: 'ERROR', text: `Error: There is no employee with that code: ${worker.auth.code}` };
        return error;
      }
      id = employee.id;
    }

    let posicion = null;
    for (let i = 0; i < task[0].workers.length; i += 1) {
      task[0].numWorkerInWorking = this.inicializarNumWorkerInWorking(task[0]);

      if (task[0].workers[i].id === id) {
        posicion = i;
      } else if (task[0].workers[i].badge_id === id) {
        posicion = i;
      }

      if (posicion !== null) {
        task[0].numWorkerInWorking -= 1;
        if (task[0].numWorkerInWorking === 0) {
          task[0].status = 'COMPLETED';
        }
        task[0].workers[i].init_date = initDate ?? task[0].workers[i].init_date;
        task[0].workers[i].end_date = endDate ?? moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        task[0].workers[i].performance = productivity;
        task[0].workers[i].comment = note;
        task[0].workers[i].unit = unit;
        task[0].workers[i].working_area = productivity2;
        this.actualizar(task[0]);
        ModifiedRecords.insert({ type: 'task', idTask: task[0].id, idLocation: task[0].location_id[0] });
        break;
      }
    }
    if (posicion === null) {
      const error = { error: 'ERROR', text: `Error: There is no employee in this task with the id: ${id}` };
      return error;
    }
    return task[0];
  },

  async checkOutGroup(newTask, productivity, productivity2, note, unit, endDate = null, initDate = null) {
    const task = await db.tasksSync.where({ id: newTask }).toArray();
    let numWorkerInWorking = 0;
    for (let i = 0; i < task[0].workers.length; i += 1) {
      if (task[0].workers[i].end_date === null) {
        numWorkerInWorking += 1;
      }
    }
    for (let i = 0; i < task[0].workers.length; i += 1) {
      if (task[0].workers[i].end_date === null) {
        task[0].workers[i].init_date = initDate ?? task[0].workers[i].init_date;
        task[0].workers[i].end_date = endDate ?? moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        task[0].workers[i].performance = parseFloat(productivity / numWorkerInWorking).toFixed(2);
        task[0].workers[i].comment = note;
        task[0].workers[i].unit = unit;
        task[0].workers[i].working_area = parseFloat(productivity2 / numWorkerInWorking).toFixed(2);
      }
    }
    task[0].status = 'COMPLETED';
    task[0].numWorkerInWorking = 0;
    this.actualizar(task[0]);
    ModifiedRecords.insert({ type: 'task', idTask: task[0].id, idLocation: task[0].location_id[0] });

    return task[0];
  },
  async getByCode(code) {
    const tasks = await db.tasksSync.where('code').equals(code).toArray();
    if (!tasks.length) {
      return null;
    }
    return tasks[0];
  },

  async delete(task) {
    if (task.id !== undefined) {
      db.modifiedRecords.put({ type: 'taskDeleted', idTask: task.id, idLocation: task.location[0] });
    }
    db.tasksSync.where({ id: task.id }).delete();
    return null;
  },

  actualizar(task) {
    db.tasksSync.where({ id: task.id }).modify({ workers: task.workers, numWorkerInWorking: task.numWorkerInWorking, status: task.status })
      .then(() => { });
  },
  actualizarMachineryPerformance(task) {
    db.tasksSync.where({ id: task.id }).modify({ workers: task.workers, machinery_performance: task.machinery_performance })
      .then(() => { });
  },
  actualizarProductPerformance(task) {
    db.tasksSync.where({ id: task.id }).modify({ workers: task.workers, product_performance: task.product_performance })
      .then(() => { });
  },
  updateTaskVehicles(idTarea, location, machineryDrivers) {
    db.tasksSync.where({ id: idTarea })
      .and((item) => item.location_id.some((id) => id === location[0]))
      .modify({ items_drivers: machineryDrivers })
      .then(() => { });
    ModifiedRecords.insert({ type: 'task', idTask: idTarea, idLocation: location[0] });
  },
  updateTaskAttachment(idTarea, location, attachment) {
    db.tasksSync.where({ id: idTarea })
      .and((item) => item.location_id.some((id) => id === location[0]))
      .modify({ attachments_vehicles: attachment })
      .then(() => { });
    ModifiedRecords.insert({ type: 'task', idTask: idTarea, idLocation: location[0] });
  },
  updateTaskLocation(idTarea, locations) {
    db.tasksSync.where({ id: idTarea })
      .and((item) => item.location_id.some((id) => id === locations[0]))
      .modify({ location_id: locations })
      .then(() => { });
    ModifiedRecords.insert({ type: 'task', idTask: idTarea, idLocation: locations });
  },
  async updateMachineryId(idTarea, machineryPerformance) {
    const task = await db.tasksSync.where({ id: idTarea }).toArray();
    for (let i = 0; i < task[0].items_drivers.length; i += 1) {
      task[0].machinery_performance = machineryPerformance;
    }
    this.actualizarMachineryPerformance(task[0]);
    ModifiedRecords.insert({ type: 'task', idTask: idTarea, idLocation: task[0].location_id[0] });
    return task[0];
  },
  async updateFitosanitary(idTarea, attachment) {
    const task = await db.tasksSync.where({ id: idTarea }).toArray();
    for (let i = 0; i < task[0].product_performance.length; i += 1) {
      task[0].product_performance = attachment;
    }
    this.actualizarProductPerformance(task[0]);
    ModifiedRecords.insert({ type: 'task', idTask: idTarea, idLocation: task[0].location_id[0] });
    return task[0];
  },
  inicializarNumWorkerInWorking(task) {
    let numWorkerInWorking = 0;
    if (task.numWorkerInWorking !== undefined) {
      return task.numWorkerInWorking;
    }
    if (task.workers.length > 0) {
      for (let i = 0; i < task.workers.length; i += 1) {
        if (task.workers[i].end_date === null) {
          numWorkerInWorking += 1;
        }
      }
    }
    return numWorkerInWorking;
  },
};
