<template>
  <div>
    <f7-button
      class=""
      @click="displayError"
    >
      <f7-icon
        :class="`icon-menu ic-alert`"
      />
    </f7-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ButtonSync',

  props: {
  },
  computed: {
    ...mapGetters('databaseSync', ['SyncErrorText']),
  },
  methods: {
    displayError() {
      if (this.SyncErrorText !== '') {
        this.$f7.dialog.alert(this.SyncErrorText);
      } else {
        this.$f7.dialog.alert(this.$t('NoConnection_Error'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-menu{
  width: 38px;
  height: 38px;
}
.ic-alert {
  background-image: url("../../static/img/ic_alert_round.svg");
}
</style>
