import db from '../../services/Database';

export default {
  getAll() {
    return db.phenologicalStates.toArray();
  },
  async getByNameOrCode(filteredName = '') {
    let states = [];
    if (filteredName !== '') {
      states = await db.phenologicalStates.filter(
        (element) => (element.phase.toUpperCase().includes(filteredName.toUpperCase())
        || element.code.toUpperCase().includes(filteredName.toUpperCase())),
      ).toArray();
    } else {
      return db.phenologicalStates.toArray();
    }
    return states;
  },
};
