import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import authentication from './modules/authentication';
import worker from './modules/worker';
import greenhouse from './modules/greenhouse';
import scouting from './modules/scouting';
import TaskManager from './modules/TaskManager';
import AccessControl from './modules/AccessControl';
import databaseSync from './modules/database/sync';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    worker,
    greenhouse,
    scouting,
    AccessControl,
    TaskManager,
    databaseSync,
  },
  plugins: [new VuexPersistence({
    storage: window.localStorage,
  }).plugin],
});

store.dispatch('authentication/initialize');

export default store;
