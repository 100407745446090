import db from '../../services/Database';

export default {
  getAll() {
    return db.employees.toArray();
  },
  async getEmployee(type, code) {
    const employees = await this.getAll();
    for (let i = 0; i < employees.length && (employees[i].auth !== undefined
      || employees[i].auth.length) > 0; i += 1) {
      for (let j = 0; j < employees[i].auth.length; j += 1) {
        if (employees[i].auth[j].code === code && employees[i].auth[j].type === type) {
          return employees[i];
        }
      }
    }
    return null;
  },
  async getDrivers() {
    const employee = await db.employees.toArray();
    const response = employee.filter((obj) => obj.properties.some((item) => item.key === 'drive' && item.value === 'True'));
    return response;
  },
  async getEmployeeById(idEmployee) {
    const employee = await db.employees.where({ id: idEmployee }).toArray();
    if (employee.length > 0) {
      return employee[0];
    }
    return null;
  },
  async getEmployeeByCode(codeEmployee) {
    const employee = await db.employees.where({ code: codeEmployee }).toArray();
    if (employee.length > 0) {
      return employee[0];
    }
    return null;
  },
  async getByFullname(fullname = '') {
    const arrayfullname = fullname.split(' ');
    let employee = [];
    for (let i = 0; i < arrayfullname.length; i += 1) {
      if (employee.length === 0) {
        // eslint-disable-next-line no-await-in-loop
        employee = await db.employees.filter(
          (emp) => emp.name.toUpperCase().includes(arrayfullname[i].toUpperCase())
          || emp.surname.toUpperCase().includes(arrayfullname[i].toUpperCase()),
        ).toArray(); // Si el array filtrado está vacío, busca en la base de datos
      } else {
        employee = employee.filter(
          (emp) => emp.name.toUpperCase().includes(arrayfullname[i].toUpperCase())
          || emp.surname.toUpperCase().includes(arrayfullname[i].toUpperCase()),
        ); // Si el array filtrado no está vacío, filtra en el mismo array.
      }
    }
    return employee;
  },
  async exists(fullname) {
    const employee = await db.employees
      .filter((emp) => fullname.has(emp.name) || fullname.has(emp.surname)).toArray();
    return Boolean(employee.length);
  },
  async insert(data) {
    await db.employees.add(data);
    return true;
  },
};
