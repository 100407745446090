<template>
  <!-- Swipe to step demo sheet -->
  <f7-sheet
    id="bottomPanelButons"
    class="content-select-productivity"
    style="height:auto; --f7-sheet-bg-color: #fff;"
    swipe-to-close
    backdrop
  >
    <!-- Initial swipe step sheet content -->
    <div class="sheet-modal-swipe-step">
      <div
        id="title"
        class="margin-top text-align-center text-color-gray margin-bottom"
        v-text="elements[0].title"
      />
      <div class="display-flex justify-content-center align-items-center filaIconos">
        <f7-link
          v-for="(item, index) in elements"
          :key="index"
          :class="`icon-menu ${item.class}`"
          @click="navigateTo(item.link)"
        >
          <div class="texto-icono">
            {{ item.name }}
          </div>
        </f7-link>
      </div>
    </div>
  </f7-sheet>
</template>
<script>

export default {
  name: 'BottomPanel',
  props: {
    elements: { type: Array, default: () => [] },
  },
  data() {
    return {
    };
  },
  methods: {
    navigateTo(link) {
      this.$f7.sheet.close();
      this.$f7.views.main.router.navigate(link, { reloadCurrent: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.filaIconos {
  padding: 10px 0px 55px;
}
.content-select-productivity .icon-menu {
    width: 120px;
    height: 48px;
    margin: 0 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

}

.content-select-productivity .texto-icono{
  padding-top: 80px;
  text-align:center;
  color: var(--fr-pending-shade);
  font-size: 12px;
  line-height: 1;
  display: block;
}
 /*
  Comento lo referente a eliminar, ya que ahora mismo se ha pedido
  que no este. Hasta que no se confirme 100% no lo quito del
  código..
  icon-delete {
  background-image: url('../static/img/delete.svg');
}*/
.icon-edit {
  background-image: url('../static/img/edit.svg');
}
.icon-run_new_order {
  background-image: url('../static/img/run_new_order.svg');
}
.icon-end_task {
  background-image: url('../static/img/end_task.svg');
}
.icon-individual {
  background-image: url('../static/img/ic_individual.svg');
}
.icon-multiple {
  background-image: url('../static/img/ic_group.svg');
}
</style>
