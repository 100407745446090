<template>
  <f7-page
    class="content-list-order main-formAction  bg-cream"
  >
    <navbar
      :new-icon-enable="true"
      :text="setTitle()"
      :path-back="pathBack"
      :back-with-event="true"
    />
    <!-- DATE -->
    <div class="bg-color-kinder padding-half text-align-center">
      <span class="text-color-white">{{ getActualDate() }}</span>
    </div>
    <div class="container">
      <f7-block class="content-list padding-top">
        <!-- PHENOLOGICAL STATE OF THE CROP (OPTIONAL) -->
        <f7-list>
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('Pests_Phenological_State_of_the_Crop') }}
            </div>
            <f7-list-item
              :title="`${$t('Pests_Phenological_State_of_the_Crop')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="Phenological_State"
                v-model="selectedPhenologicalState"
                @change="changePhenologicalState($event.target.value)"
              >
                <option
                  v-for="(item, index) in phenologicalStates"
                  :key="'phenological-'+index"
                  :value="item.id"
                >
                  {{ item.code }} ({{ item.phase }})
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- SPECIES -->
        <f7-list
          v-if="actualSelection === 'diseases' || actualSelection === 'pests'"
        >
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('Pests_Species') }}
            </div>
            <f7-list-item
              :title="`${$t('Pests_Species')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="Species"
                v-model="selectedSpecie"
                @change="changeSpecie($event.target.value)"
              >
                <option
                  v-for="(item, index) in species"
                  :key="'plagues-'+index"
                  :value="item.id"
                >
                  {{ item.scientific_name }} ({{ item.name }})
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- STATE OF DEVELOPMENT -->
        <f7-list
          v-if="actualSelection === 'diseases' || actualSelection === 'pests'"
        >
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('Pests_State_of_Development') }}
            </div>
            <f7-list-item
              :title="`${$t('Pests_State_of_Development')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="Species"
                v-model="selectedPlagueState"
                @change="changePlagueState($event.target.value)"
              >
                <option
                  v-for="(item, index) in plaguesStates"
                  :key="'plaguesStates-'+index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- TYPE -->
        <f7-list
          v-if="actualSelection === 'extreme weather conditions'"
        >
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('ExtremeWeatherConditions_Type') }}
            </div>
            <f7-list-item
              :title="`${$t('ExtremeWeatherConditions_Type')}`"
              smart-select
              :smart-select-params="{openIn: 'popover', closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="Type"
                v-model="selectedWeatherCondition"
                @change="changeWeatherCondition($event.target.value)"
              >
                <option
                  v-for="(item, index) in weatherConditions"
                  :key="'weatherCondition-'+index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- NOTES -->
        <f7-list>
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('Pests_Comments') }}
            </div>
            <f7-list-input
              ref="comments"
              class="content-textarea"
              type="textarea"
              resizable
            />
          </f7-list-group>
        </f7-list>

        <!-- Nº Detected -->
        <f7-block v-if="actualSelection === 'pests' ">
          <div class="content-list__title text-align-center">
            {{ $t("Pests_NDetected") }}
          </div>
          <f7-stepper
            :min="0"
            :max="9999"
            :step="1"
            large
            fill
            :value="0"
            @input="changeInputStepper($event.target.value)"
          />
        </f7-block>
      </f7-block>

      <TakePicture />

      <f7-row class="padding-left padding-right padding-bottom margin">
        <f7-col>
          <!-- Finish -->
          <f7-button
            name="btnContinue"
            class="botonPrimary color-green"
            col
            button
            button-large
            button-raised
            fill
            @click="finish()"
          >
            {{ $t("Pests_Finish") }}
          </f7-button>
        </f7-col>
        <f7-col>
          <!-- Next -->
          <f7-button
            name="btnContinue"
            class="bg-color-nutella"
            col
            button
            button-large
            button-raised
            fill
            @click="next()"
          >
            {{ $t("Pests_Next") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import navbar from '../../components/NavBar.vue';
import EventBus from '../../js/event-bus';
import Families from '../../js/models/Families';
import PlaguesAndDiseases from '../../js/models/plaguesAndDiseases';
import ScoutingPoint from '../../js/models/ScoutingPoint';
import TakePicture from '../../components/TakePicture.vue';

export default {
  name: 'FormAction',
  components: {
    navbar,
    TakePicture,
  },
  props: {
  },
  data() {
    return {
      loaded: false,
      stepper: 0,
      selectedPhenologicalState: '',
      selectedSpecie: '',
      pathBack: '/fixedMonitoringPoint/',
      plaguesStates: [],
      selectedPlagueState: '',
      weatherConditions: [],
      selectedWeatherCondition: '',
      species: [],
    };
  },
  computed: {
    ...mapGetters('scouting', ['phenologicalStates', 'plaguesAndDiseases', 'actualSelection', 'incidentName', 'formMonitoringPoint', 'selectedScoutingPoint', 'locationSelected', 'pictures', 'latitude', 'longitude']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      EventBus.$on('go_back_event', this.goBack);
      if (this.incidentName !== '') {
        this.pathBack = '/casualRegister/';
      }
      if (this.actualSelection === 'pests') {
        const plagues = await Families.getPlaguesStates();
        if (plagues) {
          this.plaguesStates = plagues[0].values;
        }
      }
      if (this.actualSelection === 'diseases') {
        const diseases = await Families.getDiseasesStates();
        if (diseases) {
          this.plaguesStates = diseases[0].values;
        }
      }
      const conditions = await PlaguesAndDiseases.getWeatherConditions();
      if (conditions) {
        this.weatherConditions = conditions;
      }
      this.species = this.plaguesAndDiseases.filter(
        (element) => element.type === this.actualSelection.toUpperCase(),
      );
    } catch (error) {
      console.warn(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  beforeDestroy() {
    EventBus.$off('go_back_event');
    this.setPictures([]);
  },
  methods: {
    setTitle() {
      this.formTitle = '';
      switch (this.actualSelection) {
        case 'pests':
          this.formTitle = this.$t('formAction_Title_Pests');
          break;
        case 'diseases':
          this.formTitle = this.$t('formAction_Title_Diseases');
          break;
        case 'phenological':
          this.formTitle = this.$t('formAction_Title_Phenological');
          break;
        case 'extreme weather conditions':
          this.formTitle = this.$t('formAction_Title_WeatherConditions');
          break;
        case 'other':
          this.formTitle = this.$t('formAction_Title_Other');
          break;
        default:
          break;
      }
      return this.formTitle;
    },
    getActualDate() {
      return moment(new Date()).format('YYYY/MM/DD');
    },
    changeInputStepper(inputValue) {
      this.stepper = inputValue;
      return inputValue;
    },
    changePhenologicalState(item) {
      this.selectedPhenologicalState = item;
      this.setSelectedPhenologicalState(item);
    },
    changeSpecie(item) {
      this.selectedSpecie = item;
      this.setSelectedPlague(item);
    },
    changePlagueState(item) {
      this.selectedPlagueState = item;
      this.setSelectedPlagueState(item);
    },
    changeWeatherCondition(item) {
      this.selectedWeatherCondition = item;
      this.setSelectedWeatherCondition(item);
    },
    next() {
      let goNext = true;
      switch (this.actualSelection) {
        case 'diseases':
          if (this.selectedPhenologicalState === '' || this.selectedSpecie === '' || this.selectedPlagueState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.saveDisease();
          break;
        case 'pests':
          if (this.selectedPhenologicalState === '' || this.selectedSpecie === '' || this.selectedPlagueState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.savePest();
          break;
        case 'extreme weather conditions':
          if (this.selectedPhenologicalState === '' || this.selectedWeatherCondition === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.saveWeatherCondition();
          break;
        case 'phenological':
          if (this.selectedPhenologicalState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.savePhenological();
          break;
        case 'other':
          if (this.selectedPhenologicalState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.saveOther();
          break;
        default:
          break;
      }
      if (goNext) {
        this.setPictures([]);
        this.$f7.views.main.router.navigate('/formAction/', { reloadCurrent: true });
      }
    },
    saveDisease() {
      const newForm = this.formMonitoringPoint;
      const disease = {
        plagues_and_diseases: this.selectedSpecie,
        phenological_phase: this.selectedPhenologicalState,
        presence: true,
        state: this.selectedPlagueState,
        comments: this.$refs.comments.$refs.inputEl.value,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.disease.push(disease);
      this.setFormMonitoringPoint(newForm);
    },
    savePest() {
      const newForm = this.formMonitoringPoint;
      const plague = {
        plagues_and_diseases: this.selectedSpecie,
        phenological_phase: this.selectedPhenologicalState,
        state: this.selectedPlagueState,
        number: this.stepper,
        comments: this.$refs.comments.$refs.inputEl.value,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.plagues.push(plague);
      this.setFormMonitoringPoint(newForm);
    },
    saveWeatherCondition() {
      const newForm = this.formMonitoringPoint;
      const wheaterCondition = {
        plagues_and_diseases: this.selectedWeatherCondition,
        phenological_phase: this.selectedPhenologicalState,
        comments: this.$refs.comments.$refs.inputEl.value,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.wheater_conditions.push(wheaterCondition);
      this.setFormMonitoringPoint(newForm);
    },
    savePhenological() {
      const newForm = this.formMonitoringPoint;
      const phenology = {
        phenological_phase: this.selectedPhenologicalState,
        comments: this.$refs.comments.$refs.inputEl.value,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.phenology.push(phenology);
      this.setFormMonitoringPoint(newForm);
    },
    saveOther() {
      const newForm = this.formMonitoringPoint;
      const other = {
        phenological_phase: this.selectedPhenologicalState,
        comments: this.$refs.comments.$refs.inputEl.value,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.others.push(other);
      this.setFormMonitoringPoint(newForm);
    },
    async finish() {
      this.$f7.preloader.show();
      try {
        switch (this.actualSelection) {
          case 'diseases':
            if (this.selectedPhenologicalState !== '' && this.selectedSpecie !== '' && this.selectedPlagueState !== '') {
              this.saveDisease();
            }
            break;
          case 'pests':
            if (this.selectedPhenologicalState !== '' && this.selectedSpecie !== '' && this.selectedPlagueState !== '') {
              this.savePest();
            }
            break;
          case 'extreme weather conditions':
            if (this.selectedPhenologicalState !== '' && this.selectedWeatherCondition !== '') {
              this.saveWeatherCondition();
            }
            break;
          case 'phenological':
            if (this.selectedPhenologicalState !== '') {
              this.savePhenological();
            }
            break;
          case 'other':
            if (this.selectedPhenologicalState !== '') {
              this.saveOther();
            }
            break;
          default:
            break;
        }
        // Sleep necesario para guardar la foto del ultimo formulario por retardo de vuex
        await this.sleep(1000);
        this.confirmFinish();
      } catch (error) {
        console.warn(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async confirmFinish() {
      const newForm = this.formMonitoringPoint;
      newForm.date = moment(new Date()).format('YYYY-MM-DD HH:mm');
      if (this.selectedScoutingPoint !== '') {
        newForm.scouting_point_id = this.selectedScoutingPoint;
      } else {
        const scoutingPoint = {
          type: this.actualSelection.toUpperCase(),
          name: this.incidentName,
          location: this.locationSelected,
          coordinates: {
            latitude: parseFloat(this.latitude).toFixed(4),
            longitude: parseFloat(this.longitude).toFixed(4),
          },
        };
        newForm.scouting_point = scoutingPoint;
      }
      if (newForm.disease.length > 0
          || newForm.others.length > 0
          || newForm.phenology.length > 0
          || newForm.plagues.length > 0
          || newForm.wheater_conditions.length > 0
      ) {
        await ScoutingPoint.insertScoutingForm(newForm);
      }
      this.$f7.views.main.router.navigate(this.pathBack, { reloadCurrent: true });
    },
    // Convertir imagen a base64
    convertImgToBase64(url, callback, outputFormat) {
      let canvas = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL(outputFormat || 'image/jpeg');
        callback.call(this, dataURL);
        // Clean up
        canvas = null;
      };
      img.src = url;
    },
    picturesToBase64(arrayPictures) {
      const picturesArrayToSend = [];
      arrayPictures.forEach((element) => {
        // Se añade al array de fotos a mostrar el base64 de la foto
        this.convertImgToBase64(element, (base64Img) => {
          picturesArrayToSend.push(base64Img);
        });
      });
      return picturesArrayToSend;
    },
    goBack() {
      const self = this;
      this.$f7.dialog.confirm(
        `${this.$t('formAction_Dialog_Confirm')}`,
        () => {
          self.$f7.views.main.router.navigate(this.pathBack, { reloadCurrent: true });
        },
      );
    },
    ...mapActions('scouting', ['setSelectedPhenologicalState', 'setSelectedPlague', 'setSelectedPlagueState', 'setSelectedWeatherCondition', 'setFormMonitoringPoint', 'setPictures']),
  },
};

</script>

<style lang="scss">
.main-formAction{
  .content-list{
    &__title{
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 3px;
      padding-left: 15px;
      color: var(--fr-nutella);
    }
    ul{
      &::before, &::after{
        height: 0!important;
      }
    }
    .smart-border {
      padding-bottom: 0px;
      .item-link {
        .item-content {
          padding: 0;
          .item-inner {
            background-color: #ffffff;
            min-height: 56px;
            border-radius: 8px;
            border: 1px solid #d8d8d8 !important;
            color: var(--f7-badge-bg-color);
            font-weight: 500;
            line-height: 21px;
            padding: 5px 15px;
            width: 100%;
            .item-after {
              margin-left: 0;
              padding: 0;
              font-weight: 500;
              font-size: 16px;
              line-height: 1.2;
              max-width: 90%;
              color: var(--ha-darkgray);
            }
          }
          .item-title {
            display: none;
          }
        }
      }
    }
  }
  .stepper{
    display: flex;
    justify-content: center;
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}
.ic-piccam{
  content: url("../../static/img/ic_piccam.svg");
  margin-right: 5px;
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.main-formAction{
 .item-input-wrap textarea {
    background-color: #FFFFFF;
    min-height: 60px;
    border-radius: 8px;
    border: 1px solid #D8D8D8 !important;
    color: var(--fr-kinder);
    font-weight: 500;
    line-height: 21px;
    padding: 6px 12px;
    width: 100%;
    margin-bottom: 15px;
  }
  .item-content {
    padding: 0;
  }
  .item-inner {
    padding: 0;
  }
  .item-input:not(.item-input-outline) .item-input-wrap:after,
  .input:not(.input-outline):after {
    opacity: 0;
  }
}
</style>
