<template>
  <div class="content-form">
    <f7-block>
      <f7-list no-hairlines-md>
        <f7-row>
          <f7-col width="50">
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_fitosanitary_dosisUsed') }} {{ dosisUt }}
              </div>
              <f7-list-input
                outline
                readonly
                input-style="background-color: #eee;"
                type="text"
                :value="formuleDose"
                @input="changeInput($event.target.value)"
              />
            </div>
          </f7-col><f7-col width="50">
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_fitosanitary_doseCollected') }} {{ dosisUt }}
              </div>
              <f7-list-input
                outline
                readonly
                input-style="background-color: #eee;"
                type="text"
                :value="doseCollected"
                @input="changeInput3($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="50">
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_fitosanitary_quantityPlanned') }} {{ dosisUt }}
              </div>
              <f7-list-input
                outline
                readonly
                input-style="background-color: #eee;"
                type="text"
                :value="dosePlanned"
              />
            </div>
          </f7-col>
          <f7-col width="50">
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_fitosanitary_doseReturn') }}
              </div>
              <f7-list-input
                outline
                readonly
                input-style="background-color: #eee;"
                type="text"
                :value="(doseCollected - formuleDose).toFixed(2)"
              />
            </div>
          </f7-col>
        </f7-row>
        <f7-row
          v-if="totalRealPrescription === -1"
        >
          <f7-col width="100">
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_fitosanitary_totalrealprescripcion') }}
              </div>
              <f7-list-input
                outline
                type="text"
                :value="totalreal"
                @input="changeInput7($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
      </f7-list>
    </f7-block>
    <f7-block class="content-button">
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="botonContinue"
      >
        {{ $t('NfcManually_navbarpresence_btnContinue') }}
      </f7-button>
    </f7-block>
    <div>
      <f7-row>
        <f7-col
          width="50"
          style="padding: 27px 0px 0px 15px;"
        >
          <f7-button
            v-if="false"
            class="boton-skip-all"
            col
            button
            button-large
            button-raised
            fill
            @click="botonFinish"
          >
            {{ $t('NfcManually_navbarpresence_skipall') }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TasksSync from '../../js/models/TasksSync';

export default {
  data() {
    return {
      doseUsed: 0,
      dosePlanned: 0,
      doseCollected: 0,
      doseReturned: 0,
      dose: 0,
      liters: 0,
      dosisUt: '',
      solutionUt: '',
      totalreal: 0,
      btnDisable: false,
      dosisUnit: '',
      booleanFinish: false,
      booleanChanged: false,
      formuleDose: 0,
    };
  },
  computed: {
    ...mapState('TaskManager', [
      'productivity2',
      'id',
      'attachment_id',
      'attachment',
      'planner',
      'machinery_performance',
      'items_drivers',
      'last_employee',
      'observation',
      'task_unit',
      'checkout_multiple',
      'last_employee',
      'products',
    ]),
    ...mapState('worker', ['type', 'code', 'selectedWorker']),
    ...mapState('TaskManager', ['totalRealPrescription']),
    getFormuleDose() {
      if (this.totalRealPrescription !== -1) {
        return (((this.dose * this.totalRealPrescription) / 100) / 1000).toFixed(2);
      }
      return (((this.dose * this.totalreal) / 100) / 1000).toFixed(2);
    },
    getTotalSolution() {
      return (this.products[0].solution * this.planner.goal_performance.working_area);
    },
  },
  async beforeMount() {
    this.$f7.preloader.show();
    for (let i = 0; i < this.planner.products.length; i += 1) {
      if (this.planner.products[i].productName === this.attachment_id) {
        this.dosisUt = this.calculateUnitDosisTotal(this.products[i]);
        this.dosisUnit = this.products[i].dosisUt.toLowerCase();
        this.dosePlanned = this.calculateDosisTotal(this.products[i]);
        this.dose = this.planner.products[i].dosis;
        if (this.dosisUnit === 'cc/hl' || this.dosisUnit === 'gr/hl') {
          this.doseUsed = ((this.dosePlanned * this.totalreal) / 100) / 1000;
          this.doseCollected = this.dosePlanned;
        }
        // else this.btnDisable = false;
        this.solutionUt = this.planner.products[i].solutionUt;
        this.totalreal = this.attachment[i].total_real_prescription;
      }
    }
    this.formuleDose = this.getFormuleDose;
    if (this.attachment.length > 1) {
      this.booleanFinish = true;
    }
    this.$f7.preloader.hide();
  },
  methods: {
    async botonContinue() {
      let ultimo = false;
      this.$f7.preloader.show();
      for (let i = 0; i < this.attachment.length; i += 1) { // aqui vendran los fitos/attachment y tendremos que recorrerlos
        if (this.attachment[i].item_id === this.attachment_id && i < this.attachment.length - 1) {
          if (this.booleanChanged) this.attachment[i].updated = 'true';
          else this.attachment[i].updated = 'false';
          this.attachment[i].qty_used = parseFloat(this.doseUsed).toFixed(2);
          this.attachment[i].qty_planned = this.dosePlanned;
          this.attachment[i].qty_collected = parseFloat(this.doseCollected).toFixed(2);
          this.attachment[i].qty_task = this.doseReturned;
          if (this.totalRealPrescription === -1) {
            this.setTotalRealPrescription(parseFloat(this.totalreal).toFixed(2));
          }
          this.attachment[i].total_real_prescription = this.totalRealPrescription;
          this.setAttachmentId(this.attachment[i + 1].item_id);
          this.setAttachment(this.attachment);
          this.$f7.views.main.router.navigate('/tasks/RegistrationFitosanitaryData/', { reloadCurrent: true });
          this.$f7.preloader.hide();
          break;
        } else if (this.attachment[i].item_id === this.attachment_id && i === this.attachment.length - 1) {
          if (this.booleanChanged) this.attachment[i].updated = 'true';
          else this.attachment[i].updated = 'false';
          ultimo = true;
          this.attachment[i].qty_used = parseFloat(this.doseUsed).toFixed(2);
          this.attachment[i].qty_planned = this.dosePlanned;
          this.attachment[i].qty_collected = parseFloat(this.doseCollected).toFixed(2);
          this.attachment[i].qty_task = this.doseReturned;
          if (this.totalRealPrescription === -1) {
            this.setTotalRealPrescription(parseFloat(this.totalreal).toFixed(2));
          }
          this.attachment[i].total_real_prescription = this.totalRealPrescription;
          this.setAttachment(this.attachment);
        }
      }
      if (ultimo === true) {
        this.setTotalRealPrescription(-1);
        const aux = await TasksSync.updateFitosanitary(this.id, this.attachment); // aqui se llamara para actualizar la task con los datos del attachment
        if (this.checkout_multiple) {
          try {
            const response = await TasksSync.checkOutGroup(
              this.id,
              this.task_unit,
              this.productivity2,
              this.observation,
              this.planner.goal_performance.unit,
            );
            this.setEvent({ type: 'OUT', status: 'All workers are out' });
            this.setWorkers(response.workers);
          } catch (error) {
            console.log(error); // TODO: Mostrar info del error
            this.$f7.dialog.alert(error);
          } finally {
            this.$f7.preloader.hide();
          }
        } else {
          try {
            const response = await TasksSync.checkOut(
              this.id,
              this.last_employee,
              this.task_unit,
              this.productivity2,
              this.observation,
              this.planner.goal_performance.unit,
            );
            if (response.error !== undefined) {
              const event = { type: 'ERROR', status: response.text };
              this.setEvent(event);
            } else {
              const name = `${this.selectedWorker.name} ${this.selectedWorker.surname}`;
              this.setEvent({ type: 'OUT', status: name });
              this.setWorkers(response.workers);
            }
          } catch (error) {
            console.log(error); // TODO: Mostrar info del error
          } finally {
            this.$f7.preloader.hide();
          }
        }
        this.$f7.views.main.router.navigate('/tasks/ExportTask/', { reloadCurrent: false });
        this.$f7.preloader.hide();
      }
    },
    changeInput(inputValue) {
      if (inputValue === '') {
        this.doseUsed = 0;
      } else {
        this.doseUsed = parseFloat(inputValue);
        this.doseReturned = (this.doseCollected - this.doseUsed).toFixed(2);
        this.booleanChanged = true;
      }
    },
    changeInput2(inputValue) {
      this.dosePlanned = inputValue;
    },
    changeInput3(inputValue) {
      if (inputValue === '') this.doseCollected = 0;
      else this.doseCollected = parseFloat(inputValue);
      this.booleanChanged = true;
    },
    changeInput4(inputValue) {
      this.doseReturned = inputValue;
    },
    changeInput5(inputValue) {
      this.liters = inputValue;
    },
    changeInput6(inputValue) {
      this.comments = inputValue;
    },
    changeInput7(inputValue) {
      if (inputValue === '' || inputValue < 0) this.totalreal = 0;
      else if (inputValue > this.getTotalSolution) this.totalreal = this.getTotalSolution;
      else this.totalreal = parseFloat(inputValue);
      this.formuleDose = this.getFormuleDose;
      this.doseUsed = parseFloat(this.formuleDose);
      this.doseReturned = (this.doseCollected - this.doseUsed).toFixed(2);
      this.booleanChanged = true;
    },
    calculateDosisTotal(product) {
      let calcDosisTotal = (parseFloat(product.dosis) * parseFloat(this.planner.goal_performance.working_area)).toFixed(2);
      const dilutionFactor = parseFloat(product.solution) / 100;

      calcDosisTotal = (!this.isKilogrameOrliter(product)) ? ((calcDosisTotal * dilutionFactor) / 1000).toFixed(2) : calcDosisTotal;
      return calcDosisTotal;
    },
    calculateUnitDosisTotal(product) {
      if (product.dosisUt.toLowerCase().includes('gr/')
        || product.dosisUt.toLowerCase().includes('kg/')) return 'Kg';
      return 'L';
    },
    isKilogrameOrliter(product) {
      return typeof (product.dosisUt) === 'undefined' || product.dosisUt.toLowerCase().includes('kg/') || product.dosisUt.toLowerCase().includes('l/');
    },
    botonFinish() {
      this.$f7.views.main.router.navigate('/tasks/ExportTask/', { reloadCurrent: false });
    },
    ...mapActions('TaskManager', ['setAttachmentId', 'setMachineryPerformance', 'setAttachment', 'setWorkers', 'setTotalRealPrescription']),
    ...mapActions('worker', ['changeUpSheet', 'setEvent', 'changeUpSheet']),
  },
};
</script>

<style lang="scss">
.boton-skip-all {
  background: #E5EEF7 !important;
  color: #616B7C !important;
  box-shadow: none;
}
.stepper .stepper-input-wrap input {
  width: 100% !important;
  background: white;
  padding: 0px !important;
}
.list li{
  list-style: none;
}

.content-form{
  padding-top: 15px;
  .item-content{
    padding: 0;
    .item-inner{
      padding: 5px 0;
      .input-with-value{
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  .content-button{
    bottom: 0;
    width: 100%;
    padding-top: 20px;
  }
}
.item-title.item-label {
  margin-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--f7-list-item-title-text-color);
  padding-bottom: 2%;
  padding-top: 2%;
}
</style>
