<template>
  <f7-page
    class="main-checkout"
    @page:beforein="beforein"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${activity} - ${$t('Nfc_navbarTask_checkout')}`"
      :path-back="`/${pathBack}/`"
    />
    <div>
      <div class="content-task-header">
        <div class="item-inner">
          <div class="item-title-row">
            <div
              class="item-title text-color-black no-margin no-padding center"
            >
              {{ task_name }}
            </div>
            <div class="item-subtitle text-color-chocolat">
              {{ getLocations(addLocation) }} ⇢
              <strong class="text-color-nutella"> {{ activity }} </strong>
            </div>
            <div class="item-after" />
          </div>
          <div class="item-subtitle">
            <div class="task-meta">
              <img
                width="20"
                height="20"
                :src="images.ic_timetask"
              >
              <span>{{ formattedHour(workers) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <f7-row>
      <f7-col v-if="selectedWorker.name !== ''">
        <div class="checkout-productivity-help">
          <img
            class="margin-right"
            width="48"
            height="48"
            :src="photoSrc()"
          >
          <span
            class="item-title text-color-chocolat"
          >{{ selectedWorker.name }} {{ selectedWorker.surname }}</span>
        </div>
      </f7-col>
      <f7-col v-else>
        <div class="checkout-productivity-help">
          <img
            class="margin-right"
            width="48"
            height="48"
            :src="images.ic_individual"
          >
          <span>{{ $t("checkOut_assigned") }}
            <b>{{ $t("checkOut_assigned_single") }}</b></span>
        </div>
      </f7-col>
    </f7-row>
    <f7-block class="checkout-productivity">
      <f7-list no-hairlines-md>
        <f7-row class="content-usario">
          <f7-col
            width="50"
          >
            <div
              v-if="planner.goal_performance.unit !== ''"
              class="content-stepper text-align-center"
            >
              <div class="content-stepper__title">
                {{ $t("checkOut_Productivity") }} {{ planner.goal_performance.unit.toUpperCase() }}
              </div>
              <f7-list-input
                type="number"
                :value="productivityValue"
                @input="setProductivityValue($event.target.value)"
              />
            </div>
          </f7-col>
          <f7-col width="50">
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t("checkOut_workingArea") }} HA
              </div>
              <f7-list-input
                type="number"
                :value="productivityValue2"
                @input="setProductivityValue2($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
        <f7-row v-if="(typeof planner.goal_performance.working_area !== 'undefined')">
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("StartOrder_productivity_working_area_goal") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important"
            >
              <f7-list-item
                :title="`${$t('StartOrder_productivity_working_area_goal')}`"
                smart-select
                :smart-select-params="{ openIn: 'popover' }"
              >
                <select
                  id="difficulty"
                  name="difficulty"
                  class="input-field"
                  @change="selectDifficulty($event)"
                >
                  <option
                    :value="planner.goal_performance.working_area"
                    selected
                  >
                    {{ planner.goal_performance.working_area }} HA
                  </option>
                </select>
              </f7-list-item>
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row v-if="planner.goal_performance.unit !== ''">
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("StartOrder_productivity_goal") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important"
            >
              <f7-list-item
                :title="`${$t('StartOrder_productivity_goal')}`"
                smart-select
                :smart-select-params="{ openIn: 'popover' }"
              >
                <select
                  id="difficulty"
                  name="difficulty"
                  class="input-field"
                  @change="selectDifficulty($event)"
                >
                  <option
                    :key="planner.goal_performance.unit"
                    :value="planner.goal_performance.unit"
                    selected
                  >
                    {{ planner.goal_performance.count }} {{ planner.goal_performance.unit }}
                  </option>
                </select>
              </f7-list-item>
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("checkOut_checkInLabel") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important"
            >
              <f7-list-input
                id="checkInDateLabel"
                type="text"
                :disabled="true"
                :value="oldCheckInDate"
              />
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("checkOut_newCheckIn") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important"
            >
              <f7-list-input
                id="checkInDate"
                type="datepicker"
                :placeholder="`${$t('checkOut_select_date')}`"
                :calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'yyyy-mm-dd HH::mm', timePicker: true, timePickerPlaceholder: $t('checkOut_select_time')}"
                style="padding:0px"
                @calendar:change="onChangeCheckInDate($event)"
              />
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("checkOut_checkOutLabel") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important"
            >
              <f7-list-input
                id="checkOutDateLabel"
                type="text"
                :disabled="true"
                :value="oldCheckOutDate"
              />
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("checkOut_newCheckOut") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important"
            >
              <f7-list-input
                id="checkOutDate"
                type="datepicker"
                :placeholder="`${$t('checkOut_select_date')}`"
                :calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'yyyy-mm-dd HH::mm', timePicker: true, timePickerPlaceholder: $t('checkOut_select_time')}"
                style="padding:0px"
                @calendar:change="onChangeCheckOutDate($event)"
              />
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("checkOut_observations") }}
            </div>
            <div class="item-input-wrap">
              <textarea
                class="resizable"
                maxlength="50"
                @input="addComment($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
      </f7-list>
    </f7-block>
    <!-- </div> -->
    <f7-block>
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green"
        col
        button
        button-large
        button-raised
        fill
        @click="setFullNameFilter"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button>

      <BottomPanelCheckInOut />
    </f7-block>
  </f7-page>
</template>

<script>
// import ImageCheckOut from "../../static/img/imgCheckOut.png";
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import ImageTime from '../../static/img/ic_time_task.svg';
import img from '../../static/img/ic_user_enter.svg';
import ImgIndividual from '../../static/img/ic_individual.svg';
import ImgGroup from '../../static/img/ic_group.svg';
import TasksSync from '../../js/models/TasksSync';
import BottomPanelCheckInOut from '../../components/BottomPanelCheckInOut.vue';
import Locations from '../../js/models/Locations';

export default {
  components: {
    navbar,
    BottomPanelCheckInOut,
  },
  props: {
    pathBack: { type: String, default: '/nfcCheckOut/' },
  },
  data() {
    return {
      value: '',
      productivityValue: 0,
      productivityValue2: 0,
      fullNameFilter: 0,
      units: [
        { name: 'man/day' },
        { name: 'ha' },
        { name: 'm' },
        { name: 'plants' },
        { name: 'hours' },
        { name: 'm2' },
      ],
      images: {
        ic_timetask: ImageTime,
        ic_individual: ImgIndividual,
        ic_multiple: ImgGroup,
      },
      noteValue: '',
      addLocation: [],
      oldCheckInDate: '',
      oldCheckOutDate: '',

    };
  },
  computed: {
    ...mapState('TaskManager', [
      'id',
      'workers',
      'task_name',
      'activity',
      'farm',
      'newTask',
      'locationId',
      'planner',
      'items',
      'attachment',
      'locationId',
      'checkInDate',
      'checkOutDate',
    ]),
    ...mapState('worker', ['type', 'code', 'selectedWorker']),
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  methods: {
    async beforein() {
      this.setCheckInDate(this.selectedWorker.init_date);
      this.setCheckOutDate(moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'));
      this.oldCheckInDate = moment.utc(this.selectedWorker.init_date).format('YYYY-MM-DD HH:mm');
      this.oldCheckOutDate = moment().format('YYYY-MM-DD HH:mm');
      this.locationsTree = await Locations.getAll();
      for (let i = 0; i < this.locationId.length; i += 1) {
        this.checkRestChild(this.locationId[i], this.locationsTree);
      }
    },
    checkRestChild(item, locationsTree) {
      for (let i = 0; i < locationsTree.length; i += 1) {
        if (locationsTree[i].children.length > 0) {
          for (let j = 0; j < locationsTree[i].children.length; j += 1) {
            if (locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < locationsTree[i].children[j].children.length; k += 1) {
                if (locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < locationsTree[i].children[j].children[k].children.length; l += 1) {
                    if (locationsTree[i].children[j].children[k].children[l].children.length > 0) {
                      for (let m = 0; m < locationsTree[i].children[j].children[k].children[l].children.length; m += 1) {
                        if (locationsTree[i].children[j].children[k].children[l].children[m].bd_id === item) {
                          this.addLocation.push(locationsTree[i].children[j].children[k].name);
                          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                            if (this.addLocation[n] === this.addLocation[n + 1]) {
                              this.addLocation.splice(n, 1);
                            }
                          }
                        }
                      }
                    } else if (locationsTree[i].children[j].children[k].children[l].bd_id === item) {
                      this.addLocation.push(locationsTree[i].children[j].children[k].name);
                      for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                        if (this.addLocation[n] === this.addLocation[n + 1]) {
                          this.addLocation.splice(n + 1, 1);
                        }
                      }
                    }
                  }
                } else if (locationsTree[i].children[j].children[k].bd_id === item) {
                  this.addLocation.push(locationsTree[i].children[j].children[k].name);
                  for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                    if (this.addLocation[n] === this.addLocation[n + 1]) {
                      this.addLocation.splice(n + 1, 1);
                    }
                  }
                }
              }
            } else if (locationsTree[i].children[j].bd_id === item) {
              this.addLocation.push(locationsTree[i].children[j].name);
              for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                if (this.addLocation[n] === this.addLocation[n + 1]) {
                  this.addLocation.splice(n + 1, 1);
                }
              }
            }
          }
        } else if (locationsTree[i].bd_id === item) {
          this.addLocation.push(locationsTree[i].name);
          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
            if (this.addLocation[n] === this.addLocation[n + 1]) {
              this.addLocation.splice(n, 1);
            }
          }
        }
      }
      this.addLocation = this.addLocation.filter(this.onlyUnique);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    addComment(value) {
      this.noteValue = value;
    },
    getLocations(item) {
      let aux = '';
      if (item.length > 0) {
        for (let i = 0; i < item.length; i += 1) {
          if (i === 0) aux += `${item[i]}`;
          else aux += `, ${item[i]}`;
        }
      }
      return aux;
    },
    setProductivityValue(value) {
      if (value === '') this.productivityValue = 0.00;
      else this.productivityValue = parseFloat(value);
    },
    setProductivityValue2(value) {
      if (value === '') this.productivityValue2 = 0.00;
      else this.productivityValue2 = parseFloat(value);
    },
    async setFullNameFilter() {
      if (this.noteValue.length <= 50) {
        // La nota tiene máximo 50 caracteres
        this.$f7.preloader.show();
        this.setTaskUnit(parseFloat(this.productivityValue).toFixed(2));
        this.setProductivity2(parseFloat(this.productivityValue2).toFixed(2));
        this.fullNameFilter = parseFloat(this.productivityValue).toFixed(2);
        const { type } = this;
        const { code } = this;
        const employed = { auth: { type, code } };
        this.setLastEmployee(employed);
        if (this.getWorkersIn(this.workers) === 1 && this.items.length > 0) {
          this.setCheckoutMultiple(false);
          this.setObservation(this.noteValue);
          this.setMachineryId(this.items[0].item);
          if (this.attachment.length > 0) {
            this.setAttachmentId(this.attachment[0].item_id);
          }
          this.setMachineryPerformance([]);
          this.$f7.views.main.router.navigate('/tasks/RegistrationMachineryData/', { reloadCurrent: false });
        } else if (this.getWorkersIn(this.workers) === 1 && this.attachment.length > 0) {
          this.setCheckoutMultiple(false);
          this.setObservation(this.noteValue);
          this.setAttachmentId(this.attachment[0].item_id);
          this.setTotalRealPrescription(-1);
          this.$f7.views.main.router.navigate('/tasks/RegistrationFitosanitaryData/', { reloadCurrent: false });
        } else {
          let checkInToBack = this.checkInDate;
          let checkOutToBack = this.checkOutDate;
          if (checkInToBack === '') {
            checkInToBack = this.oldCheckInDate;
          }
          if (checkOutToBack === '') {
            checkOutToBack = this.oldCheckOutDate;
          }
          if (checkOutToBack < checkInToBack) {
            this.$f7.dialog.alert(
              `${this.$t('CheckOutMulti_checkout_alert')}`,
            );
            this.$f7.preloader.hide();
          } else {
            try {
              const response = await TasksSync.checkOut(
                this.id,
                employed,
                this.fullNameFilter,
                parseFloat(this.productivityValue2).toFixed(2),
                this.noteValue,
                this.planner.goal_performance.unit,
                checkInToBack,
                checkOutToBack,
              );
              if (response.error !== undefined) {
                const event = { type: 'ERROR', status: response.text };
                this.setEvent(event);
                this.$f7.sheet.open('.demo-sheet-swipe-to-step');
              } else {
                const name = `${this.selectedWorker.name} ${this.selectedWorker.surname}`;
                this.setEvent({ type: 'OUT', status: name });
                this.setWorkers(response.workers);
                this.$f7.sheet.open('.demo-sheet-swipe-to-step');
                this.$f7.views.main.router.navigate('/nfcCheckOut/', {
                  reloadCurrent: false,
                // false para que el popup se pueda cerrar al pulsar en cualquier sitio
                });
                this.syncTasksSync().catch((error) => {
                  this.$f7.dialog.alert(error);
                }); // Sincronizamos
              }
            } catch (error) {
              console.log(error); // TODO: Mostrar info del error
            } finally {
              this.$f7.preloader.hide();
            }
          }
        }
      } else {
        this.$f7.dialog.alert(
          `${this.$t('CheckOutMulti_note_alert')} ${this.noteValue.length}`,
        );
      }
    },
    getWorkersIn(employee) {
      let aux = 0;
      for (let i = 0; i < employee.length; i += 1) {
        if (employee[i].end_date === null) {
          aux += 1;
        }
      }
      return aux;
    },
    formattedHour(item) {
      for (let i = 0; i < item.length; i += 1) {
        if (item[i].id === this.code) {
          const momentObj = moment();
          let end = item[i].end_date
            ? item[i].end_date
            : momentObj.format('YYYY-MM-DD HH:mm:ss');
          end = moment.utc(end);
          const ms = moment(end).diff(moment(item[i].init_date));
          const d = moment.utc(moment.duration(ms).asMilliseconds());
          return moment.utc(d).format('HH:mm');
        }
      }
      return 0;
    },
    photoSrc() {
      return img;
    },
    onChangeCheckInDate(date) {
      this.setCheckInDate((moment(date[0]).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')));
    },
    onChangeCheckOutDate(date) {
      this.setCheckOutDate((moment(date[0]).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')));
    },
    ...mapActions('databaseSync', ['syncTasksSync']),
    ...mapActions('TaskManager', ['setProductivity2',
      'setTaskUnit',
      'setWorkers',
      'setMachineryId',
      'setDriverId',
      'setProductivity',
      'setObservation',
      'setCheckoutMultiple',
      'setLastEmployee',
      'setMachineryPerformance',
      'setAttachmentId',
      'setCheckInDate',
      'setCheckOutDate',
      'setTotalRealPrescription',
    ]),
    ...mapActions('worker', ['changeUpSheet', 'setEvent', 'changeUpSheet']),
  },
};
</script>
<style lang="scss">
.main-checkout{
  .unit {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
  }
  .checkout-productivity-help {
    --help-green: #f0f8eb;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 15px 15px 0;
    background: var(--help-green);
    border-radius: 6px;
    line-height: 1.4;
    padding-right: 40px;
    color: #556080;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.13));
  }
  .checkout-productivity-help.multiple {
    background: #edf5fe;
  }

  .checkout-productivity-help::after {
    content: "";
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid var(--help-green);
    right: 65px;
    bottom: -11px;
  }

  .checkout-productivity div.col:nth-child(1) {
    width: 60%;
    align-self: center;
  }
  .item-input-wrap:after{
      height: 0!important;
    }
  .checkout-productivity div.col:nth-child(2) {
    width: 40%;
    align-self: center;
  }
.item-content.item-input.item-input-with-value input[type="number"]{
    min-height: 58px;
    border-radius: 8px;
    border-color: var(--ha-silver) !important;
    color: var(--fr-kinder)!important;
    background-color: white;
    font-weight: 500;
    font-size: 18px;
  }
  .checkout-productivity .user-id {
    display: flex;
    align-items: center;
  }

  .checkout-productivity .user-id img {
    margin-right: 8px;
  }

  .checkout-productivity {
    .content-usario {
      align-items: flex-end;
      .item-title {
        color: #556080 !important;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  .ios .stepper .stepper-input-wrap input {
    width: 64px;
  }

  .content-task-header {
    display: flex;
    flex-direction: row;
    background: var(--f7-sheet-bg-color);
    padding: 10px 15px;
    border-bottom: 1px solid var(--f7-input-outline-border-color);
  }

  .content-task-header .item-inner:nth-of-type(1) {
    min-width: 75%;
    align-self: center;
  }
  .content-task-header .item-inner:nth-of-type(2) {
    min-width: 30%;
    align-self: center;
    text-align: center;
  }

  .content-task-header .item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #2e2e2e;
    text-transform: capitalize;
    white-space: initial;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .content-task-header .item-subtitle {
    max-width: 75%;
    text-transform: capitalize;
    font-size: 12px;
    line-height: 1.4;
    margin-top: 3px;
  }

  .content-task-header .task-meta {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-top: 8px;
    color: var(--fr-chocolat);
  }

  .content-task-header .task-meta span {
    display: inline-block;
    margin: 0px 20px 0px 6px;
  }

  .content-task-header .task-meta img {
    float: left;
  }

  .content-task-header .button {
    box-shadow: none;
    border: none;
    background-color: transparent;
    border-radius: 0;
    height: 68px;
  }

  div.input.input-with-value {
    padding-top: 4%;
    padding-left: 8%;
  }
  div.item-label.smart-title {
    margin-left: 5%;
    margin-right: 5%;
    font-weight: normal;
  }
  div.input.input-with-value {
    height: 35px;
    margin-left: 5%;
    margin-right: 5%;
    background: #f7f8fa;
    border: 1px solid #d4dce1;
    font-size: 18px;
    border-radius: 8px;
  }
  div.block-title {
    line-height: 34px;
    text-align: center;
    font-size: 30px;
    color: darkslateblue;
  }
  .color {
    background: #f7f8fa;
    border: 1px solid #d4dce1;
    border-radius: 8px;
    height: 59px;
    width: 50%;
    left: 16px;
  }
  .list.item-input-outline.item-floating-label {
    background: #f7f8fa !important;
    width: 70% !important;
  }

  .header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .avatar {
    margin-top: 15px;
  }
}
</style>
