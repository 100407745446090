import Framework7 from 'framework7';
import EventBus from '../js/event-bus';

export default {
  request(url, payload, verb, processData = true, contentType = 'application/x-www-form-urlencoded', headerAuth,
    callback, timeout = 60000) {
    Framework7.request.setup({
      headers: {
        Authorization: headerAuth,
        'Ha-Appcode': 'WEB_ISFA',
      },
    });
    Framework7.request({
      url,
      method: verb,
      cache: false,
      data: payload.data,
      processData,
      contentType,
      timeout,
      complete: callback,
    });
  },

  async(url, payload, verb, processData, contentType, headerAuth = null) {
    const self = this;
    return new Promise(((resolve, reject) => {
      self.request(url, payload, verb, processData, contentType, headerAuth,
        (xhr, status) => {
          if (status >= 300 || status === 'timeout') { // TODO: Tratar adecuademente las respuestas (1xx,2xx,3xx,4xx,5xx)
            try {
              if (typeof xhr.response !== 'undefined' && xhr.response !== '') {
                if (typeof JSON.parse(xhr.response).error_code !== 'undefined') {
                  EventBus.$emit('SetNew_SyncErrorText', xhr.response);
                } else {
                  EventBus.$emit('SetNew_SyncErrorText', 'Generic_Error');
                }
              }
              if (status === 401) {
                EventBus.$emit('invalidTokenDetected');
              } else if (status === 404) {
                resolve(xhr);
              }
              reject(status);
              return;
            } catch (error) {
              EventBus.$emit('SetNew_SyncErrorText', 'Generic_Error');
              reject(status);
              return;
            }
          }
          try {
            resolve(xhr);
          } catch (e) {
            reject(e);
          }
        });
    }));
  },

  async blobRequest(url, callback) {
    const logoRes = await fetch(url);
    const blob = await logoRes.blob();
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(blob);
  },
};
