<template>
  <div>
    <f7-treeview-item
      v-for="(item, index) in nodes"
      :key="index"
    >
      <template #content-start>
        <!-- LISTA ARBOL VIVERO -->
        <f7-list v-if="singleSelectionEnabled">
          <f7-list-item
            checkbox
            :checked="selectedLocations.indexOf(item.bd_id) >= 0"
            :title="item.name"
            :indeterminate="parentLocationsSelected.indexOf(item.bd_id) >= 0"
            :disabled="item.children.length > 0 && item.level !== lastLevelToDisplay"
            @change="changeLocationSelected(item)"
          />
        </f7-list>
        <!-- LISTA ARBOL NO VIVERO -->
        <f7-list v-else>
          <f7-list-item
            checkbox
            :checked="selectedLocations.indexOf(item.bd_id) >= 0"
            :title="item.name"
            :indeterminate="parentLocationsSelected.indexOf(item.bd_id) >= 0"
            @change="changeLocationSelected(item)"
          />
        </f7-list>
      </template>
      <!-- Pintamos siguiente nodo si tiene hijos Y
           (no hay ultimo nivel que pintar O
           nivel actual es menor estricto que el último nivel a pintar -->
      <RecursiveTreeView
        v-if="item.children.length > 0
          && (lastLevelToDisplay===undefined || item.level < lastLevelToDisplay)"
        :nodes="item.children"
        :single-selection-enabled="singleSelectionEnabled"
        :last-level-to-display="lastLevelToDisplay"
      />
    </f7-treeview-item>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RecursiveTreeView',
  components: {
  },
  props: {
    nodes: { type: Array, default: () => [] },
    previousSelectedLocations: { type: Array, default: () => [] },
    singleSelectionEnabled: { type: Boolean, default: false },
    lastLevelToDisplay: { type: Number, default: undefined },
  },
  data() {
    return {
      newSelectedLocations: [],
      intermediateLocations: [],
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['selectedLocations', 'formattedTree', 'parentLocationsSelected']),

  },
  beforeMount() {
    try {
      if (this.previousSelectedLocations.length > 0) {
        this.previousSelectedLocations.forEach((element) => {
          const nodoPrincipal = this.formattedTree.filter((item) => item.bd_id === element)[0];
          if (this.newSelectedLocations.indexOf(nodoPrincipal.bd_id) === -1) {
            this.newSelectedLocations.push(nodoPrincipal.bd_id);
            // Añadir al array de seleccionados los hijos del nodo seleccionado (si los hubiera)
            if (nodoPrincipal.children.length > 0) {
              this.recursiveAddChildren(nodoPrincipal.children);
            }
            // Añadir al array de semiseleccionados los padres del nodo seleccionado (si los hubiera)
            if (nodoPrincipal.parent !== undefined) {
              this.intermediateLocations.push(nodoPrincipal.parent);
              this.recursiveAddParent(nodoPrincipal.parent);
            }
          }
        });
        this.setSelectedLocations(this.newSelectedLocations);
        this.setParentLocationsSelected(this.intermediateLocations);
      }
    } catch (error) {
      console.warn(error);
    }
  },

  methods: {
    changeLocationSelected(item) {
      const index = this.selectedLocations.indexOf(item.bd_id);
      const indexParents = this.parentLocationsSelected.indexOf(item.bd_id);
      if (this.singleSelectionEnabled) { // Modo Vivero
        if (index >= 0 || indexParents >= 0) {
          this.newSelectedLocations = [];
          this.intermediateLocations = [];
        } else {
          this.addSelectedElementVivero(item);
        }
      } else if (index >= 0) { // Modo general
        this.removeSelectedElement(item, index);
      } else {
        this.addSelectedElement(item);
      }
      this.setSelectedLocations(this.newSelectedLocations);
      this.setParentLocationsSelected(this.intermediateLocations);
    },
    // MÉTODOS ÁRBOL DE SELECCIÓN INDIVIDUAL--------------------------------------------------
    addSelectedElementVivero(item) {
      this.newSelectedLocations = [];
      this.intermediateLocations = [];
      if (item.children.length > 0
        && (this.lastLevelToDisplay === undefined
            || item.level < this.lastLevelToDisplay)
      ) {
        // Si tiene hijos ponemos el nodo actual como intermedio y buscamos el primer hijo
        this.intermediateLocations.push(item.bd_id);
        const firstChild = item.children[0];
        this.recursiveAddChildrenVivero(firstChild);
      } else {
        this.newSelectedLocations.push(item.bd_id);
      }
      // Si el nodo actual tiene padre, este se añade al array de padres
      if (item.parent !== undefined) {
        this.intermediateLocations.push(item.parent);
        this.recursiveAddParent(item.parent);
      }
    },
    recursiveAddChildrenVivero(node) {
      if (node.children.length > 0
          && (this.lastLevelToDisplay === undefined
            || node.level < this.lastLevelToDisplay)
      ) {
        // Si tiene hijos ponemos el nodo actual como intermedio y buscamos el primer hijo
        this.intermediateLocations.push(node.bd_id);
        const firstChild = node.children[0];
        this.recursiveAddChildrenVivero(firstChild);
      } else {
        this.newSelectedLocations.push(node.bd_id);
      }
    },

    // MÉTODOS ÁRBOL DE SELECCIÓN MÚLTIPLE--------------------------------------------------
    removeSelectedElement(item, index) {
      this.newSelectedLocations = this.selectedLocations;
      this.intermediateLocations = this.parentLocationsSelected;
      // Eliminar nodo actual e hijos
      this.newSelectedLocations.splice(index, 1);
      item.children.forEach((element) => {
        this.newSelectedLocations = this.newSelectedLocations.filter(
          (loc) => loc !== element.bd_id,
        );
        if (element.children.length > 0) {
          this.recursiveRemoveChildren(element.children);
        }
      });
      // Si se han eliminado todos los nodos seleccionados, eliminamos los padres que tenían
      if (this.newSelectedLocations.length === 0) {
        this.intermediateLocations.length = 0;
      } else {
        /* En caso contrario se ha eliminado un último hijo de todos los
             seleccionados por un padre, por tanto cambiamos al padre del array
             de seleccionados al de semiseleccionados */
        const parentIndex = this.selectedLocations.indexOf(item.parent);
        if (parentIndex >= 0) {
          this.newSelectedLocations.splice(parentIndex, 1);
        }
        this.intermediateLocations.push(item.parent);
      }
    },
    addSelectedElement(item) {
      this.newSelectedLocations = this.selectedLocations;
      this.intermediateLocations = this.parentLocationsSelected;
      // Añadir nodo actual e hijos
      this.newSelectedLocations.push(item.bd_id);
      item.children.forEach((element) => {
        // Si no estaba seleccionado anteriormente, lo seleccionamos
        let indexSelected = this.newSelectedLocations.indexOf(element.bd_id);
        if (indexSelected === -1) {
          this.newSelectedLocations.push(element.bd_id);
        }
        // si el hijo actual estaba seleccionado como padre, lo quitamos del array de padres
        indexSelected = this.intermediateLocations.indexOf(element.bd_id);
        if (indexSelected >= 0) {
          this.intermediateLocations.splice(indexSelected, 1);
        }
        // Añadimos los hijos
        if (element.children.length > 0) {
          this.recursiveAddChildren(element.children);
        }
      });
      // Si el nodo actual estaba como padre, lo quitamos del array de padres
      const indexParent = this.intermediateLocations.indexOf(item.bd_id);
      if (indexParent >= 0) {
        this.intermediateLocations.splice(indexParent, 1);
      }
      // Añadir al array de semiseleccionados los padres del nodo seleccionado (si los hubiera)
      if (item.parent !== undefined && this.intermediateLocations.indexOf(item.parent) === -1) {
        this.intermediateLocations.push(item.parent);
        this.recursiveAddParent(item.parent);
      }
    },
    recursiveAddChildren(childrenArray) {
      childrenArray.forEach((element) => {
        // Si no estaba seleccionado anteriormente, lo seleccionamos
        let indexSelected = this.newSelectedLocations.indexOf(element.bd_id);
        if (indexSelected === -1) {
          this.newSelectedLocations.push(element.bd_id);
        }
        // si el hijo actual estaba seleccionado como padre, lo quitamos del array de padres
        indexSelected = this.intermediateLocations.indexOf(element.bd_id);
        if (indexSelected >= 0) {
          this.intermediateLocations.splice(indexSelected, 1);
        }
        // Añadimos los hijos
        if (element.children.length > 0) {
          this.recursiveAddChildren(element.children);
        }
      });
    },
    recursiveRemoveChildren(childrenArray) {
      childrenArray.forEach((element) => {
        this.newSelectedLocations = this.newSelectedLocations.filter(
          (item) => item !== element.bd_id,
        );
        if (element.children.length > 0) {
          this.recursiveRemoveChildren(element.children);
        }
      });
    },
    recursiveAddParent(idParent) {
      if (this.intermediateLocations.indexOf(idParent) >= 0) {
        const newParent = this.formattedTree.filter((item) => item.bd_id === idParent)[0];
        if (newParent.parent !== undefined
          && this.intermediateLocations.indexOf(newParent.parent) === -1) {
          this.intermediateLocations.push(newParent.parent);
          this.recursiveAddParent(newParent.parent);
        }
      }
    },
    ...mapActions('greenhouse', ['setSelectedLocations', 'setParentLocationsSelected']),
  },
};
</script>
<style lang="scss" scoped>
</style>
