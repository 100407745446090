<template>
  <f7-panel
    left
    cover
    :visible-breakpoint="960"
  >
    <f7-view>
      <f7-page>
        <div class="content-left-panel-header">
          <f7-block class="content-left-panel-header-logo">
            <img :src="APP_LOGO">
          </f7-block>
          <f7-block class="content-left-panel-header-user">
            <div class="content-left-panel-header-user__img">
              <img>
            </div>
            <div class="content-left-panel-header-user__title">
              <span>{{ user.first_name }}</span>
              <span>{{ user.last_name }}</span>
            </div>
          </f7-block>
        </div>

        <f7-list media-list>
          <ul class="Contenedorf7list">
            <f7-list-group
              v-for="(item, index) in elements"
              :key="index"
              @click="navigateTo(item.url)"
            >
              <f7-list-item
                v-if="item.name!=''"
                :title="$t(item.name)"
                group-title
              />

              <f7-list-item
                v-for="(itemMenu, index2) in item.menus"
                :key="index2"
                class="panel-close close"
                @click="navigateTo(itemMenu.url)"
              >
                <f7-icon
                  v-if="itemMenu.image!=''"
                  slot="media"
                  :class="`icon-menu ${itemMenu.icon}`"
                  width="32"
                  height="32"
                  @click="navigateTo(itemMenu.url)"
                />
                <a>{{ $t(itemMenu.name) }}</a>
                <br>
              </f7-list-item>
            </f7-list-group>
          </ul>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>
</template>

<script>
import {
  mapState, mapActions,
} from 'vuex';
import { APPLICATION_NAME, APP_LOGO } from '../js/constants';
import store from '../store/index';
import ModifiedRecords from '../js/models/ModifiedRecords';

export default {
  name: 'LeftPanel',
  store,
  props: {
    elements: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: false },
  },
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['user']),
  },
  methods: {
    navigateTo(path) {
      if (path === '/accounts/loginPage/') {
        // store.dispatch('authentication/logout');
        this.logoutPressed();
      } else {
        this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
      }
    },
    async logoutPressed() {
      const pendingToUpdate = await ModifiedRecords.countElement();
      const self = this;
      if (pendingToUpdate !== 0) {
        this.$f7.dialog.confirm(
          `${this.$t('loggOut_Warning_Message')}`,
          () => {
            self.$f7.panel.close('left', false);
            self.logout();
          },
        );
      } else {
        this.$f7.dialog.confirm(
          `${this.$t('loggOut_Message')}`,
          () => {
            self.$f7.panel.close('left', false);
            self.logout();
          },
        );
      }
    },
    ...mapActions('authentication', ['logout']),
  },
};
</script>

<style lang="scss">
.content-left-panel-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../static/img/bg_menu-header.png") no-repeat;
  background-size: 100%;
  margin: 0;
  padding-top: var(--f7-block-margin-vertical);
  padding-bottom: var(--f7-block-margin-vertical);
  border-bottom: 1px solid var(--hi-lightsnow);
  .content-left-panel-header-logo{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    img{
      width: 100%;
    }
  }
  .content-left-panel-header-user{
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid #D4DCE1 ;
    background: var(--f7-list-group-title-bg-color);
    padding-top: 10px;
    &__img{
      img{
        content: url("../static/img/ic_useravatar.svg");
        width: 56px;
        height: 56px;
      }
    }
    &__title{
      padding-left: 15px;
      flex-direction: column;
      display: flex;
      span{
        &:first-child{
          color: var(--fr-kinder);
          font-size: 17px;
          font-weight: 500;
        }
        &:nth-child(2){
          color: var(--fr-kinder);
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
}
.toolbar_panel {
  bottom: 0px !important;
  position: absolute;
  padding: 10px;
}
.image_logo {
  width: 90%;
  height: auto;
  margin: 10px 15px;
}

.list ul ul{
  padding-left: 0px;
}

  @-moz-document url-prefix() {
  .content-menu-header {
      width: 100%;
      -moz-box-sizing: border-box;

      }

  }
  .content-menu .list ul {
    background: white;
  }
  .content-menu .list-group-title {
    border-bottom: 1px solid #ececec;
  }
  .content-menu .page-content {
    background: #F7F8FA;
    border-right: 1px solid var(--f7-list-border-color);
  }
  //TODO: Ver porqué se ha alinea a la derecha | Alinear nombre del menú a la izquierda
  .Contenedorf7list .item-inner{
    display: flex!important;
    align-self: center;
    align-items: center;
  }
  .item-media {
    margin-left: 0 !important;
  }

  .Contenedorf7list .icon-menu {
    width: 32px;
    height:32px;
  }

  .icon-home {
    background-image: url('../static/img/icon_inicio.svg');
  }

  .icon-dailysummary {
    background-image: url('../static/img/ic_dailysummary.svg');
  }

  .icon-searchworkers {
    background-image: url('../static/img/ic_searchworkers.svg');
  }

  .icon-consultbins {
    background-image: url('../static/img/ic_consultbins.svg');
  }

  .icon-historyboards {
    background-image: url('../static/img/ic_historyboards.svg');
  }

  .icon-setting {
    background-image: url('../static/img/ic_setting.svg');
  }

  .icon-casual {
    background-image: url('../static/img/ic_casual.svg');
  }

  .icon-fix {
    background-image: url('../static/img/ic_fix.svg');
  }

  .icon-pest {
    background-image: url('../static/img/ic_pest.svg');
  }

  .icon-phenology {
    background-image: url('../static/img/ic_phenology.svg');
  }

  .icon-logout {
    background-image: url('../static/img/ic_singout.svg');
  }
</style>
