export default {
  namespaced: true,

  state: {
    selectedPlague: {},
    selectedPhenologicalState: {},
    phenologicalStates: [],
    plaguesAndDiseases: [],
    actualSelection: '',
    selectedPlagueState: '',
    selectedWeatherCondition: {},
    selectedScoutingPoint: '',
    incidentName: '',
    formMonitoringPoint: {
      date: '', disease: [], plagues: [], wheater_conditions: [], phenology: [], others: [],
    },
    locationSelected: '',
    pictures: [],
    latitude: 0,
    longitude: 0,
  },

  getters: {
    selectedPlague: (state) => state.selectedPlague,
    selectedPhenologicalState: (state) => state.selectedPhenologicalState,
    phenologicalStates: (state) => state.phenologicalStates,
    plaguesAndDiseases: (state) => state.plaguesAndDiseases,
    actualSelection: (state) => state.actualSelection,
    selectedPlagueState: (state) => state.selectedPlagueState,
    selectedWeatherCondition: (state) => state.selectedWeatherCondition,
    selectedScoutingPoint: (state) => state.selectedScoutingPoint,
    formMonitoringPoint: (state) => state.formMonitoringPoint,
    incidentName: (state) => state.incidentName,
    locationSelected: (state) => state.locationSelected,
    pictures: (state) => state.pictures,
    latitude: (state) => state.latitude,
    longitude: (state) => state.longitude,
  },

  actions: {
    resetScoutingStates({ commit }) {
      commit('SET_SELECTED_PLAGUE', {});
      commit('SET_SELECTED_PHENOLOGICAL_STATE', {});
      commit('SET_PHENOLOGICAL_STATES', {});
      commit('SET_PLAGUES_AND_DISEASES', {});
      commit('SET_ACTUAL_SELECTION', '');
      commit('SET_SELECTED_PLAGUE_STATE', '');
      commit('SET_SELECTED_WEATHER_CONDITION', '');
      commit('SET_SELECTED_SCOUTING_POINT', '');
      commit('SET_INCIDENT_NAME', '');
      commit('SET_FORM_MONITORING_POINT', {
        date: '', disease: [], plagues: [], wheater_conditions: [], phenology: [], others: [],
      });
      commit('SET_LOCATION_SELECTED', '');
      commit('SET_PICTURES', []);
      commit('SET_LATITUDE', 0);
      commit('SET_LONGITUDE', 0);
    },
    setSelectedPlague({ commit }, value) {
      commit('SET_SELECTED_PLAGUE', value);
    },
    setSelectedPhenologicalState({ commit }, value) {
      commit('SET_SELECTED_PHENOLOGICAL_STATE', value);
    },
    setPhenologicalStates({ commit }, value) {
      commit('SET_PHENOLOGICAL_STATES', value);
    },
    setPlaguesAndDiseases({ commit }, value) {
      commit('SET_PLAGUES_AND_DISEASES', value);
    },
    setActualSelection({ commit }, value) {
      commit('SET_ACTUAL_SELECTION', value);
    },
    setSelectedPlagueState({ commit }, value) {
      commit('SET_SELECTED_PLAGUE_STATE', value);
    },
    setSelectedWeatherCondition({ commit }, value) {
      commit('SET_SELECTED_WEATHER_CONDITION', value);
    },
    setSelectedScoutingPoint({ commit }, value) {
      commit('SET_SELECTED_SCOUTING_POINT', value);
    },
    setIncidentName({ commit }, value) {
      commit('SET_INCIDENT_NAME', value);
    },
    setFormMonitoringPoint({ commit }, value) {
      commit('SET_FORM_MONITORING_POINT', value);
    },
    setNewFormMonitoringPoint({ commit }) {
      commit('SET_FORM_MONITORING_POINT', {
        date: '', disease: [], plagues: [], wheater_conditions: [], phenology: [], others: [],
      });
    },
    setLocationSelected({ commit }, value) {
      commit('SET_LOCATION_SELECTED', value);
    },
    setPictures({ commit }, value) {
      commit('SET_PICTURES', value);
    },
    setLatitude({ commit }, value) {
      commit('SET_LATITUDE', value);
    },
    setLongitude({ commit }, value) {
      commit('SET_LONGITUDE', value);
    },
  },

  mutations: {
    SET_SELECTED_PLAGUE(state, payload) {
      state.selectedPlague = payload;
    },
    SET_SELECTED_PHENOLOGICAL_STATE(state, payload) {
      state.selectedPhenologicalState = payload;
    },
    SET_PHENOLOGICAL_STATES(state, payload) {
      state.phenologicalStates = payload;
    },
    SET_PLAGUES_AND_DISEASES(state, payload) {
      state.plaguesAndDiseases = payload;
    },
    SET_ACTUAL_SELECTION(state, payload) {
      state.actualSelection = payload;
    },
    SET_SELECTED_PLAGUE_STATE(state, payload) {
      state.selectedPlagueState = payload;
    },
    SET_SELECTED_WEATHER_CONDITION(state, payload) {
      state.selectedWeatherCondition = payload;
    },
    SET_SELECTED_SCOUTING_POINT(state, payload) {
      state.selectedScoutingPoint = payload;
    },
    SET_INCIDENT_NAME(state, payload) {
      state.incidentName = payload;
    },
    SET_FORM_MONITORING_POINT(state, payload) {
      state.formMonitoringPoint = payload;
    },
    SET_LOCATION_SELECTED(state, payload) {
      state.locationSelected = payload;
    },
    SET_PICTURES(state, payload) {
      state.pictures = payload;
    },
    SET_LATITUDE(state, payload) {
      state.latitude = payload;
    },
    SET_LONGITUDE(state, payload) {
      state.longitude = payload;
    },
  },
};
