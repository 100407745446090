import Dexie from 'dexie';
import { DATABASE_NAME } from '../js/constants';

const db = new Dexie(DATABASE_NAME);

// TODO: Comprobar si sobran campos.
db.version(1).stores({
  tasksDefinition: '++id, activity_code',
  tasksSync: 'id, location_id, task, activity_code, task_name, status',
  employees: '++id, code',
  locations: 'bd_id, farm',
  tasksActivities: 'code',
  modifiedRecords: '[idTask+idLocation], type',
  items: '++id, family',
  families: '++id',
  bins: '++id, modified',
  binsVariety: '++id',
  binsCategory: '++id',
  binsClassification: '++id',
  plaguesAndDiseases: '++id',
  phenologicalStates: '++id',
  scoutingPoint: '++id',
  scoutingForm: '++id',
  egress: '++id',
});

export default db;
