<template>
  <div>
    <f7-button
      v-if="!isSyncing"
      class=""
      @click="refreshNow()"
    >
      <f7-icon

        :class="`icon-menu ic-refresh`"
      >
        <f7-badge
          v-if="syncCounterDisplay"
          color="red"
        >
          {{ syncCounterDisplay }}
        </f7-badge>
      </f7-icon>
    </f7-button>
    <div
      v-if="isSyncing"
      class="refresh-preloader"
    >
      <f7-preloader
        :size="30"
        color="white"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EventBus from '../../js/event-bus';
// import ImageEdit from '../../static/img/ic_edit-btn-round.svg';

export default {
  name: 'ButtonSync',

  props: {
    refreshBtnEnable: { type: Boolean, default: false },
  },
  computed: {
    syncCounterDisplay() {
      return this.itemsPendingToSyncCounter.length;
    },
    ...mapState('authentication', ['loggedUser']),
    ...mapGetters('databaseSync', ['itemsPendingToSyncCounter']),
    ...mapState('databaseSync', ['isSyncing']),
  },
  beforeMount() {
    EventBus.$on('SetNew_SyncErrorText', this.setNewSyncError);
  },
  beforeDestroy() {
    EventBus.$off('SetNew_SyncErrorText');
  },
  methods: {
    setNewSyncError(code) {
      switch (code) {
        case '':
          this.setSyncErrorText('');
          break;
        case 'Generic_Error':
          this.setSyncErrorText(this.$t('Generic_Error'));
          break;
        case 'NoConnection_Error':
          this.setSyncErrorText(this.$t('NoConnection_Error'));
          break;
        default:
          this.setSyncErrorText(`${this.$t('Code_Error')} ${JSON.parse(code).error_code}`);
          break;
      }
    },
    refreshNow() {
      if (this.loggedUser) {
        this.forceSync()
          .catch((error) => { this.$f7.dialog.alert(error); });
      }
    },
    ...mapActions('databaseSync', ['forceSync', 'setSyncErrorText']),
  },
};
</script>

<style lang="scss" scoped>
.icon-menu{
  width: 38px;
  height: 38px;
}
.ic-refresh {
  background-image: url("../../static/img/ic_refresh.svg");
}

</style>
