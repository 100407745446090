import Sync from '../../../services/Sync';
import AccessControl from '../../../services/AccessControl';
import EventBus from '../../../js/event-bus';
import ModifiedRecords from '../../../js/models/ModifiedRecords';

let elementsToSync = 0;

// Events
EventBus.$on('updateElementsToSync', async () => {
  const countModifiedRecords = await ModifiedRecords.countElement();
  console.log('numero a sincronizar: ', countModifiedRecords);
  // FIXME: No funciona esto.
  elementsToSync = countModifiedRecords;
  console.log('numero a sincronizar variable de clase: ', elementsToSync);
});

export default {
  namespaced: true,

  state: {
    isSyncing: false,
    syncError: null,
    lastSuccessfulSync: null,
    accessControlItemsToSync: 0,
    elementsToSync: 0,
    SyncErrorText: '',
    deviceUuid: '',
  },
  getters: {
    itemsPendingToSyncCounter(state) {
      return state.accessControlItemsToSync;
    },
    SyncErrorText: (state) => state.SyncErrorText,
    getDeviceUuid: (state) => state.deviceUuid,
  },
  actions: {
    async syncTasksSync(context) {
      //Método para sincronizar sólo tasksSync
      if (context.state.isSyncing) {
        return;
      }
      context.commit('setSyncingState', true);
      context.commit('setSyncError', null);
      try {
        await Sync.syncTasksSync(context.getters.getDeviceUuid);
        const eventsPendingToSync = await AccessControl.getEventsPendingToSync();
        context.commit('setAccessControlItemsToSync', eventsPendingToSync);
      } catch (e) {
        if (e === 'error') {
          context.commit('setSyncError', 'No se ha podido conectar con el servidor');
        } else if (e === 404) {
          context.commit('setSyncError', 'No hay tareas para la fecha hoy.');
          context.commit('setSyncingState', false);
          throw Error('No hay tareas para la fecha de hoy.');
        } else {
          context.commit('setSyncError', 'Respuesta del servidor inesperada');
          context.commit('setSyncingState', false);
          throw Error('Respuesta del servidor inesperada');
        }
      }
      context.commit('setSyncingState', false);
    },
    async forceSync(context) {
      //Método para sincronizar de forma forzada con el boton sync
      if (context.state.isSyncing) {
        return;
      }
      context.commit('setSyncingState', true);
      context.commit('setSyncError', null);
      try {
        await Sync.forceSync(context.getters.getDeviceUuid);
        const eventsPendingToSync = await AccessControl.getEventsPendingToSync();
        context.commit('setAccessControlItemsToSync', eventsPendingToSync);
      } catch (e) {
        if (e === 'error') {
          context.commit('setSyncError', 'No se ha podido conectar con el servidor');
        } else if (e === 404) {
          context.commit('setSyncError', 'No hay tareas para la fecha hoy.');
          context.commit('setSyncingState', false);
          throw Error('No hay tareas para la fecha de hoy.');
        } else {
          context.commit('setSyncError', 'Respuesta del servidor inesperada');
          context.commit('setSyncingState', false);
          throw Error('Respuesta del servidor inesperada');
        }
      }
      context.commit('setSyncingState', false);
    },
    async syncWithUpstream(context) {
      if (context.state.isSyncing) {
        return;
      }
      context.commit('setSyncingState', true);
      context.commit('setSyncError', null);
      try {
        const sincro = await Sync.syncWithUpstream(context.getters.getDeviceUuid);
        if (sincro.indexOf(0) === -1) {
          const eventsPendingToSync = await AccessControl.getEventsPendingToSync();
          context.commit('setAccessControlItemsToSync', eventsPendingToSync);
          context.commit('updateLastSuccessfulSyncTimestamp');
        }
      } catch (e) {
        if (e === 'error') {
          context.commit('setSyncError', 'Could not connect to the server');
        } else {
          context.commit('setSyncError', 'Unexpected server response');
          context.commit('setSyncingState', false);
          throw Error('Unexpected server response');
        }
      }
      context.commit('setSyncingState', false);
    },

    removeLastSync(context) {
      context.commit('removeLastSuccessfulSyncTimestamp');
    },
    start(context) {
      context.commit('setSyncingState', true);
    },

    finish(context) {
      context.commit('setSyncingState', false);
    },
    setSyncErrorText({ commit }, value) {
      commit('SET_SYNC_ERROR_TEXT', value);
    },
    setDeviceUuid(context, value) {
      context.commit('SET_DEVICE_UUID', value);
    },
  },

  mutations: {
    setSyncingState(state, value) {
      state.isSyncing = value;
    },

    setSyncError(state, value) {
      state.syncError = value;
    },

    updateLastSuccessfulSyncTimestamp(state) {
      state.lastSuccessfulSync = Date.now();
    },

    removeLastSuccessfulSyncTimestamp(state) {
      state.lastSuccessfulSync = null;
    },

    setAccessControlItemsToSync(state, value) {
      state.accessControlItemsToSync = value;
    },

    SET_SYNC_ERROR_TEXT(state, payload) {
      state.SyncErrorText = payload;
    },

    SET_DEVICE_UUID(state, value) {
      state.deviceUuid = value;
    },
  },
};
