const dictionary = {
  en: {
    translation: {
      Login_txtWelcome: 'Access',
      Login_inpUsername_placeholder: 'Username',
      Login_inpPassword_label: 'Password',
      Login_inpPassword_placeholder: 'Password',
      Login_chkRememberMe: 'Remember me',
      Login_txtRestorePass: 'Restore Password',
      Login_txtNeedHelp: 'Do you need some help?',
      Login_btnSignIn: 'SIGN IN',
      Login_btnSignUp_question: 'Not registered yet?',
      Login_btnSignUp_link: 'Sign up here',
      Login_msgEmail_title: 'Information',
      Login_msgEmail_content: 'You will shortly receive an email to change your password',
      Login_msgEmail_btnClose: 'Close',
      loggOut_Message: 'Are you sure you want to exit?',
      loggOut_Warning_Message: 'If you log out, you will lose all the data pending synchronization. Are you sure you want to exit?',
      Delete_Message: 'are you sure you want to delete this task?',
      stop_task: 'Are you sure you want to STOP this task?',
      onlyStop: 'STOP',
      RecoveryPassword_txtDataAccess: 'Enter your email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reset password',
      RecoveryPassword_btnGoBack: 'Go back',
      ChangePassword_txtDataAccess: 'Enter your new password',
      ChangePassword_inpEmail_placeholder: 'Password',
      ChangePassword_inpRepeatEmail_placeholder: 'Repeat password',
      ChangePassword_btnChangePassword: 'Change password',
      CheckOutMulti_note_lable: 'Note',
      CheckOutMulti_note_placeholder: 'Tap here to add a note',
      CheckOutMulti_note_alert: 'You can write a maximum of 50 caracteres on the note, currently there is ',
      assigned: 'You are assigned',
      CheckIn: 'Checkin',
      CheckOut: 'CheckOut',
      nfcManuallySearch: 'Search for name or surname worker',
      ManualListSearch: 'Search for name or surname',
      ManualListAccesSearch: 'Search',
      nfcManuallyCheckOut: 'AssignedCheckOut',
      Form_fitosanitary_quantityPlanned: 'Quantity Planned',
      Form_fitosanitary_doseCollected: 'Quantity collected ',
      Form_fitosanitary_dosisUsed: 'Quantity used',
      Form_fitosanitary_doseReturn: 'Quantity Returned to WH',
      Form_fitosanitary_totalrealprescripcion: 'Wetting Used L',
      Form_machinery_title: 'Machinery',
      Form_fitosanitary_title: 'Fitosanitary',
      Form_machinery_KMInicial: 'KM Start',
      Form_machinery_KMFinal: 'KM End',
      Form_machinery_horometroInicial: 'Horometro Start',
      Form_machinery_workingArea: 'Working Area',
      Form_machinery_fuelType: 'Fuel Type',
      Form_machinery_horometroFinal: 'Horometro End',
      Form_machinery_literConsumed: 'Liters Consumed',
      Form_machinery_observations: 'Observations',
      Form_machinery_ERRORKM: 'KM Start cann´t be greater than KM End',
      Form_machinery_ERRORHORO: 'Horometro Start cann´t be greater than Horometro End',
      Register_registerText: 'REGISTER',
      Register_btnEnter: 'ENTER',
      Register_inpName_name: 'Name',
      Register_inpName_placeholder: 'Name',
      Register_inpSurnames_name: 'Surnames',
      Register_inpSurnames_placeholder: 'Surnames',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Password',
      Register_inpPassword_placeholder: 'Password',
      Register_inpPasswordVerification_name: 'RepeatPassword',
      Register_inpPasswordVerification_placeholder: 'Repeat password',
      Register_inpUsername_name: 'Username',
      Register_inpUsername_placeholder: 'Username',
      Nfc_into_error_404: 'The employee is not registered',
      Nfc_into_error_400: 'The nfc format is invalid',
      Nfc_into_error_406: 'The current task is already created in this location',
      Nfc_into_error_409: 'The worker is already working in another task',
      Nfc_info_txtTitle: 'Card reading',
      Nfc_info_txtSubTitle: 'Approach the card for reading',
      Nfc_navbarpresence_incidence: 'Checkin',
      Nfc_navbarpresence_incidence_checkout: 'Checkout',
      Nfc_navbarTask_checkout: 'Productivity',
      Nfc_navbarTask_startorder: 'Start Order',
      Editorder_title: 'Edit Order',
      Editorder_driver: 'DRIVER ',
      Nfc_navbarpresence_manual: 'Add NFC manually',
      Nfc_navbarpresence_btnContinue: 'Continue',
      NfcManually_navbarpresence_gotoproduct: 'GO TO PRODUCT',
      NfcManually_navbarpresence_skipall: 'SKIP ALL',
      NfcManually_navbarpresence_btnContinue: 'Continue',
      NfcManually_listInput_placeholder: 'Name or surname',
      LeftPanel_title_taskManagement: 'TASK MANAGEMENT',
      LeftPanel_Subtitle_DAILYsUMMARY: 'Daily Summary',
      LeftPanel_Subtitle_searchworker: 'Search Workers',
      LeftPanel_Subtitle_historyboards: 'History Boards',
      LeftPanel_title_information: 'INFORMATION',
      LeftPanel_Subtitle_SIGNOUT: 'Log Out',
      Profile_info_profile: 'Profile info',
      Profile_name_placeholder: 'Name',
      Profile_surname_label: '',
      Profile_surname_placeholder: '',
      Profile_email_label: '',
      Profile_email_placeholder: '',
      Profile_mobile_label: '',
      Profile_mobile_placeholder: '',
      Profile_password_profile: '',
      Profile_oldPass_label: '',
      Profile_oldPass_placeholder: '',
      Profile_newPass_label: '',
      Profile_newPass_placeholder: '',
      Profile_checkNewPass_label: '',
      Profile_checkNewPass_placeholder: '',
      Profile_change_password: '',
      Profile_photo_profile: '',
      Profile_update_photo: '',
      Profile_setup_profile: '',
      Profile_language_label: '',
      Profile_theme_label: '',
      Profile_save_changes: '',
      Home_info_txtTitle: 'No workers',
      ExportWorkerList_btnExport: '',
      ExportExcel_thNombre: '',
      ExportExcel_thTipo: '',
      ExportExcel_thFecha: '',
      ExportExcel_captionListaEmpleados: '',
      editorder_noSelectSameVehicle_error: 'Error: Can´t select the same vehicle in two diferent select, select another vehicle.',
      SearchEmployee_title: 'Search Employee',
      SheetExport_sheet_export_ok: 'Downloaded file: ',
      SheetExport_sheet_export_error: 'Error into download',
      StartOrder_WorkOrderId: 'Work Order ID',
      StartOrder_date: 'Date',
      StartOrder_activityName: 'Activity Name',
      StartOrder_activityCode: 'Activity Code',
      StartOrder_taskName: 'Task Name',
      StartOrder_taskCode: 'Task Code',
      StartOrder_grouped: 'Grouped',
      StartOrder_group: 'Group',
      StartOrder_shift: 'Shift',
      startorder_dosistotal: 'Dose in disolution',
      startorder_productname: 'Name',
      startorder_producttype: 'Type',
      Startorder_ProductTitle: 'Products',
      Startorder_Tools: 'Tools',
      Startorder_attachments: 'Attachments',
      yes: 'Yes',
      no: 'No',
      StartOrder_productivity_working_area_goal: 'Working Area Goal',
      StartOrder_productivity_goal: 'Productivity goal',
      StartOrder_farm: 'Farm',
      StartOrder_area: 'Area',
      StartOrder_lote: 'Lot',
      StartOrder_parcel: 'Parcel',
      StartOrder_subParcel: 'SubParcel',
      StartOrder_ha: 'Ha',
      StartOrder_machineFamily: 'Machine Family',
      StartOrder_machineDriver: 'Driver ',
      StartOrder_taskUnit: 'Task Unit',
      StartOrder_unitPrice: 'Unit Price',
      Start_order: 'Select Workers',
      Startorder_titlePrice: 'Price',
      Startorder_titleMachinery: 'Machinery',
      Startorder_additionalInformation: 'Additional Information',
      Startorder_titleLocation: 'Location',
      Editorder_titleLocation: 'Location - Working Area :',
      Startorder_titleGeneral: 'General',
      TasksList_navbar_text: 'Daily summary',
      Hisotry_navbar_text: 'History board',
      TasksList_lstNewTasks_title: 'Init new task',
      TasksList_lstInProgressTasks_title: 'In progress tasks',
      TasksList_lstCompletedTasks_title: 'Completed tasks',
      TasksList_Workers_title: 'Workers',
      TasksList_WorkersSelection_title: 'No workers in task',
      TasksList_WorkersSelection_subtitle: 'Click the top button to link workers',
      AccordionList_workers: 'workers',
      AccordionList_worker: 'worker',
      Home_conductor: 'Tractorist',
      ManualList_listItem_title: 'No employee found',
      checkOut_Productivity_placeholder: 'Field Number',
      checkOut_Productivity: 'Productivity',
      checkOut_workingArea: 'Working Area',
      checkOut_Task: 'Task',
      checkOut_observations: 'Observations',
      checkOut_assigned: 'You are assigned productivity for',
      checkOut_assigned_single: 'individually worker',
      checkOut_assigned_multiple: 'all workers',
      list_content_farm_img_alt: 'Change farm',
      farmSelection_navbar_text: 'Select Farm',
      locationSelect_navbar_text: 'Select Location',
      WorkersInTask: 'Workers',
      TasksList_filterTask_title: 'Select task',
      workersSelection_buttomPanelConfig_multiple: 'Multiple workers',
      workersSelection_buttomPanelConfig_individual: 'Individually assign',
      workersSelection_buttomPanelConfig_title: 'Select your register productivity',
      SyncingModal_infoUpdate_title: 'Data update',
      SyncingModal_infoUpdate_text: 'Synchronization with the server is necessary so that you can continue working even offline.',
      SyncingModal_infoLoading_title: 'Loading data…',
      SyncingModal_infoLoading_text: 'This process may take a few minutes, depending on the speed of the device connection.',
      SyncingModal_txtError: 'Error:',
      SyncingModal_btnSync: 'Synchronize',
      Status_Open: 'PENDING',
      Status_InProgress: 'IN PROGRESS',
      Status_Completed: 'COMPLETED',
      EditOrder_ID: 'ID',
      EditOrder_productivityGoal: 'Productivity goal',
      binsConsult_search_text: 'Search for name or code bins',
      binsConsult_listInput_placeholder: 'Name or code',
      LeftPanel_title_nursery: 'NURSERY',
      LeftPanel_Subtitle_ConsultBins: 'Consult Bins',
      ManualListBins_listItem_title: 'No bins found',
      binContent_NBins: 'Nº Bins:',
      binContent_NBatch: 'Nº Batch:',
      binContent_Origin: 'Farm Origin:',
      binContent_Destination: 'Farm Destination:',
      binContent_State: 'Status:',
      binContent_LastLevel: 'Last Level:',
      binContent_Variety: 'Variety:',
      binContent_Category: 'Category:',
      binContent_Classification: 'Classification:',
      binContent_NumPlants: 'Nº Plants',
      binContent_State_Created: 'Created',
      binContent_State_Registered: 'Registered',
      binContent_State_Sent: 'Sent',
      binContent_State_ToReceive: 'To Receive',
      binContent_State_Received: 'Received',
      binContent_State_Closed: 'Closed',
      BinsContent_Button_btnReclassify: 'RECLASSIFY',
      BinsContent_Button_btnConfirm: 'CONFIRM',
      BinsContent_Button_btnMove: 'MOVE',
      BinsContent_Button_btnContinue: 'CONTINUE',
      BinsContent_Button_btnSave: 'SAVE',
      binsNewContent_Select_ParentCode: 'Parent Code:',
      binsNewContent_Select_Category: 'Category:',
      binsNewContent_Select_Classification: 'Classification:',
      binsNewContent_Alert: 'Parent Code, Category and Classification must be selected.',
      binsMove_CheckBox_SelectAll: 'Select All',
      binsMove_Alert_ButtonMove: 'You have to select at least one content',
      binsChangeLocation_Advice_SelectLastLevel: '*IMPORTANT: Select only one last level',
      binsChangeLocation_Title: 'Change Location',
      binsChangeLocation_Alert_SelectOneLocation: 'You have to select one and only one location',
      EditOrder_BinCode: 'Bin-Code',
      EditOrder_Nursery: 'Nursery',
      LeftPanel_title_scouting: 'Scouting',
      LeftPanel_Subtitle_PestsAndDiseases: 'Pests and Diseases',
      LeftPanel_Subtitle_PhenologicalStates: 'Phenological States',
      LeftPanel_Subtitle_FixedMonitoringPoint: 'Fixed Monitoring Point',
      LeftPanel_Subtitle_CasualRegister: 'Casual Register',
      pestsAndDiseases_search_text: 'Name of Pest or Disease',
      pestsAndDiseases_listInput_placeholder: 'Name',
      PestsAndDiseases_listItem_title: 'No Pests and Diseases Found',
      pestAndDiseasesContent_title_ImageDescription: 'Image Description',
      CasualRegister_Title_Date: 'Date',
      CasualRegister_Title_IncidentName: 'Incident Name',
      CasualRegister_Title_Type: 'Type',
      CasualRegister_Title_Species: 'Species',
      CasualRegister_Title_Comments: 'Comments',
      CasualRegister_Title_Location: 'Location',
      CasualRegister_Button_Finish: 'Finish',
      FixedMonitoringPoint_Title_MonitoringPoint: 'Monitoring Point',
      FixedMonitoringPoint_Button_Continue: 'Continue',
      SelectAction_Title: 'Select Action',
      formAction_Title_Pests: 'Pests',
      formAction_Title_Diseases: 'Diseases',
      formAction_Title_Phenological: 'Phenology',
      formAction_Title_WeatherConditions: 'Extreme Weather Conditions',
      formAction_Title_Other: 'Others',
      SelectAction_Button_Finish: 'Finish',
      Pests_Phenological_State_of_the_Crop: 'Phenological State',
      Pests_Species: 'Species',
      Pests_State_of_Development: 'State of Development',
      Pests_Comments: 'Comments',
      Pests_NDetected: 'Nº Detected',
      Pests_Next: 'Next',
      Pests_Finish: 'Finish',
      Pests_TakePicture: 'Take a Picture',
      ExtremeWeatherConditions_Type: 'Type',
      phenologicalStates_search_text: 'Phenological State´s Code or Name',
      phenologicalStates_listInput_placeholder: 'Name or Code',
      phenologicalStates_listItem_title: 'No Phenological State Found',
      formAction_Dialog_Confirm: 'Are you sure you want to go back? If you get out, no added data will be saved.',
      formAction_Alert_MissingData: 'Missing data to fill',
      formAction_Dialog_Finish: 'If you finish, the elements of the current screen will not be saved, but the elements added so far will be saved. Are you sure?',
      AccordionItem_Notes: 'Notes',
      Generic_Error: 'An unexpected error has occurred. Please contact support.',
      NoConnection_Error: 'An unexpected error has occurred. (Make sure you have a connection)',
      Code_Error: 'Synchronization error. Support code: ',
      TakePicture_ConfirmDeletePicture: 'Are you sure you want to delete this image?',
      pestAndDiseases_ViewAll: 'View All',
      Error_TooManyPictures: 'You have reached the maximum number of photos in this report',
      formAction_Alert_EnableGPS: 'You must grant location permissions and enable GPS',
      checkOut_checkInLabel: 'CheckIn',
      checkOut_checkOutLabel: 'CheckOut',
      checkOut_select_date: 'Select date',
      checkOut_select_time: 'Select time',
      checkOut_newCheckIn: 'New CheckIn',
      checkOut_newCheckOut: 'New CheckOut',
      binContent_Egress: 'Egress:',
      CheckOutMulti_checkout_alert: 'Checkin must be before CheckOut',
      Start_edit_order_disolucion: 'PLANNED SOLUTION',
    },
  },
};
export default dictionary;
