// Accounts
import Login from '../pages/accounts/loginPage.vue';
import Logout from '../pages/accounts/logout.vue';
// Presence
import NfcPage from '../pages/presence/nfcPage.vue';
import NfcManually from '../pages/presence/nfcManually.vue';
import NfcPageCheckOut from '../pages/presence/nfcPageCheckOut.vue';
import NfcManuallyCheckOut from '../pages/presence/nfcManuallyCheckOut.vue';
import AccessPage from '../pages/presence/accessPage.vue';
import NfcManuallyAccessPage from '../pages/presence/nfcManuallyAccess.vue';
import NfcAccessPage from '../pages/presence/nfcPageAccess.vue';
// Tasks
import FarmSelection from '../pages/farmSelection.vue';
import TaskList from '../pages/tasks/list.vue';
import history from '../pages/history.vue';
import searchEmployee from '../pages/tasks/searchEmployee.vue';
import WorkersSelection from '../pages/tasks/workersSelection.vue';
import ExportTask from '../pages/tasks/exportTask.vue';
import LocationSelect from '../pages/locationSelect.vue';
// Nursery
import binsConsult from '../pages/greenhouse/binsConsult.vue';
import binsContent from '../pages/greenhouse/binsContent.vue';
import binsReclassify from '../pages/greenhouse/binsReclassify.vue';
import binsNewContent from '../pages/greenhouse/binsNewContent.vue';
import binsMove from '../pages/greenhouse/binsMove.vue';
import binsChangeLocation from '../pages/greenhouse/binsChangeLocation.vue';
import qrScanner from '../pages/qrScanner.vue';
// Scouting
import pestsAndDiseases from '../pages/scouting/pestsAndDiseases.vue';
import casualRegister from '../pages/scouting/casualRegister.vue';
import fixedMonitoringPoint from '../pages/scouting/fixedMonitoringPoint.vue';
import selectAction from '../pages/scouting/selectAction.vue';
import formAction from '../pages/scouting/formAction.vue';
import phenologicalStates from '../pages/scouting/phenologicalStates.vue';
// Others
import NotFoundPage from '../pages/404.vue';
import ExportWorker from '../pages/ExportWorkerList.vue';
import startOrder from '../pages/startOrder.vue';
import editOrder from '../pages/editOrder.vue';
// Check Out
import CheckOut from '../pages/tasks/checkOut.vue';
import CheckOutMulti from '../pages/tasks/checkOutMulti.vue';
import registrationMachinery from '../pages/tasks/RegistrationMachineryData.vue';
import RegistrationFitosanitary from '../pages/tasks/registrationFitosanitaryData.vue';

const routes = [
  {
    path: '/',
    component: TaskList,
  },
  {
    path: '/history/',
    component: history,
  },
  // Accounts
  {
    path: '/accounts/loginPage/',
    component: Login,
  },
  {
    path: '/accounts/logout/',
    component: Logout,
  },
  // Presence
  {
    path: '/nfc/',
    component: NfcPage,
  },
  {
    path: '/nfcManually/',
    component: NfcManually,
  },
  {
    path: '/nfcCheckOut/',
    component: NfcPageCheckOut,
  },
  {
    path: '/nfcManuallyCheckOut/',
    component: NfcManuallyCheckOut,
  },
  {
    path: '/accessPage/',
    component: AccessPage,
  },
  {
    path: '/nfcAccessPage/',
    component: NfcAccessPage,
  },
  {
    path: '/nfcManuallyAccessPage/',
    component: NfcManuallyAccessPage,
  },
  {
    path: '/tasks/searchEmployee/',
    component: searchEmployee,
  },
  {
    path: '/tasks/RegistrationMachineryData/',
    component: registrationMachinery,
  },
  {
    path: '/tasks/RegistrationFitosanitaryData/',
    component: RegistrationFitosanitary,
  },
  // Tasks
  {
    path: '/tasks/list/',
    component: TaskList,
  },
  // Check Out
  {
    path: '/tasks/checkOut/:pathBack/',
    component: CheckOut,
  },
  // Check Out Multiple
  {
    path: '/tasks/checkOutMulti/',
    component: CheckOutMulti,
  },
  // Nursery
  {
    path: '/bins/consult/',
    component: binsConsult,
  },
  {
    path: '/bins/content/',
    component: binsContent,
  },
  {
    path: '/bins/reclassify/',
    component: binsReclassify,
  },
  {
    path: '/bins/newContent/',
    component: binsNewContent,
  },
  {
    path: '/bins/move/',
    component: binsMove,
  },
  {
    path: '/bins/changeLocation/',
    component: binsChangeLocation,
  },
  {
    path: '/qrScanner/',
    component: qrScanner,
  },
  // Scouting
  {
    path: '/pestsAndDiseases/',
    component: pestsAndDiseases,
  },
  {
    path: '/formAction/',
    component: formAction,
  },
  {
    path: '/phenologicalStates/',
    component: phenologicalStates,
  },
  {
    path: '/fixedMonitoringPoint/',
    component: fixedMonitoringPoint,
  },
  {
    path: '/selectAction/',
    component: selectAction,
  },
  {
    path: '/casualRegister/',
    component: casualRegister,
  },
  // Others
  {
    path: '/dashboard/',
    component: TaskList,
  },
  {
    path: '/ExportWorkerList/',
    component: ExportWorker,
  },
  {
    path: '/locationSelect/',
    component: LocationSelect,
  },
  {
    path: '/tasks/workersSelection/',
    component: WorkersSelection,
  },
  {
    path: '/tasks/ExportTask/',
    component: ExportTask,
  },
  // Tasks
  {
    path: '/farmSelection/',
    component: FarmSelection,
  },
  {
    path: '/startOrder/',
    component: startOrder,
  },
  {
    path: '/edit/',
    component: editOrder,
  },
  // No found
  {
    path: '(.*)',
    component: NotFoundPage,
  },

];

export default routes;
