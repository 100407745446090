<template>
  <f7-page
    class="content-list-order main-editorder"
    @page:beforein="beforein"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${activity} - ${$t('Editorder_title')}`"
      :path-back="`/${pathBack}/`"
    />
    <div class="container">
      <div class="block block-header header-space">
        <div class="item-inner">
          <div class="item-cell">
            <div class="block-module">
              <img
                class="icon-line float-left"
                width="38"
                height="38"
                :src="images.workers"
              >
              <span class="number-icon">{{ workers.length }} / {{ count(plan_workers) }}</span>
            </div>
          </div>
          <div class="item-cell">
            <div class="block-module" />
          </div>
        </div>
      </div>
      <f7-list
        accordion-item-opened
      >
        <!-- General item -->
        <f7-list-item
          accordion-item
          :title="`${$t('Startorder_titleGeneral')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("EditOrder_ID") }}
                </f7-col>
                <f7-col>
                  {{ short_id }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_date") }}
                </f7-col>
                <f7-col>
                  {{ date() }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_activityName") }}
                </f7-col>
                <f7-col>
                  {{ activity }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_activityCode") }}
                </f7-col>
                <f7-col>
                  {{ activityId }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_taskName") }}
                </f7-col>
                <f7-col>
                  {{ task_name }}
                </f7-col>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_taskCode") }}
                </f7-col>
                <f7-col>
                  {{ task_code }}
                </f7-col>
              </f7-row>
              <f7-row
                v-if="planner.goal_performance.unit != ''"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("EditOrder_productivityGoal") }}
                </f7-col>
                <f7-col>
                  {{ planner.goal_performance.count }} {{ planner.goal_performance.unit }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of General item -->
        <f7-list-item
          accordion-item
          :title="`${$t('Editorder_titleLocation')} ${planner.goal_performance.working_area} HA`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-block
                strong
                class="no-padding-horizontal no-margin"
              >
                <f7-treeview>
                  <f7-treeview-item
                    v-for="(item, index) in locationsTree"
                    :key="index"
                    class="content-tree"
                    :label="`${item.name}`"
                    :opened="isChecked(item) || isIndeterminate(item)"
                  >
                    <f7-checkbox
                      slot="content-start"
                      :checked="isChecked(item)"
                      :indeterminate="isIndeterminate(item)"
                      @change="changeChildren($event.target.checked, item)"
                    />
                    <f7-treeview-item
                      v-for="(item2, index2) in item.children"
                      :key="index2"
                      :label="`${item2.name}`"
                    >
                      <f7-checkbox
                        slot="content-start"
                        :checked="isChecked2(item2)"
                        :indeterminate="isIndeterminate2(item2)"
                        @change="changeChildren2($event.target.checked, item2)"
                      />
                      <f7-treeview-item
                        v-for="item3 in item2.children"
                        :key="item3.ID"
                        :label="`${item3.name}`"
                      >
                        <f7-checkbox
                          slot="content-start"
                          :checked="isChecked3(item3)"
                          :indeterminate="isIndeterminate3(item3)"
                          @change="changeChildren3($event.target.checked, item3)"
                        />
                        <f7-treeview-item
                          v-for="(item4, index4) in item3.children"
                          :key="index4"
                          :label="`${item4.name}`"
                          :opened="isChecked4(item4) || isIndeterminate4(item4)"
                        >
                          <f7-checkbox
                            slot="content-start"
                            :checked="isChecked4(item4)"
                            :indeterminate="isIndeterminate4(item4)"
                            @change="changeChildren4($event.target.checked, item4)"
                          />
                          <f7-treeview-item
                            v-for="(item5, index5) in item4.children"
                            :key="index5"
                            :label="`${item5.name}`"
                          >
                            <f7-checkbox
                              slot="content-start"
                              :checked="isEquals(item5, locationId) || item5.level === true"
                              @change="addEvent($event.target.checked, item5)"
                            />
                          </f7-treeview-item>
                        </f7-treeview-item>
                      </f7-treeview-item>
                    </f7-treeview-item>
                  </f7-treeview-item>
                </f7-treeview>
              </f7-block>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Location item -->

        <!-- Machinery item -->
        <f7-list-item
          v-if="planner != null && itemsMachinery.length > 0"
          accordion-item
          :title="`${$t('Startorder_titleMachinery')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in itemsMachinery"
                :key="'machinery'+index"
                no-gap
                class="justify-content-flex-start content-accordion-machinary"
              >
                <f7-list-item
                  :title="item.subfamily+ ' ' + (index+1)"
                  smart-select
                  :smart-select-params="{openIn: 'popup', searchbar: true,
                                         searchbarPlaceholder: 'Machinery',
                                         setValueText: true, valueEl: items[index].name}"
                >
                  <select
                    :name="item.subfamily"
                    @change="saveChangeVehicle($event, index)"
                  >
                    <optgroup
                      :label="item.subfamily"
                    >
                      <option
                        v-for="(item3, index3) in machinery"
                        v-if="item.subfamily === item3.subfamily"
                        :key="index3"
                        :value="item3.item.id"
                        :title="item.name"
                        :selected="item.id === item3.item.id"
                      >
                        {{ item3.item.brand }} {{ item3.item.description }}
                        <!-- {{ machinery[index3].properties[0].value }}
                        {{ machinery[index3].properties[1].value }} -->
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
              </f7-row>
              <f7-row
                v-for="(item, index) in itemsDrivers"
                :key="'driver'+index"
                no-gap
                class="justify-content-flex-start content-accordion-machinary"
              >
                <f7-list-item
                  :title="`${$t('Editorder_driver')}` + (index+1)"
                  smart-select
                  :smart-select-params="{openIn: 'popup', searchbar: true,
                                         searchbarPlaceholder: 'Driver', closeOnSelect: true,
                                         setValueText: true , valueEl: drivers[index].name}"
                >
                  <select
                    :name="`${$t('Editorder_driver')}` + (index+1)"
                    @change="saveChangeDrivers($event, index)"
                  >
                    <optgroup
                      :label="`${$t('Editorder_driver')}` + (index+1)"
                    >
                      <option
                        v-for="(itemdriver, index3) in drivers"
                        :key="index3"
                        :value="itemdriver.id"
                        :title="itemdriver.name"
                        :selected="itemsDrivers[index].id === itemdriver.id"
                      >
                        {{ itemdriver.name }} {{ itemdriver.surname }}
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
              </f7-row>
              <f7-row
                no-gap
                class="justify-content-flex-start"
              />
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Machinery item -->
        <!-- Attachment Item -->
        <f7-list-item
          v-if="attachment_vehicles.length > 0"
          accordion-item
          :title="`${$t('Startorder_attachments')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in itemsAttachment"
                :key="'Attachment'+index"
                no-gap
                class="justify-content-flex-start content-accordion-machinary"
              >
                <f7-list-item
                  :title="item.subfamily+ ' ' + (index+1)"
                  smart-select
                  :smart-select-params="{openIn: 'popup', searchbar: true,
                                         searchbarPlaceholder: 'Attachment',
                                         setValueText: true}"
                >
                  <select
                    :name="item.subfamily"
                    @change="saveChangeAttachment($event, index)"
                  >
                    <optgroup
                      :label="item.subfamily"
                    >
                      <option
                        v-for="(item3, index3) in attachment"
                        v-if="item.subfamily === item3.subfamily"
                        :key="index3"
                        :value="item3.id"
                        :title="item.name"
                        :selected="item.id === item3.id"
                      >
                        {{ item3.item.description }} {{ item3.item.code }}
                        <!-- {{ machinery[index3].properties[0].value }}
                        {{ machinery[index3].properties[1].value }} -->
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
              </f7-row>
              <!-- <f7-row
                v-for="(item, index) in attachment_vehicles"
                :key="'vehicleAttachment'+index"
                no-gap
                class="justify-content-flex-start content-accordion-machinary"
              >
                <f7-list-item
                  :title="'VEHICLE ' + (index+1)"
                  smart-select
                  :smart-select-params="{openIn: 'popup', searchbar: true,
                                         searchbarPlaceholder: 'Vehicle',
                                         setValueText: true, valueEl: items[index].name}"
                >
                  <select
                    name="VEHICLE"
                    @change="saveChangeVehicleAttachment($event, index)"
                  >
                    <optgroup
                      label="VEHICLE"
                    >
                      <option
                        v-for="(item3, index3) in itemsMachinery"
                        :key="index3"
                        :value="item3.id"
                        :title="item3.name"
                        :selected="item.vehicle === item3.id"
                      >
                        {{ item3.name }}
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
              </f7-row> -->
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Attachment item -->
        <!-- additonal information item -->
        <f7-list-item
          v-if="comment !== ''"
          accordion-item
          :title="`${$t('Startorder_additionalInformation')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ comment }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of additional information item -->
        <!-- Product Item -->
        <f7-list-item
          v-if="products.length > 0"
          accordion-item
          :title="`${$t('Startorder_ProductTitle')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box product">
              <f7-row
                v-for="(item, index) in planner.products"
                :key="'Product'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col
                  class="enfasis"
                  width="20%"
                >
                  {{ item.product.description }}
                </f7-col>
                <f7-col
                  v-for="(item2, index2) in item.product.properties"
                  v-if="item2.key === 'family'"
                  :key="index2"
                  class="padding"
                >
                  {{ calculateDosisTotal(products[index]) }}
                </f7-col>
                <!-- <f7-col
                  width="30"
                  class="padding enfasis"
                >
                  {{ calculateTotalSolution(products[index]) }}
                </f7-col> -->
                <f7-col
                  class="padding enfasis"
                />
              </f7-row>
              <f7-row
                class="enfasis"
              >
                {{ `${$t('Start_edit_order_disolucion')} (L)` }}
              </f7-row>
              <f7-row
                class="padding"
              >
                {{ calculateTotalSolutions(products) }}
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Product item -->
        <!-- Product Item -->
        <f7-list-item
          v-if="tools.length > 0"
          accordion-item
          :title="`${$t('Startorder_Tools')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in tools"
                :key="'Tools'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col
                  v-for="(item2, index2) in item.data.properties"
                  v-if="item2.key === 'subfamily'"
                  :key="index2"
                >
                  {{ item2.value }}
                </f7-col>
                <f7-col>
                  {{ item.count }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Product item -->
        <!-- Price item -->
        <!-- Se oculta porque en Ferrero se han quejado de que no tiene sentido
             mostrar datos económicos en la app de supervisor (SFR-133)
        <f7-list-item
          v-if="task_price != 0.00"
          accordion-item
          class="accordion-off"
          :title="`${$t('Startorder_titlePrice')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("StartOrder_unitPrice") }}
                </f7-col>
                <f7-col>
                  {{ task_price }} €
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        -->
        <!-- End of Price item -->
        <!-- Nursery item -->
        <f7-list-item
          v-if="nursery.length > 0"
          accordion-item
          :title="`${$t('EditOrder_Nursery')}`"
          accordion-item-opened
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="block list-order-box">
              <f7-row
                v-for="(item, index) in nursery"
                :key="'bin'+index"
                no-gap
                class="justify-content-flex-start"
              >
                <f7-col>
                  {{ $t("EditOrder_BinCode") + (index + 1) }}
                </f7-col>
                <f7-col>
                  {{ item.code }}
                </f7-col>
              </f7-row>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Nursery item -->
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import navbar from '../components/NavBar.vue';
import img from '../static/img/useron.svg';
import img2 from '../static/img/ic_nfc.svg';
import ImageNfc2 from '../static/img/ic_nfc_2.svg';
import ImageUser from '../static/img/ic_useron.svg';
import Locations from '../js/models/Locations';
import Items from '../js/models/Items';
import TasksSync from '../js/models/TasksSync';
import Employees from '../js/models/Employees';

export default {
  name: 'EditOrder',
  components: {
    navbar,
  },
  props: {
    pathBack: { type: String, default: 'tasks/list' },
  },
  data() {
    return {
      selected: false,
      images: {
        workers: ImageUser,
        ic_nfc_2: ImageNfc2,
      },
      addLocation: [],
      locationsTree: [],
      fullNameFilter: 0,
      machinery: [],
      employees: [],
      drivers: [],
      itemsDrivers: [],
      itemsMachinery: [],
      attachment: [],
      itemsAttachment: [],
      ids: [],
      idsAttachment: [],
      value: [],
      ha: 0,
    };
  },
  computed: {
    ...mapState('TaskManager', ['id', 'workers', 'activityId', 'activity', 'task_name', 'locationName', 'locationId',
      'task_code', 'farm', 'task_unit', 'task_price', 'plan_workers', 'planner', 'items', 'comment', 'products', 'tools',
      'attachment_vehicles', 'short_id', 'planner', 'nursery']), // TODO: working area correcto, id y goal
    ...mapState('worker', ['type', 'code', 'selectedWorker']),
    ...mapState('AccessControl', ['currentFarmCode']),
    ...mapGetters('greenhouse', ['binsContentVarieties', 'binsContentCategories']),
  },

  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        this.drivers = await Employees.getDrivers();
        this.locationsTree = await Locations.getAll();
        this.machinery = await Items.getItemsByFamily('VEHICLE');
        this.attachment = await Items.getItemsByFamily('ATTACHMENT');
        this.itemsAttachment = [];
        this.itemsMachinery = [];
        this.itemsDrivers = [];
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].driver === null) {
            this.itemsDrivers.push({ id: 0 });
          }
          for (let j = 0; j < this.drivers.length; j += 1) {
            if (this.items[i].driver === this.drivers[j].id) {
              this.itemsDrivers.push({
                id: this.drivers[j].id,
                name: this.drivers[j].name,
                surname: this.drivers[j].surname,
              });
            }
          }
          for (let j = 0; j < this.machinery.length; j += 1) {
            if (this.items[i].item === this.machinery[j].id) {
              this.itemsMachinery.push({
                id: this.machinery[j].id,
                name: `${this.machinery[j].item.brand} ${this.machinery[j].item.description}`,
                subfamily: this.machinery[j].subfamily,
              });
            }
          }
        }
        for (let i = 0; i < this.attachment_vehicles.length; i += 1) {
          for (let j = 0; j < this.attachment.length; j += 1) {
            if (this.attachment_vehicles[i].attachment === this.attachment[j].id) {
              this.itemsAttachment.push({
                id: this.attachment[j].id,
                name: `${this.attachment[j].item.brand} ${this.attachment[j].item.description}`,
                subfamily: this.attachment[j].subfamily,
              });
            }
          }
        }
        for (let i = 0; i < this.locationId.length; i += 1) {
          this.checkRestChild(this.locationId[i], this.locationsTree);
          this.addLocation.push(this.locationId[i]);
        }
      } catch (error) {
        console.debug(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async saveChangeVehicle(e, indice) {
      for (let i = 0; i < this.itemsMachinery.length; i += 1) {
        // Controlo con el if si la maquinaria que estoy introduciendo ya la contiene la task,
        // si la contiene, no la setea, le muestra el error y debe seleccionar otra maquinaria
        if (e.target.options[e.target.options.selectedIndex].value === this.itemsMachinery[i].id) {
          this.$f7.dialog.alert(this.$t('editorder_noSelectSameVehicle_error'));
          break;
        }
        // aqui se setea la maquinaria cuando se selecciona el vehiculo
        // y no lo contiene la task todavia
        else if (i === indice) {
          this.itemsMachinery[i].id = e.target.options[e.target.options.selectedIndex].value;
          this.itemsMachinery[i].name = e.target.options[e.target.options.selectedIndex].title;
        }
      }
      this.rellenarIds();
      await TasksSync.updateTaskVehicles(this.id, this.addLocation, this.ids);
    },
    async saveChangeDrivers(e, indice) {
      for (let i = 0; i < this.itemsDrivers.length; i += 1) {
        if (i === indice) {
          this.itemsDrivers[i].id = e.target.options[e.target.options.selectedIndex].value;
          this.itemsDrivers[i].name = e.target.options[e.target.options.selectedIndex].title;
        }
      }
      this.rellenarIds();
      await TasksSync.updateTaskVehicles(this.id, this.addLocation, this.ids);
    },
    rellenarIds() {
      this.ids = [];
      for (let i = 0; i < this.itemsMachinery.length; i += 1) {
        const item = this.itemsMachinery[i].id;
        const driver = this.itemsDrivers[i].id;
        this.ids.push({ item, driver });
      }
    },
    async saveChangeAttachment(e, indice) {
      for (let i = 0; i < this.itemsAttachment.length; i += 1) {
        if (i === indice) {
          this.itemsAttachment[i].id = e.target.options[e.target.options.selectedIndex].value;
          this.itemsAttachment[i].name = e.target.options[e.target.options.selectedIndex].title;
        }
      }
      this.rellenarIdsAttachment();
      await TasksSync.updateTaskAttachment(this.id, this.addLocation, this.idsAttachment);
    },
    async saveChangeVehicleAttachment(e, indice) {
      for (let i = 0; i < this.itemsMachinery.length; i += 1) {
        // Controlo con el if si la maquinaria que estoy introduciendo ya la contiene la task,
        // si la contiene, no la setea, le muestra el error y debe seleccionar otra maquinaria
        if (e.target.options[e.target.options.selectedIndex].value === this.itemsMachinery[i].id) {
          this.$f7.dialog.alert(this.$t('editorder_noSelectSameVehicle_error'));
          break;
        }
        // aqui se setea la maquinaria cuando se selecciona el vehiculo
        // y no lo contiene la task todavia
        else if (i === indice) {
          this.itemsMachinery[i].id = e.target.options[e.target.options.selectedIndex].value;
          this.itemsMachinery[i].name = e.target.options[e.target.options.selectedIndex].title;
        }
      }
      this.rellenarIdsAttachment();
      await TasksSync.updateTaskAttachment(this.id, this.addLocation, this.idsAttachment);
    },
    rellenarIdsAttachment() {
      this.idsAttachment = [];
      let vehicle;
      for (let i = 0; i < this.itemsAttachment.length; i += 1) {
        vehicle = this.attachment_vehicles[i].vehicle;
        const attachment = this.itemsAttachment[i].id;
        this.idsAttachment.push({ vehicle, attachment });
      }
    },
    calculateDosisTotal(product) {
      let calcDosisTotal = (parseFloat(product.dosis) * parseFloat(this.planner.goal_performance.working_area)).toFixed(2);
      const dilutionFactor = parseFloat(product.solution) / 100;
      const unit = this.calculateUnitDosisTotal(product);

      calcDosisTotal = (!this.isKilogrameOrliter(product)) ? ((calcDosisTotal * dilutionFactor) / 1000).toFixed(2) : calcDosisTotal;
      return `${calcDosisTotal} ${unit}`;
    },
    calculateUnitDosisTotal(product) {
      if (product.dosisUt.toLowerCase().includes('gr/')
        || product.dosisUt.toLowerCase().includes('kg/')) return 'Kg';
      return 'L';
    },
    calculateTotalSolutions(products) {
      for (const product of products) {
        return parseFloat(this.calculateTotalSolution(product));
      }
      return 0;
    },
    calculateTotalSolution(product) {
      if (this.isKilogrameOrliter(product)) {
        return '0.00';
      }
      const calcTotalSol = (product.solution * this.planner.goal_performance.working_area).toFixed(2);
      return calcTotalSol;
    },
    isKilogrameOrliter(product) {
      return typeof (product.dosisUt) === 'undefined' || product.dosisUt.toLowerCase().includes('kg/') || product.dosisUt.toLowerCase().includes('l/');
    },
    isChecked(item) {
      let select = 0;
      for (let i = 0; i < item.children.length; i += 1) {
        if (this.isChecked2(item.children[i]) === true) {
          select += 1;
        }
      }
      if (select === item.children.length) {
        item.level = true;
        return true;
      }
      return false;
    },
    isChecked2(item) {
      let select = 0;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          if (this.isChecked3(item.children[i]) === true) {
            select += 1;
          }
        }
        if (select === item.children.length) {
          item.level = true;
          return true;
        }
        return false;
      }
      return this.isEquals(item, this.locationId);
    },
    isChecked3(item) {
      let select = 0;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          if (this.isChecked4(item.children[i]) === true) {
            select += 1;
          }
        }
        if (select === item.children.length) {
          item.level = true;
          return true;
        }
        return false;
      }
      return this.isEquals(item, this.locationId);
    },
    isChecked4(item) {
      let children = [];
      let select = 0;
      if (item.level === true) {
        return true;
      }
      if (item.children.length > 0) {
        children = item.children;
        for (let i = 0; i < children.length; i += 1) {
          if (this.isEquals(children[i], this.locationId) || children[i].level === true) {
            select += 1;
          }
        }
        if (select === children.length) {
          item.level = true;
          return true;
        }
        return false;
      }
      return this.isEquals(item, this.locationId);
    },
    isIndeterminate4(item) {
      let children = [];
      let cont = 0;
      let select = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (this.isEquals(children[i], this.locationId)) {
          select += 1;
        } else if (children[i].level === item.level + 1 || children[i].level === false) {
          cont += 1;
        } else {
          select += 1;
        }
      }
      if (cont > 0 && select > 0) {
        return true;
      }
      return false;
    },
    isIndeterminate(item) {
      let children = [];
      let select = 0;
      let cont = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (children[i].level === item.level + 1 || children[i].level === true) {
          if (this.isIndeterminate2(children[i]) === true) select += 1;
          else if (this.isChecked2(children[i]) === true) cont += 1;
        }
      }
      if (select > 0 || (cont > 0 && cont < children.length)) {
        return true;
      }
      return false;
    },
    isIndeterminate3(item) {
      let children = [];
      let select = 0;
      let cont = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (this.isIndeterminate4(children[i]) === true) select += 1;
        else if (this.isChecked4(children[i]) === true) cont += 1;
      }
      if (select > 0 || (cont > 0 && cont < children.length)) {
        return true;
      }
      return false;
    },
    isIndeterminate2(item) {
      let children = [];
      let select = 0;
      let count = 0;
      children = item.children;
      for (let i = 0; i < children.length; i += 1) {
        if (this.isIndeterminate3(children[i]) === true) select += 1;
        else if (this.isChecked3(children[i]) === true) count += 1;
      }
      if (select > 0 || (count > 0 && count < children.length)) return true;
      return false;
    },
    checkRestChild(item, locationsTree) {
      for (let i = 0; i < locationsTree.length; i += 1) {
        if (locationsTree[i].children.length > 0) {
          for (let j = 0; j < locationsTree[i].children.length; j += 1) {
            if (locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < locationsTree[i].children[j].children.length; k += 1) {
                if (locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < locationsTree[i].children[j].children[k].children.length; l += 1) {
                    if (locationsTree[i].children[j].children[k].children[l].children.length > 0) {
                      for (let m = 0; m < locationsTree[i].children[j].children[k].children[l].children.length; m += 1) {
                        if (locationsTree[i].children[j].children[k].children[l].children[m].bd_id === item) {
                          this.ha += locationsTree[i].children[j].children[k].children[l].children[m].area;
                        }
                      }
                    } else if (locationsTree[i].children[j].children[k].children[l].bd_id === item) {
                      this.ha += locationsTree[i].children[j].children[k].children[l].area;
                    }
                  }
                } else if (locationsTree[i].children[j].children[k].bd_id === item) {
                  this.ha += locationsTree[i].children[j].children[k].area;
                }
              }
            } else if (locationsTree[i].children[j].bd_id === item) {
              this.ha += locationsTree[i].children[j].area;
            }
          }
        } else if (locationsTree[i].bd_id === item) {
          this.ha += locationsTree[i].area;
        }
      }
    },

    // metodo que cambia la subparcel
    // o lo que es lo mismo el evento checkbox
    async addEvent(event, item5) {
      let contiene = false;
      item5.level = event;
      if (event === true) {
        for (let i = 0; i < this.locationId.length; i += 1) {
          if (this.locationId[i] === item5.bd_id) {
            contiene = true;
          }
        }
        if (contiene === false) {
          this.locationId.push(item5.bd_id);
          this.ha += item5.area;
        }
      } else {
        for (let i = 0; i < this.locationId.length; i += 1) {
          if (this.locationId[i] === item5.bd_id) {
            this.ha -= item5.area;
            this.locationId.splice(i, 1);
            if (this.locationId.length === 0) this.ha = 0;
          }
        }
      }
      this.setLocationId(this.locationId);
      await TasksSync.updateTaskLocation(this.id, this.locationId);
    },
    isEquals(item, locationId) {
      for (let i = 0; i < locationId.length; i += 1) {
        if (item.bd_id === locationId[i]) {
          return true;
        }
      }
      return false;
    },
    changeChildren4(event, item) {
      item.level = event;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          this.addEvent(event, item.children[i]);
        }
      } else {
        this.addEvent(event, item);
      }
    },
    changeChildren3(event, item) {
      item.level = event;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          this.changeChildren4(event, item.children[i]);
        }
      } else {
        this.addEvent(event, item);
      }
    },
    changeChildren2(event, item) {
      item.level = event;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          this.changeChildren3(event, item.children[i]);
        }
      } else {
        this.addEvent(event, item);
      }
    },
    changeChildren(event, item) {
      item.level = event;
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i += 1) {
          this.changeChildren2(event, item.children[i]);
        }
      } else {
        this.addEvent(event, item);
      }
    },
    photoSrc() {
      return img;
    },
    photoSrc2() {
      return img2;
    },
    navigateTo(path) {
      this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
    },
    date() {
      const aux = moment(new Date());
      return aux.format('YYYY/MM/DD');
    },
    count(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        x += item[i].count;
      }
      return x;
    },
    ...mapActions('TaskManager', ['setLocationId']),
  },
};

</script>

<style lang="scss">
.main-editorder{
  .list{
    .item-title{
      color: #FFFFFF;
    }
  }
  .enfasis {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }
  .list-order-box .row .products .col:nth-last-of-type(2) {
    max-width: 90px;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);;
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }

  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .accordion-off .triangle {
    border-top: 6px solid var(--fr-avellana);
  }

  .content-tree.treeview-item {
      margin-left: -25px;
  }
}

</style>
