<template>
  <div>
    <!-- item InProgress -->
    <div
      v-if="itemTask.status === 'IN_PROGRESS'"
      class="content-task"
    >
      <div class="list media-list chevron-center">
        <ul class="content-task-detail">
          <!-- item -->
          <li class="inprogress">
            <div class="status-type">
              <div>
                {{ $t("Status_InProgress") }}
              </div>
            </div>
            <f7-button
              class="item-link item-content margin-left"
              @click="navigateToWorkerSelection(itemTask)"
            >
              <div class="item-content">
                <div class="item-media">
                  <f7-button
                    class="icon-action"
                    @click="navigateToWorkerSelection(itemTask, true)"
                  >
                    <img
                      class="icon-middle"
                      width="68"
                      height="68"
                      :src="images.ic_stop"
                    >
                  </f7-button>
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title x2 text-color-black no-margin no-padding">
                      {{ itemTask.name }}
                      <!-- {{ itemTask.activity }} -->
                    </div>
                  </div>
                  <div class="item-subtitle">
                    <div class="item-after-location">
                      {{ getLocations(addLocation) }}
                    </div>
                    <div class="task-meta">
                      <img
                        width="20"
                        height="20"
                        :src="images.ic_worker"
                      >
                      <span v-if="itemTask.planner != null">
                        {{ countIn(itemTask.workers) }}/{{ count(itemTask.planner.workers) }}
                      </span>
                      <img
                        width="20"
                        height="20"
                        :src="images.ic_timetask"
                      >
                      <span>{{ timeOfWorked(itemTask.workers) }}</span>
                      <!-- <img
                        v-if="itemTask.comment"
                        width="20"
                        height="20"
                        :src="images.ic_alert"
                      > -->
                      <span v-if="itemTask.comment">{{ $t('AccordionItem_Notes') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </f7-button>
          </li>
          <!-- item -->
        </ul>
      </div>
    </div>
    <!-- item Open -->
    <div
      v-if="itemTask.status === 'OPEN' || itemTask.status === 'PENDING'"
      class="content-task"
    >
      <div class="list media-list chevron-center">
        <ul class="content-task-detail">
          <li class="open">
            <div class="status-type">
              <div>
                {{ $t("Status_Open") }}
              </div>
            </div>
            <f7-button
              class="item-link item-content margin-left"
              @click="popupOpened = true"
            >
              <!-- Action buttom -->
              <div class="item-content">
                <div class="item-media">
                  <f7-button
                    class="icon-action"
                    @click="navigateToStartOrder(itemTask)"
                  >
                    <img
                      class="icon-middle"
                      width="68"
                      height="68"
                      :src="images.ic_play"
                    >
                  </f7-button>
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title x2 text-color-black no-margin no-padding">
                      {{ itemTask.name }}
                      <!-- {{ itemTask.activity }} -->
                    </div>
                  </div>
                  <div class="item-subtitle">
                    <div class="item-after-location">
                      {{ getLocations(addLocation) }}
                    </div>
                    <div class="task-meta">
                      <img
                        width="20"
                        height="20"
                        :src="images.ic_worker_blue"
                      >
                      <span>
                        {{ itemTask.workers.length }}/{{ count(itemTask.planner.workers) }}
                      </span>
                      <!-- <img
                        v-if="itemTask.comment"
                        width="20"
                        height="20"
                        :src="images.ic_alert"
                      > -->
                      <span v-if="itemTask.comment">{{ $t('AccordionItem_Notes') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </f7-button>
          </li>
        </ul>
      </div>
    </div>
    <!-- item Finished -->
    <div
      v-if="itemTask.status === 'COMPLETED'"
      class="content-task"
    >
      <div class="list media-list chevron-center">
        <ul class="content-task-detail">
          <li class="finished">
            <div class="status-type">
              <div>
                {{ $t("Status_Completed") }}
              </div>
            </div>
            <a
              href="#"
              class="item-link item-content margin-left"
            >
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title x2 text-color-black no-margin no-padding">
                    {{ itemTask.name }}
                    <!-- {{ itemTask.activity }} -->
                  </div>
                </div>
                <div class="item-subtitle">
                  {{ getLocations(addLocation) }}
                  <div class="task-meta">
                    <img
                      width="20"
                      height="20"
                      :src="images.ic_worker"
                    >
                    <span v-if="itemTask.planner !== null">
                      {{ itemTask.workers.length }}/{{ count(itemTask.planner.workers) }}
                    </span>
                    <img
                      width="20"
                      height="20"
                      :src="images.ic_timetask"
                    >
                    <span>{{ timeOfWorked(itemTask.workers) }}</span>
                    <!-- <img
                      v-if="itemTask.comment"
                      width="20"
                      height="20"
                      :src="images.ic_alert"
                    > -->
                    <span v-if="itemTask.comment">{{ $t('AccordionItem_Notes') }}</span>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- item Deleted -->
    <!-- Se me ha pedido que quite el eliminar de la app
    por lo que hasta que se me confirme 100% lo comento.
      <div
      v-if="itemTask.status === 'DELETED'"
      class="content-task"
    >
      <div class="list media-list chevron-center">
        <ul class="content-task-detail">
          <li class="deleted">
            <div class="status-type">
              Deleted
            </div>
            <a
              href="#"
              class="item-link item-content margin-left"
            >
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title x2 text-color-black no-margin no-padding">
                    {{ itemTask.name }}
                  </div>
                  <div class="item-after" />
                </div>
                <div class="item-subtitle">
                  <div class="task-meta">
                    <img
                      width="20"
                      height="20"
                      :src="images.ic_worker"
                    >
                    <span>{{ itemTask.workers.length }}/{{ count(itemTask.planner.workers) }}</span>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    -->
    <!-- item Rejected -->
    <div
      v-if="itemTask.status === 'PLANNING'"
      class="content-task"
    >
      <div class="list media-list chevron-center">
        <ul class="content-task-detail">
          <li class="rejected">
            <div class="status-type">
              <div>
                {{ itemTask.status }}
              </div>
            </div>
            <a
              href="#"
              class="item-link item-content margin-left"
            >
              <!-- Action buttom -->
              <div class="item-content">
                <div class="item-media">
                  <a
                    href=""
                    class="icon-action"
                  >
                    <img
                      class="icon-middle"
                      width="68"
                      height="68"
                      :src="images.ic_play"
                    >
                  </a>
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title x2 text-color-black no-margin no-padding">
                      {{ itemTask.name }}
                    </div>
                    <div class="item-after" />
                  </div>
                  <div class="item-subtitle">
                    <div class="task-meta">
                      <img
                        width="20"
                        height="20"
                        :src="images.ic_worker"
                      >
                      <span>
                        {{ itemTask.workers.length }}/{{ count(itemTask.planner.workers) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <f7-popup
      class="demo-popup-swipe"
      swipe-to-close
      :opened="popupOpened"
      @popup:closed="popupOpened = false"
    >
      <f7-page>
        <f7-navbar
          title="Pending"
        >
          <f7-nav-right>
            <f7-link
              popup-close
              title="x"
            >
              <img
                width="38"
                height="38"
                :src="images.ic_close"
              >
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div>
          <div class="content-task-header">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title text-color-black no-margin no-padding">
                  {{ itemTask.name }}
                </div>
                <div class="item-subtitle text-color-chocolat">
                  {{ nameLocation }} ⇢
                  <strong class="text-color-nutella">{{ itemTask.activity }}</strong>
                </div>
                <div class="item-after" />
              </div>
              <div class="item-subtitle">
                <div class="task-meta">
                  <img
                    width="20"
                    height="20"
                    :src="images.ic_worker"
                  >
                  <span>{{ itemTask.workers.length }}/{{ count(itemTask.planner.workers) }}</span>
                  <!-- <img
                    width="20"
                    height="20"
                    :src="images.ic_timetask"
                  >
                  <span>{{ timeOfWorked(itemTask.workers) }}</span> -->
                </div>
              </div>
            </div>
            <div class="item-inner content-actions">
              <!-- Se me ha pedido que quite el eliminar de la app
              por lo que hasta que se me confirme 100% lo comento.
                <f7-button
                @click="deleteTask(itemTask)"
              >
                <img
                  width="48"
                  height="48"
                  :src="images.ic_delete"
                >
                <span class="text-color-red">Delete</span>
              </f7-button>-->
              <f7-button
                popup-close
                @click="navigateToEdit(itemTask)"
              >
                <img
                  width="48"
                  height="48"
                  :src="images.ic_edit"
                >
                <span class="text-color-orange">Edit</span>
              </f7-button>
            </div>
          </div>
        </div>
        <div
          style="height: 100%"
          class="display-flex justify-content-center align-items-center"
        />
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import ImageAlert from '../../static/img/ic_alert_round.svg';
import ImageTask from '../../static/img/ic_task.svg';
import ImageUser from '../../static/img/ic_useron.svg';
import ImagePlay from '../../static/img/ic_play_task.svg';
import ImageEdit from '../../static/img/ic_cambiarlg.svg';
// import ImageDelete from '../../static/img/ic_delete.svg';
import ImageTime from '../../static/img/ic_time_task.svg';
import ImageUserTask from '../../static/img/ic_user_task.svg';
import ImageUserTaskBlue from '../../static/img/ic_user_task_blue.svg';
import ImageStop from '../../static/img/ic_stop_task.svg';
import ImageClose from '../../static/img/ic_close_popup_white.svg';
import TasksSync from '../../js/models/TasksSync';
import Fitosanitary from '../../json/tasklist.json';
import Locations from '../../js/models/Locations';

export default {
  name: 'AccordionTaskItem',
  props: {
    itemTask: { type: Object, default: null },
    nameLocation: { type: String, default: '' },
  },
  data() {
    return {
      sheetOpened: false,
      popupOpened: false,
      Fitosanitary,
      locationsTree: [],
      images: {
        ic_alert: ImageAlert,
        tasks: ImageTask,
        workers: ImageUser,
        ic_stop: ImageStop,
        ic_play: ImagePlay,
        // ic_delete: ImageDelete,
        ic_edit: ImageEdit,
        ic_timetask: ImageTime,
        ic_worker: ImageUserTask,
        ic_worker_blue: ImageUserTaskBlue,
        ic_close: ImageClose,
      },
      addLocation: [],
    };
  },
  computed: {
    ...mapState('TaskManager', ['task_name', 'task_code', 'workers', 'status', 'plan_workers']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    this.locationsTree = await Locations.getAll();
    for (let i = 0; i < this.itemTask.location.length; i += 1) {
      this.checkRestChild(this.itemTask.location[i], this.locationsTree);
    }
    this.$f7.preloader.hide();
  },
  methods: {
    setVariables(itemTask) {
      this.setTaskName(itemTask.name);
      this.setTaskCode(itemTask.task_code);
      this.setWorkers(itemTask.workers);
      this.setTaskUnit(itemTask.performance.unit);
      this.setTaskPrice(itemTask.performance.price);
      this.setActivity(itemTask.activity);
      this.setActivityId(itemTask.activity_code);
      this.setId(itemTask.id);
      this.setLocationId(itemTask.location);
      this.setLocations(itemTask.location);
      this.setStatus(itemTask.status);
      this.setPlanner(itemTask.planner);
      this.setPlanWorkers(itemTask.planner.workers);
      this.setItems(itemTask.items);
      this.setAttachment(itemTask.attachment);// AQUI SE SETEARA LOS FITOS
      this.setItemsDrivers(itemTask.items_drivers);
      this.setFarm(itemTask.nameLocation);
      this.setComment(itemTask.comment);
      this.setAreaEffective(itemTask.area_effective);
      this.setTools(itemTask.tools);
      this.setProducts(itemTask.products);
      this.setAttachmentVechicles(itemTask.attachment_vehicles);
      this.setShortId(itemTask.short_id);
      this.setNursery(itemTask.nursery);
    },
    navigateToWorkerSelection(itemTask, showRegisterProductivity = false) {
      this.setVariables(itemTask);
      if (showRegisterProductivity) {
        this.changeShowRegisterProductivity();
      }
      this.$f7.views.main.router.navigate('/tasks/workersSelection/', { reloadCurrent: true });
    },
    navigateToStartOrder(itemTask) {
      this.setVariables(itemTask);
      this.$f7.views.main.router.navigate('/startOrder/', { reloadCurrent: true });
    },
    navigateToEdit(itemTask) {
      this.setVariables(itemTask);
      this.$f7.views.main.router.navigate('/edit/', { reloadCurrent: true });
    },
    /* Se me ha pedido que quite el eliminar de la app
    por lo que hasta que se me confirme 100% lo comento.
    deleteTask(itemTask) {
      const self = this;
      this.$f7.dialog.confirm(
        `${this.$t('Delete_Message')}`,
        () => {
          self.$f7.popup.close();
          // Api.deleteTask(itemTask.id);
          try {
            TasksSync.delete(itemTask);
          } catch (error) {
            console.debug(error); // TODO: tenemos que mostrar el error por pantalla.
          } finally {
            this.$f7.views.main.router.navigate('/tasks/list/', { reloadCurrent: true });
          }
        },
      );
    }, */
    checkRestChild(item, locationsTree) {
      for (let i = 0; i < locationsTree.length; i += 1) {
        if (locationsTree[i].children.length > 0) {
          for (let j = 0; j < locationsTree[i].children.length; j += 1) {
            if (locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < locationsTree[i].children[j].children.length; k += 1) {
                if (locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < locationsTree[i].children[j].children[k].children.length; l += 1) {
                    if (locationsTree[i].children[j].children[k].children[l].children.length > 0) {
                      for (let m = 0; m < locationsTree[i].children[j].children[k].children[l].children.length; m += 1) {
                        if (locationsTree[i].children[j].children[k].children[l].children[m].bd_id === item) {
                          this.addLocation.push(locationsTree[i].children[j].children[k].name);
                          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                            if (this.addLocation[n] === this.addLocation[n + 1]) {
                              this.addLocation.splice(n, 1);
                            }
                          }
                        }
                      }
                    } else if (locationsTree[i].children[j].children[k].children[l].bd_id === item) {
                      this.addLocation.push(locationsTree[i].children[j].children[k].name);
                      for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                        if (this.addLocation[n] === this.addLocation[n + 1]) {
                          this.addLocation.splice(n + 1, 1);
                        }
                      }
                    }
                  }
                } else if (locationsTree[i].children[j].children[k].bd_id === item) {
                  this.addLocation.push(locationsTree[i].children[j].children[k].name);
                  for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                    if (this.addLocation[n] === this.addLocation[n + 1]) {
                      this.addLocation.splice(n + 1, 1);
                    }
                  }
                }
              }
            } else if (locationsTree[i].children[j].bd_id === item) {
              this.addLocation.push(locationsTree[i].children[j].name);
              for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                if (this.addLocation[n] === this.addLocation[n + 1]) {
                  this.addLocation.splice(n + 1, 1);
                }
              }
            }
          }
        } else if (locationsTree[i].bd_id === item) {
          this.addLocation.push(locationsTree[i].name);
          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
            if (this.addLocation[n] === this.addLocation[n + 1]) {
              this.addLocation.splice(n, 1);
            }
          }
        }
      }
      this.addLocation = this.addLocation.filter(this.onlyUnique);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getLocations(item) {
      let aux = '';
      if (item.length > 0) {
        for (let i = 0; i < item.length; i += 1) {
          if (i === 0) aux += `${item[i]}`;
          else aux += `, ${item[i]}`;
        }
      }
      return aux;
    },
    ...mapActions('databaseSync', ['syncTasksSync']),
    ...mapActions('TaskManager', [
      'setLocations',
      'setId',
      'setLocationId',
      'setTaskName',
      'setWorkers',
      'setTaskUnit',
      'setTaskPrice',
      'setNewTask',
      'setLocationName',
      'setTaskCode',
      'setActivity',
      'setActivityId',
      'setStatus',
      'setPlanWorkers',
      'setFarm',
      'changeShowRegisterProductivity',
      'setPlanner',
      'setItems',
      'setItemsDrivers',
      'setLocations',
      'setAttachment',
      'setComment',
      'setTools',
      'setProducts',
      'setAttachmentVechicles',
      'setAreaEffective',
      'setShortId',
      'setNursery',
    ]),
    count(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        x += item[i].count;
      }
      return x;
    },
    countIn(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        if (item[i].end_date === null) {
          x += 1;
        }
      }
      return x;
    },
    timeOfWorked(workers) {
      let totalTime = 0;
      for (let i = 0; i < workers.length; i += 1) {
        const checkinDate = moment(workers[i].init_date);
        if (workers[i].end_date !== null) {
          const checkoutDate = moment(workers[i].end_date);
          const duration = moment.utc((moment.duration(checkoutDate.diff(checkinDate)).asMilliseconds()));
          if (totalTime < duration) {
            totalTime = duration;
          }
        } else {
          let checkoutDate = moment(new Date());
          checkoutDate = checkoutDate.format('YYYY-MM-DD HH:mm:ss');
          checkoutDate = moment.utc(checkoutDate);
          const duration = moment.utc((moment.duration(checkoutDate.diff(checkinDate)).asMilliseconds()));
          if (totalTime < duration) {
            totalTime = duration;
          }
        }
      }
      return moment.utc(totalTime).format('HH:mm');
    },
  },
};
</script>

<style lang="scss">
.text-color-orange {
  color: #F29500;
}

.content-task-header {
  display: flex;
  flex-direction: row;
  background: var(--f7-sheet-bg-color);
  padding: 10px 5px 10px 15px;
  border-bottom: 1px solid var(--f7-input-outline-border-color);
}

.content-task-header .item-inner:nth-of-type(1) {
  min-width: 60%;
  align-self: center;
}

.content-task-header .item-inner:nth-of-type(2) {
  min-width: 40%;
  justify-content: flex-end;
}

.content-actions {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.content-actions a {
  flex-wrap: wrap;
  max-width: 70px;
  display: flex;
}

.content-actions span {
    padding: 5px;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.02em;
}

.content-task-header .item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #2E2E2E;
  text-transform: capitalize;
  white-space: initial;
  text-align: left;
  display: flex;
  align-items: center;
}

.content-task-header .item-subtitle {
  max-width: 80%;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 3px;
}

.content-task-header .task-meta {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 5px;
  color: var(--fr-chocolat);
}

.content-task-header .task-meta span {
  display: inline-block;
  margin: 0px 20px 0px 6px;
}

.content-task-header .task-meta img {
  float: left;
}

.content-task-header .button {
  box-shadow: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  height: 68px;
}

.workers-selection .item-inner {
  padding: 0;
}

.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.change-location-btn {
  padding: 10px 5px 6px;
}
.inprogress {
  background: var(--fr-inprogress-tint)!important;
}

.pending {
    background: var(--fr-pending-tint);
    color: var(--fr-pending-shade);
}
.finished {
    background: var(--fr-finished-tint);
}
/*Se me ha pedido que quite el eliminar de la app
    por lo que hasta que se me confirme 100% lo comento.
.deleted {
    background: var(--fr-deleted-tint);
}*/
.rejected {
    background: var(--fr-rejected-tint);
}

.content-task{
  display: flex;
  margin: 5px 10px 10px!important;
  width: calc(1000%-10px);
  min-height: 115px;
  height: auto!important;
  background: white;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.14);
  border-radius: 6px ;
  padding: 0 ;
  .list{
    width: 100% ;
    display: flex;
    align-items: stretch;
    ul{
      &.content-task-detail{
        width: 100%;
        display: flex;
        align-items: stretch;
        .item-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 1.2;
          color: #2E2E2E;
          text-transform: capitalize;
          white-space: initial;
          text-align: left;
          display: flex;
          align-items: center;
        }
        .item-inner{
          .item-after-location {
            display: flex;
            font-size: 12px;
            margin-top: 0px;
            line-height: 1.45;
            font-weight: 500;
            white-space: initial;
            text-align: left;
          }
        }
      }
      &::after, &::before{
        display: none;
      }
      li{
        border-radius: 6px;
        width: 100%;
        > a{
          min-height: 115px;
          height: auto;
        }
        &.open{
          .item-subtitle{
            .task-meta{
              span{
                color: var(--ha-blue);
              }
            }
          }
        }
      }
    }
  }
}

.content-task .list .item-content {
  align-items: normal;
  width: calc(100% - 16px) !important;
  justify-content: flex-start;
  padding-left: 25px;
  border-radius: 0 6px 6px 0 !important;
  padding: 0 0 0 5px !important;
}

.content-task .button {
  box-shadow: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  height: auto;
}

.content-task .item-inner {
  width: 100%;
  padding-left: 20px;
}

.content-task-detail .item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #2E2E2E;
  text-transform: capitalize;
  white-space: initial;
  text-align: left;
  display: flex;
  align-items: center;
}

.ios .content-task-detail .item-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.content-task .task-meta {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 8px;
  color: var(--fr-chocolat);
}

.content-task .task-meta span {
  display: inline-block;
  margin: 0px 20px 0px 6px;
}

.content-task .task-meta img {
  float: left;
}

/* Deleted
.content-task-detail li.deleted .item-title {
  text-decoration: line-through;
  opacity: .5;
}*/
/*
.content-task-detail li.deleted .task-meta {
  opacity: .5;
}*/

/* In progres, Finished and Rejected */
.content-task-detail li.inprogress .item-inner,
.content-task-detail li.open .item-inner,
.content-task-detail li.rejected .item-inner {
  padding-left: 0;
}
.content-task .list li.inprogress .item-content,
.content-task .list li.open .item-content,
.content-task .list li.rejected .item-content {
  padding-left: 10px;
  width: calc(100% - 10px)!important;
}

.content-task .list .item-media + .item-inner {
  margin-left: 0;
}

.status-type{
  position: absolute;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  text-transform: uppercase;
  color: white;
  background: var(--ha-blue);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 20px;
  height: 100%;
  writing-mode: vertical-rl;
  div{
    transform: rotate(180deg);
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.inprogress .status-type {
    background: var(--fr-inprogress);
}
.pending .status-type {
    background: var(--fr-pending);
    color: var(--fr-pending-shade);
}
.finished .status-type {
    background: var(--fr-finished);
}
/*.deleted .status-type {
    background: var(--fr-deleted);
}*/
.rejected .status-type {
    background: var(--fr-rejected);
}
</style>
