import db from '../../services/Database';

export default {
  getAll() {
    return db.plaguesAndDiseases.toArray();
  },
  async getByName(filteredName = '') {
    let plagues = [];
    if (filteredName !== '') {
      plagues = await db.plaguesAndDiseases.filter(
        (element) => (
          (element.name.toUpperCase().includes(filteredName.toUpperCase())
        || element.scientific_name.toUpperCase().includes(filteredName.toUpperCase()))
        && (element.type === 'PESTS' || element.type === 'DISEASES')
        ),
      ).toArray();
    } else {
      return db.plaguesAndDiseases.toArray();
    }
    return plagues;
  },
  getWeatherConditions() {
    return db.plaguesAndDiseases.filter((element) => element.type === 'EXTREME WEATHER CONDITIONS').toArray();
  },
};
