<template>
  <f7-page
    class="content-manual-list"
    @page:beforein="beforein"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${activity} - ${$t('Form_machinery_title')}`"
    />
    <!-- :path-back="`/${pathBack}/`" -->
    <f7-block class="machinery-registration">
      <div>
        <div class="checkout-machinery-header">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title task-meta no-margin no-padding">
                {{ machineryFamily }}
              </div>
              <div class="item-subtitle text-color-chocolat" />
              <div class="item-after" />
            </div>
            <div class="item-subtitle">
              <div class="task-meta">
                <!-- <img
                    width="20"
                    height="20"
                    :src="images.ic_worker"
                > -->
                <span>{{ machineryName }}</span>
                <!-- <img
                    width="20"
                    height="20"
                    :src="images.ic_timetask"
                >
                <span>{{ timeOfWorked(workers) }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <formInputMachinery />
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import formInputMachinery from '../../components/tasks/FormInputMachinery.vue';
import Items from '../../js/models/Items';

export default {
  components: {
    navbar,
    formInputMachinery,
  },
  // props: {
  //   pathBack: { type: String, default: './' },
  // },
  data() {
    return {
      machineryName: '',
      machineryFamily: '',
    };
  },
  computed: {
    ...mapState('TaskManager', ['activity', 'id', 'task_name', 'machinery_id']),
  },
  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        const machinery = await Items.getItemsById(this.machinery_id);
        this.machineryFamily = machinery[0].subfamily;
        this.machineryName = `${machinery[0].item.description} ${this.$helpers.getProperty(machinery[0].item.properties, 'internal_code')}`;
      } catch (error) {
        this.$f7.dialog.alert(error);
        console.debug(error); // TODO: Mostrar info del error
      } finally {
        this.$f7.preloader.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.machinery-registration {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 4%;
  padding-top: 4%;
  background: var(--f7-sheet-bg-color);
}
.content-manual-list {
    background: var(--f7-sheet-bg-color);
}
.content-task-header {
  display: flex;
  flex-direction: row;
  background: var(--f7-sheet-bg-color);
  padding: 10px 15px;
  border-bottom: 1px solid var(--f7-input-outline-border-color);
}

.checkout-machinery-header .item-inner:nth-of-type(1) {
  min-width: 100%;
  align-self: center;
}
.checkout-machinery-header .item-inner:nth-of-type(2) {
  min-width: 30%;
  align-self: center;
  text-align: center;
}

.checkout-machinery-header{
  .item-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    color:var(--ha-gray);
    text-transform: capitalize;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .item-subtitle {
    max-width: 80%;
    display: flex;
    text-transform: capitalize;
    font-size: 20px;
    line-height: 1.2;
    margin-top: 3px;
    font-weight: 500;
    color: var(--fr-chocolat);
  }
}

.checkout-machinery-header .task-meta span {
  display: inline-block;
}

</style>
