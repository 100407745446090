<template>
  <f7-page
    class="content-list-order main-bins-content"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${selectedBin.code}`"
      :path-back="`${pathBack}`"
    />
    <div class="container">
      <f7-list
        v-if="loaded"
      >
        <!-- General item -->
        <div class="block strong bg-color-chocolat text-color-white">
          <f7-row>
            <f7-col>
              <div class="content-location-site">
                <img
                  class="icon-line"
                  width="16"
                  height="16"
                  :src="images.location"
                >
                <span
                  v-if="locationDestination"
                  class="location-site"
                >
                  {{ selectedBin.location }} → {{ locationDestination }}
                </span>
                <span v-else>
                  {{ selectedBin.location }}
                </span>
              </div>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <div class="content-quanty">
                <div class="quanty-item">
                  <small>{{ $t("binContent_NBins") }}</small>
                  <p>{{ selectedBin.name }}</p>
                </div>
                <div class="quanty-item">
                  <small>{{ $t("binContent_NBatch") }}</small>
                  <p>{{ getNameLote(selectedBin.lote) }}</p>
                </div>
                <div class="quanty-item">
                  <small>{{ $t("binContent_State") }}</small>
                  <a class="button bg-color-blue button-small button-fill">
                    {{ getStatusText(selectedBin.status) }}
                  </a>
                </div>
              </div>
            </f7-col>
          </f7-row>
        </div>
        <!-- End of General item -->

        <!-- Location item -->
        <f7-list-item
          v-for="(element, index) in contentBin.elements"
          :key="index"
          accordion-item
          :title="element.code"
          accordion-item-opened
          class="star-order-list__location title-color-white"
        >
          <f7-accordion-content>
            <div
              class="triangle"
            />
            <div class="list-order-box">
              <div class="card card-outline no-margin">
                <div class="card-content card-content-padding">
                  <div class="list media-list no-chevron">
                    <ul>
                      <li class="item-link item-content">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title text-color-kinder">
                              {{ getVarietyNameByCode(element.variety) }}
                            </div>
                            <div class="item-after">
                              {{ getCategoryNameByCode(element.category) }}
                            </div>
                          </div>
                          <div class="item-subtitle padding-bottom">
                            {{ getNameLocationById(element.location) }}
                          </div>
                          <div class="item-text">
                            <f7-row>
                              <f7-col class="no-padding">
                                <f7-row>
                                  {{ $t("binContent_Classification") }} {{ element.class_type }}
                                </f7-row>
                                <f7-row v-if="element.egreso">
                                  {{ $t("binContent_Egress") }} {{ getEgressNameByCode(element.egreso) }} <!-- Egreso -->
                                </f7-row>
                              </f7-col>
                              <f7-col class="text-align-right no-padding-bottom">
                                <div class="number-plant">
                                  {{ element.count }}
                                  <img
                                    class="icon-line margin-left-half"
                                    width="20"
                                    height="20"
                                    :src="images.leaf"
                                  >
                                </div>
                              </f7-col>
                            </f7-row>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </f7-accordion-content>
        </f7-list-item>
        <!-- End of Location Item -->
      </f7-list>

      <f7-button
        v-if="selectedBin.status === 'TO_RECEIVE'"
        name="btnContinue"
        class="button-toreceive botonPrimary color-green margin"
        col
        button
        button-large
        fill
        @click="setBinReceived()"
      >
        {{ $t("BinsContent_Button_btnConfirm") }}
      </f7-button>

      <f7-button
        v-if="selectedBin.status === 'RECEIVED'"
        name="btnContinue"
        class="button-received bg-color-blue margin"
        col
        button
        button-large
        fill
        @click="navigateTo('/bins/reclassify/')"
      >
        {{ $t("BinsContent_Button_btnReclassify") }}
      </f7-button>

      <f7-row
        v-if="selectedBin.status === 'REGISTERED'"
        no-gap
        class="justify-content-center margin"
      >
        <f7-col>
          <f7-button
            name="btnContinue"
            class="margin-right color-orange"
            col
            button
            fill
            @click="navigateTo('/bins/move/')"
          >
            {{ $t("BinsContent_Button_btnMove") }}
          </f7-button>
        </f7-col>
        <f7-col>
          <f7-button
            name="btnContinue"
            class="bg-color-blue"
            col
            button
            fill
            @click="navigateTo('/bins/reclassify/')"
          >
            {{ $t("BinsContent_Button_btnReclassify") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';
import Location from '../../js/models/Locations';
import Bins from '../../js/models/Bins';

import ImageLeaf from '../../static/img/ic_leaf_circle.svg';
import ImageLocation from '../../static/img/ic_place.svg';

export default {
  name: 'BinsContent',
  components: {
    navbar,
  },
  props: {
    pathBack: { type: String, default: '/bins/consult/' },
  },
  data() {
    return {
      loaded: false,
      statusTranslated: '',
      images: {
        leaf: ImageLeaf,
        location: ImageLocation,
      },
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['formattedTree', 'selectedBin', 'locationDestination', 'contentBin', 'binsContentVarieties', 'binsContentCategories', 'egressAvailable']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const destinationName = await Location.getLocationById(
        this.selectedBin.shipments[this.selectedBin.shipments.length - 1].destination,
      );
      this.setLocationDestination(destinationName);
    } catch (error) {
      console.debug(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;

    // Despues de cargar la página, si el bin seleccionado ha sido actualizado, se modifica en bbdd
    if (this.selectedBin.modified === true) {
      await this.fetchUpdateBinInDatabase();
    }
  },
  methods: {
    getNameLote(lote) {
      if (lote) {
        return lote.name;
      }
      return '';
    },
    getEgressNameByCode(codeEgress) {
      let nameEgress = '';
      const filteredElements = this.egressAvailable.filter((element) => element.code === codeEgress);
      if (filteredElements.length > 0) {
        nameEgress = filteredElements[0].name;
      }
      return nameEgress;
    },
    getNameLocationById(idLocation) {
      let nameLocation = '';
      const filteredElements = this.formattedTree.filter((element) => element.bd_id === idLocation);
      if (filteredElements.length > 0) {
        nameLocation = filteredElements[0].name;
      }
      return nameLocation;
    },
    getVarietyNameByCode(codeVariety) {
      let name = '';
      const filteredElements = this.binsContentVarieties.filter(
        (element) => element.code === codeVariety,
      );
      if (filteredElements.length > 0) {
        name = filteredElements[0].name;
      }
      return name;
    },
    getCategoryNameByCode(codeCategory) {
      let name = '';
      const filteredElements = this.binsContentCategories.filter(
        (element) => element.code === codeCategory,
      );
      if (filteredElements.length > 0) {
        name = filteredElements[0].name;
      }
      return name;
    },
    getStatusText(status) {
      this.statusTranslated = '';
      switch (status) {
        // CREATED REGISTERED SENT TO_RECEIVE RECEIVED CLOSED
        case 'CREATED':
          this.statusTranslated = this.$t('binContent_State_Created');
          break;
        case 'REGISTERED':
          this.statusTranslated = this.$t('binContent_State_Registered');
          break;
        case 'SENT':
          this.statusTranslated = this.$t('binContent_State_Sent');
          break;
        case 'TO_RECEIVE':
          this.statusTranslated = this.$t('binContent_State_ToReceive');
          break;
        case 'RECEIVED':
          this.statusTranslated = this.$t('binContent_State_Received');
          break;
        case 'CLOSED':
          this.statusTranslated = this.$t('binContent_State_Closed');
          break;
        default:
          break;
      }
      return this.statusTranslated;
    },
    setBinReceived() {
      const binchanges = this.selectedBin;
      binchanges.shipments[binchanges.shipments.length - 1].status = 'RECEIVED';
      Bins.updateBin(binchanges);
      this.$f7.views.main.router.navigate('/bins/consult/', { reloadCurrent: true });
    },
    navigateTo(path) {
      this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
    },
    ...mapActions('greenhouse', ['setLocationDestination', 'fetchUpdateBinInDatabase']),
  },
};

</script>

<style lang="scss">
.main-bins-content{
  ul{
    max-height: calc(85vh - 155px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 1px solid #ececec;
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}
.content-location-site {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px 0px;
}

.location-site {
  color: var(--fr-inprogress-tint)!important;
  font-weight: 700;
  padding: 0px 5px;
}

.content-quanty {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 10px 20px;
}
.quanty-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
.quanty-item small {
  margin: 0 3px 5px 0;
  opacity: 0.75;
  text-transform: uppercase;
}
.quanty-item p {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.12;
  margin: 3px 10px 3px 0;
  color: var(--fr-inprogress-tint);
}
.number-plant {
  font-size: 20px;
  line-height: 1.2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--fr-kinder)!important;
}
</style>
