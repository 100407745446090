<template>
  <f7-page
    class="content-list-order main-newcontent  bg-cream"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${selectedBin.code}`"
      :path-back="`${pathBack}`"
    />
    <div class="container">
      <f7-block class="content-list padding-top">
        <!-- PARENT CODE -->
        <f7-list>
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('binsNewContent_Select_ParentCode') }}
            </div>
            <f7-list-item
              :title="$t('binsNewContent_Select_ParentCode')"
              popup-close-link-text="Atras"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="parentCode"
                v-model="selectedParentCode"
                @change="changeParentCode($event.target.value)"
              >
                <option
                  v-for="(item, index) in parentCodes"
                  :key="'parentCode-'+index"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- CATEGORY -->
        <f7-list style="margin: 0px !important">
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('binsNewContent_Select_Category') }}
            </div>
            <f7-list-item
              :title="`${$t('binsNewContent_Select_Category')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="classification"
                v-model="selectedCategory"
                @change="changeCategory($event.target.value)"
              >
                <option
                  v-for="(item, index) in categories"
                  :key="'category-'+index"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- CLASSIFICATION -->
        <f7-list style="margin: 0px !important">
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('binsNewContent_Select_Classification') }}
            </div>
            <f7-list-item
              :title="`${$t('binsNewContent_Select_Classification')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true, openIn: 'popover'}"
              class="smart-border"
            >
              <select
                id="classification"
                v-model="selectedClassification"
                @change="changeClassification($event.target.value)"
              >
                <option
                  v-for="(item, index) in classifications"
                  :key="'classification-'+index"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- EGRESS -->
        <f7-list style="margin: 0px !important">
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('binContent_Egress') }}
            </div>
            <f7-list-item
              :title="`${$t('binContent_Egress')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="egress"
                v-model="selectedEgress"
                @change="changeEgress($event.target.value)"
              >
                <option
                  v-for="(item, index) in egressAvailable"
                  :key="'egress-'+index"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
      </f7-block>

      <!-- NUMPLANTAS -->
      <f7-block>
        <div class="content-list__title text-align-center">
          {{ $t("binContent_NumPlants") }}
        </div>
        <f7-stepper
          :min="0"
          :max="9999"
          :step="1"
          large
          fill
          :value="0"
          @input="changeInputStepper($event.target.value)"
        />
      </f7-block>
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green margin"
        col
        button
        button-large
        button-raised
        fill
        @click="addElement()"
      >
        {{ $t("BinsContent_Button_btnConfirm") }} <!-- TODO Save and Continue -->
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'BinsNewContent',
  components: {
    navbar,
  },
  props: {
    pathBack: { type: String, default: '/bins/reclassify/' },
  },
  data() {
    return {
      loaded: false,
      stepper: 0,
      parentCodes: [],
      classifications: [],
      categories: [],
      selectedParentCode: '',
      selectedClassification: '',
      selectedCategory: '',
      selectedEgress: null,
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['selectedBin', 'contentBin', 'binsContentVarieties', 'binsContentCategories', 'binsContentClassification', 'egressAvailable']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.contentBin.elements.forEach((element) => {
        this.parentCodes.push({
          code: element.code,
          name: `${element.code} ${this.getVarietyNameByCode(element.variety)}`,
          variety: element.variety,
          location: element.location,
          plant_year: element.plant_year,
        });
      });
      this.binsContentCategories.forEach((element) => {
        this.categories.push({ code: element.code, name: element.name });
      });
      this.binsContentClassification.forEach((element) => {
        this.classifications.push({ code: element.code, name: element.name });
      });
    } catch (error) {
      console.debug(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  methods: {
    getVarietyNameByCode(codeVariety) {
      let name = '';
      const filteredElements = this.binsContentVarieties.filter(
        (element) => element.code === codeVariety,
      );
      if (filteredElements.length > 0) {
        name = filteredElements[0].name;
      }
      return name;
    },
    changeParentCode(element) {
      this.selectedParentCode = element;
    },
    changeCategory(element) {
      this.selectedCategory = element;
    },
    changeEgress(element) {
      this.selectedEgress = element;
    },
    changeClassification(element) {
      this.selectedClassification = element;
    },
    changeInputStepper(inputValue) {
      this.stepper = inputValue;
      return inputValue;
    },
    addElement() {
      if (this.selectedParentCode === ''
        || this.selectedClassification === ''
        || this.selectedCategory === '') {
        this.$f7.dialog.alert(this.$t('binsNewContent_Alert'));
      } else {
        const codeContent = this.selectedBin.max_index + 1;
        const actualLocation = this.parentCodes.filter(
          (element) => element.code === this.selectedParentCode,
        )[0].location;
        // Si el valor del steper da error, lo pasamos a 0.
        let contador = 0;
        try {
          contador = parseInt(this.stepper, 10);
        } catch (error) {
          contador = 0;
        }
        const newElement = {
          egreso: this.selectedEgress,
          category: this.selectedCategory,
          class_type: this.selectedClassification,
          code: `${this.selectedBin.code}-${codeContent}`,
          count: contador,
          location: actualLocation,
          movements: [], // { location: actualLocation, date: moment(new Date()).format('YYYY-MM-DD') }
          parent: this.selectedParentCode,
          plant_year: this.parentCodes.filter(
            (element) => element.code === this.selectedParentCode,
          )[0].plant_year,
          variety: this.parentCodes.filter(
            (element) => element.code === this.selectedParentCode,
          )[0].variety,
        };

        // Actualizamos en vuex el maxindex del selected bin
        const newSelectedBin = this.selectedBin;
        newSelectedBin.max_index = codeContent;
        this.setSelectedBin(newSelectedBin);

        // Añadimos el nuevo contenido en vuex a contentBin
        const newContentBin = this.contentBin;
        newContentBin.elements.push(newElement);
        newContentBin.modified = true;
        this.setContentBin(newContentBin);

        this.$f7.views.main.router.navigate('/bins/reclassify/', { reloadCurrent: true });
      }
    },
    ...mapActions('greenhouse', ['setSelectedBin', 'setContentBin']),
  },
};

</script>

<style lang="scss">
.main-newcontent{
  .content-list{
    &__title{
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 5px;
      opacity: 0.7;
    }
    ul{
      &::before, &::after{
        height: 0!important;
      }
    }
    .smart-border {
      padding-bottom: 10px;
      .item-link {
        .item-content {
          padding: 0;
          .item-inner {
            background-color: #ffffff;
            min-height: 56px;
            border-radius: 8px;
            border: 1px solid #d8d8d8 !important;
            color: var(--f7-badge-bg-color);
            font-weight: 500;
            line-height: 21px;
            padding: 5px 15px;
            width: 100%;
            .item-after {
              margin-left: 0;
              padding: 0;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              color: var(--ha-darkgray);
            }
          }
          .item-title {
            display: none;
          }
        }
      }
    }
  }
  .stepper{
    display: flex;
    justify-content: center;
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}

</style>
