<template>
  <f7-block
    class="no-padding no-margin bg-color-white main-ManualListPestsAndDiseases"
  >
    <f7-searchbar
      search-container="#manualVirtual-list-pestsAndDiseases"
      search-item="li"
      search-in=".item-title, .item-header"
      :placeholder="$t('pestsAndDiseases_search_text')"
    />
    <f7-list
      v-if="vlData.items.length == 0"
      class="nothingfound"
    >
      <f7-list-item
        :title="$t('PestsAndDiseases_listItem_title')"
      />
    </f7-list>
    <f7-block class="no-padding">
      <f7-list
        id="manualVirtual-list-pestsAndDiseases"
        media-list
      >
        <f7-photo-browser
          ref="popup"
          theme="dark"
          :photos="pictures"
          type="popup"
          :routable-modals="false"
        />
        <div class="list media-list">
          <ul>
            <li  
              v-for="element in vlData.items"
              :key="element.id"
              @click="showPictures(element.photos)"
            >
              <a href="#" class="item-link item-content">
                <div class="item-media"
                  v-if="element.photos.length > 0">
                  <img
                    :src="element.photos[0]"
                    width="80"
                    height="80"
                    class="bg-photo"
                  >
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title text-color-kinder x2">{{element.name}}</div>
                  </div>
                  <div class="item-subtitle text-color-nutella">
                    <em>{{element.scientific_name}}</em>
                  </div>
                  <div class="item-text">
                    {{element.type}}
                  </div>
                  <div class="item-extra">
                    {{element.symthoms}}
                  </div>
                </div>
              </a></li>
          </ul>
        </div>
      </f7-list>
    </f7-block>
  </f7-block>
</template>

<script>
import { mapActions } from 'vuex';
import PlaguesAndDiseases from '../../js/models/plaguesAndDiseases';
// import EventBus from '../../js/event-bus';

export default {
  name: 'ManualListPestsAndDiseases',
  components: {
  },
  props: {
    callbackSelected: { type: Function, default: () => {} },
    nameCodeFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
    pestsAndDiseases: { type: Array, default: () => [] },
  },
  data() {
    return {
      code: '',
      vlData: {
        items: [],
      },
      pictures: [],
    };
  },
  computed: {
  },
  async beforeMount() {
    this.updateList();
    // EventBus.$on('refresh-taskslist', this.updateList);
  },
  beforeDestroy() {
    // EventBus.$off('refresh-taskslist');
  },
  methods: {
    async updateList() {
      this.$f7.preloader.show();
      try {
        this.vlData = { items: [] };
        this.pestsAndDiseases.length = 0;
        const xhrFormat = await PlaguesAndDiseases.getByName(this.nameCodeFilter);
        for (let i = 0; i < xhrFormat.length; i += 1) {
          this.pestsAndDiseases.push({
            name: xhrFormat[i].name,
            scientific_name: xhrFormat[i].scientific_name,
            type: xhrFormat[i].type,
            id: xhrFormat[i].id,
            photos: xhrFormat[i].photos,
            symthoms: xhrFormat[i].symthoms,
          });
        }
        this.vlData.items = this.pestsAndDiseases;
      } catch (error) {
        console.debug(error); // TODO: Mostrar este error
      } finally {
        this.$f7.preloader.hide();
      }
    },
    showPictures(elements) {
      this.pictures = elements;
      setTimeout(() => { this.$refs.popup.open(); }, 10);
    },
  },
};
</script>
<style lang="scss">
.main-ManualListPestsAndDiseases{
  .list-bins-item{
    .item-inner{
      .item-footer{
        margin-top: 10px;
      }
      .item-after{
        color: #FFFFFF;
        padding: 3px 16px;
        border-radius: 2px;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        &.created{
          background-color: #D4DCE1;
          color: #434E6F;
        }
        &.registered{
          background-color: #556080;
        }
        &.sent{
          background-color: #F5A623;
        }
        &.to_receive{
          background-color: #4A98F7;
        }
        &.received{
          background-color: #388E3C;
        }
        &.closed{
          background-color: #FF654F;
        }
      }
    }
  }
  li.list-bins-item {
      border: 1px solid #ececec;
      background: white;
      margin: 8px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
  }

  .searchbar-found {
    min-height: calc(90vh - 125px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 1px solid#ececec;
  }
  .ios .searchbar-found {
    min-height: calc(90vh - 100px) !important;
  }
  .searchbar-input-wrap {
    background: white;
    box-sizing: border-box;
    border-radius: 0px;
  }
  .searchbar {
    border-bottom: 1px solid #d8d8d8;
  }
  .searchbar-not-found {
    text-align: left;
  }
}
</style>
