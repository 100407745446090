// - General
import logo from '../static/img/logo.png';

export const ENV = process.env.NODE_ENV || 'development';
export const { TARGET } = process.env;
export const APP_VERSION = '5.7.2';
export const APPLICATION_NAME = 'ISFA';

// export const DATABASE_NAME = 'agrotareo';
export const DATABASE_NAME = 'dbferrero';
// export const CONFIG_BASE_URL = ENV === 'development' && TARGET === 'web'
//   ? LOCAL_DEV_PROXY : CONFIG_PRODUCTION_BASE;

// - Backend API
export const MILISECONDS_TO_REFRESH_AUTOMATICALLY = 1800 * 1000; // 30 minutos
export const SECONDS_BETWEEN_SCANS = 3;

// - Color Theme
export const DEFAULT_PRIMARY_COLOR = '#42526E';
export const DEFAULT_SECONDARY_COLOR = '#009BA6';

// Routes
export const APP_LOGO = logo;
