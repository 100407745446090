<template>
  <f7-page
    class="content-list-order main-casualregister  bg-cream"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${$t('LeftPanel_Subtitle_CasualRegister')}`"
      :show-button-sync="true"
    />
    <!-- DATE -->
    <div class="bg-color-kinder padding-half text-align-center">
      <span class="text-color-white">{{ getActualDate() }}</span>
    </div>
    <div class="container">
      <f7-block class="content-list padding-top">
        <!-- INCIDENT NAME -->
        <f7-list>
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('CasualRegister_Title_IncidentName') }}
            </div>
            <f7-list-input
              ref="incidentName"
              type="text"
              outline
              item-label
              clear-button
              class="smart-border margin-bottom-half"
            />
          </f7-list-group>
        </f7-list>

        <!-- TYPE -->
        <f7-list>
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('CasualRegister_Title_Type') }}
            </div>
            <f7-list-item
              :title="`${$t('CasualRegister_Title_Type')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="classification"
                v-model="selectedType"
                @change="changeType($event.target.value)"
              >
                <option
                  v-for="(item, index) in typePlagues"
                  :key="'type-'+index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <!-- LOCATION -->
        <!-- <f7-list>
          <f7-list-group>
            <div class="content-list__title">
              {{ $t('CasualRegister_Title_Location') }}
            </div>
            <f7-list-item
              :title="`${$t('CasualRegister_Title_Location')}`"
              smart-select
              :smart-select-params="{closeOnSelect: true}"
              class="smart-border"
            >
              <select
                id="classification"
                v-model="selectedCategory"
                @change="changeCategory($event.target.value)"
              >
                <option
                  v-for="(item, index) in categories"
                  :key="'category-'+index"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list> -->
        <div class="block-title margin-top margin-bottom-half">
          <span class="text-color-nutella">{{ $t('CasualRegister_Title_Location') }}</span>
        </div>
        <f7-block class="no-padding main-changelocation__treelist margin-bottom">
          <div class="card card-outline no-margin">
            <div class="card-content">
              <f7-treeview>
                <RecursiveTreeView
                  :nodes="locationTreeTreated"
                  :single-selection-enabled="true"
                  :last-level-to-display="2"
                />
              </f7-treeview>
            </div>
          </div>
        </f7-block>
      </f7-block>
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green margin-left margin-right"
        col
        button
        button-large
        button-raised
        fill
        @click="addCasualRegister()"
      >
        {{ $t("FixedMonitoringPoint_Button_Continue") }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import navbar from '../../components/NavBar.vue';
import PlaguesAndDiseases from '../../js/models/plaguesAndDiseases';
import PhenologicalStates from '../../js/models/PhenologicalStates';
import RecursiveTreeView from '../../components/nursery/RecursiveTreeView.vue';
import Families from '../../js/models/Families';

export default {
  name: 'CasualRegister',
  components: {
    navbar,
    RecursiveTreeView,
  },
  props: {
  },
  data() {
    return {
      loaded: false,
      typePlagues: [],
      selectedType: '',
      longitude: 0,
      latitude: 0,
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['locationTreeTreated', 'selectedLocations']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.syncTasksSync()
        .catch((error) => { this.$f7.dialog.alert(error); });
      this.resetScoutingStates();
      this.resetStates();
      await this.fetchLocationTree();
      const phenological = await PhenologicalStates.getAll();
      this.setPhenologicalStates(phenological);
      const plaguesDiseases = await PlaguesAndDiseases.getAll();
      const scoutingTypes = await Families.getScoutingType();
      this.typePlagues = scoutingTypes[0].values;
      this.setPlaguesAndDiseases(plaguesDiseases);

      // Establecer geolocalización
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      },
      (error) => {
        this.$f7.dialog.alert(`${error.message} - ${this.$t('formAction_Alert_EnableGPS')}`);
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  methods: {
    getActualDate() {
      return moment(new Date()).format('YYYY/MM/DD');
    },
    addCasualRegister() {
      const incidentName = this.$refs.incidentName.$refs.inputEl.value;
      if (incidentName.replace(' ', '') === '' || this.selectedType === '' || this.selectedLocations.length === 0) {
        this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
      } else if (this.longitude === 0 && this.latitude === 0) {
        this.$f7.dialog.alert(this.$t('formAction_Alert_EnableGPS'));
      } else {
        this.setActualSelection(this.selectedType.toLowerCase());
        this.setIncidentName(incidentName);
        this.setLocationSelected(this.selectedLocations[0]);
        this.setNewFormMonitoringPoint();
        this.setLatitude(this.latitude);
        this.setLongitude(this.longitude);
        this.$f7.views.main.router.navigate('/formAction/', { reloadCurrent: true });
      }
    },
    changeType(element) {
      this.selectedType = element;
    },
    ...mapActions('scouting', ['resetScoutingStates', 'setActualSelection', 'setIncidentName', 'setPhenologicalStates', 'setPlaguesAndDiseases', 'setLocationSelected', 'setNewFormMonitoringPoint', 'setLatitude', 'setLongitude']),
    ...mapActions('greenhouse', ['fetchLocationTree', 'resetStates']),
    ...mapActions('databaseSync', ['syncTasksSync']),
  },
};

</script>

<style lang="scss">
.main-casualregister{
  .content-list{
    &__title{
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 3px;
      padding-left: 15px;
      color: var(--fr-nutella);
    }
    ul{
      &::before, &::after{
        height: 0!important;
      }
    }
    .smart-border {
      padding-bottom: 0px;
      .item-link {
        .item-content {
          padding: 0;
          .item-inner {
            background-color: #ffffff;
            min-height: 56px;
            border-radius: 8px;
            border: 1px solid #d8d8d8 !important;
            color: var(--f7-badge-bg-color);
            font-weight: 500;
            line-height: 21px;
            padding: 5px 15px;
            width: 100%;
            .item-after {
              margin-left: 0;
              padding: 0;
              font-weight: 500;
              font-size: 14px;
              color: var(--fr-kinder);
            }
          }
          .item-title {
            display: none;
          }
        }
      }
    }
  }
  .stepper{
    display: flex;
    justify-content: center;
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}
.main-casualregister{
 .item-input-wrap textarea {
    background-color: #FFFFFF;
    min-height: 60px;
    border-radius: 8px;
    border: 1px solid #D8D8D8 !important;
    color: var(--fr-kinder);
    font-weight: 500;
    line-height: 21px;
    padding: 6px 12px;
    width: 100%;
    margin-bottom: 15px;
  }
  .item-content {
    padding: 0;
  }
  .item-inner {
    padding: 0;
  }
  .item-input:not(.item-input-outline) .item-input-wrap:after,
  .input:not(.input-outline):after {
    opacity: 0;
  }
  .smart-select .item-after {
    max-width: 90%;
  }
}

.main-changelocation__treelist .treeview-item-root {
  border-bottom: 1px solid #f6f6f6;
  padding: 0 5px;
}
.main-changelocation__treelist .treeview-item .treeview-item .treeview-item-root {
  padding-left: 20px;
}
.main-changelocation__treelist label.item-checkbox > .icon-checkbox {
  margin-right: 10px;
}
</style>
