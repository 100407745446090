<template>
  <f7-navbar :sliding="false">
    <button
      v-if="pathBack != '' && backWithEvent === false"
      class="button-back"
      @click="backTo"
    >
      <img
        class="ic-back"
      >
    </button>
    <button
      v-else-if="pathBack != '' && backWithEvent === true"
      class="button-back"
      @click="dispatchEvent"
    >
      <img
        class="ic-back"
      >
    </button>

    <f7-nav-left v-else>
      <f7-link
        icon-ios="f7:menu"
        icon-aurora="f7:menu"
        icon-md="material:menu"
        panel-open="left"
        icon-color="white"
        class="margin-left-half"
      />
    </f7-nav-left>
    <f7-nav-title>{{ text }}</f7-nav-title>
    <f7-nav-right sliding>
      <button
        v-if="showQrIcon"
        class="button-back"
        @click="navigateTo('/qrScanner/')"
      >
        <img
          class="ic-QrScan"
        >
      </button>
      <button
        v-if="showButtonAdd"
        class="button-back"
        @click="navigateTo('/bins/newContent/')"
      >
        <img
          class="ic-add"
        >
      </button>
      <ExportExcel
        v-if="list!=null"
        :list="list"
        :type-export="typeExport"
      />
      <ExtraElement
        v-for="(item, index) in elements"
        :key="index"
        :icon-enable="item.iconEnable"
        :text-enable="item.textEnable"
        :bagde-enable="item.badgeEnable"
        :info="item.info"
      />
      <ButtonWarningSync
        v-if="showButtonSync && syncCounterDisplay"
      />
      <buttonSync
        v-if="showButtonSync"
      />
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import { mapGetters } from 'vuex';
import ExtraElement from './extraElement.vue';
import ExportExcel from './ExportExcel.vue';
import buttonSync from './sync/ButtonSync.vue';
import EventBus from '../js/event-bus';
import ButtonWarningSync from './sync/ButtonWarningSync.vue';

export default {
  components: {
    ExtraElement,
    ExportExcel,
    buttonSync,
    ButtonWarningSync,
  },
  props: {
    elements: { type: Array, default: () => [] },
    list: { type: Array, default: () => null },
    typeExport: { type: String, default: '' },
    pathBack: { type: String, default: '' },
    text: { type: String, default: '' },
    showButtonSync: { type: Boolean, default: false },
    showQrIcon: { type: Boolean, default: false },
    showButtonAdd: { type: Boolean, default: false },
    backWithEvent: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    syncCounterDisplay() {
      return this.itemsPendingToSyncCounter.length;
    },
    ...mapGetters('databaseSync', ['itemsPendingToSyncCounter']),
  },
  methods: {
    backTo() {
      this.$f7.views.main.router.navigate(this.pathBack, {
        reloadCurrent: true,
      });
    },
    dispatchEvent() {
      EventBus.$emit('go_back_event');
    },
    navigateTo(path) {
      this.$f7.views.main.router.navigate(path, {
        reloadCurrent: true,
      });
    },
  },
};
</script>
<style scoped>
.ic-back{
  content: url("../static/img/ic_back.svg");
  zoom: 0.9;
  margin-top: 5px;
  margin-left: 5px;
}
.ic-QrScan{
  content: url("../static/img/ic_QrScan.svg");
  zoom: 0.9;
  margin-top: 5px;
  margin-left: 5px;
}
.ic-add{
  content: url("../static/img/ic_add.svg");
  zoom: 0.9;
  margin-top: 5px;
  margin-left: 5px;
}
.button-back{
  background-color: transparent;
  border: none;
  text-align: left;
  outline: 0;
  width: auto;
}
</style>
