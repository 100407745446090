const dictionary = {
  sr: {
    translation: {
      Login_txtWelcome: 'Pristup',
      Login_inpUsername_placeholder: 'Korisničko ime',
      Login_inpPassword_label: 'Lozinka',
      Login_inpPassword_placeholder: 'Lozinka',
      Login_chkRememberMe: 'Zapamti',
      Login_txtRestorePass: 'Povrati lozinku',
      Login_txtNeedHelp: 'Treba Vam pomoć?',
      Login_btnSignIn: 'PRIJAVA',
      Login_btnSignUp_question: 'Niste registrovani',
      Login_btnSignUp_link: 'Prijavite se',
      Login_msgEmail_title: 'Informacije',
      Login_msgEmail_content: 'Uskoro će te primiti mail za promenu lozinke',
      Login_msgEmail_btnClose: 'Zatvori',
      loggOut_Message: 'Da li ste sigurni da želite da izađete?',
      Delete_Message: 'Da li ste sigurni da želite da obrišete zadatak?',
      stop_task: 'Da li ste sigurni da želite da prekinete zadatak?',
      onlyStop: 'STOP',
      RecoveryPassword_txtDataAccess: 'Unesite Vaš mail',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Promeni lozinku',
      RecoveryPassword_btnGoBack: 'Nazad',
      ChangePassword_txtDataAccess: 'Unesite novu lozinku',
      ChangePassword_inpEmail_placeholder: 'Lozinka',
      ChangePassword_inpRepeatEmail_placeholder: 'Ponovite lozinku',
      ChangePassword_btnChangePassword: 'Promeni lozinku',
      CheckOutMulti_note_lable: 'Beleška',
      CheckOutMulti_note_placeholder: 'Dodirnite ovde da dodate belešku',
      CheckOutMulti_note_alert: 'Na belešku možete napisati najviše 50 karatera, trenutno postoji',
      assignedCheckOut: '<span> Dodelili ste <b> proveru</b> </span>',
      assignedCheckIn: '<span> Dodelili ste <b> prijavu</b> </span>',
      nfcManuallySearch: 'Potražite ime ili prezime radnika',
      ManualListSearch: 'Potražite ime ili prezime',
      ManualListAccesSearch: 'Pretraga',
      nfcManuallyCheckOut: 'Dodeljena odjava',
      Form_fitosanitary_quantityPlanned: 'Planirana doza',
      Form_fitosanitary_doseCollected: 'Prikupljena doza',
      Form_fitosanitary_dosisUsed: 'Doza koja se koristi',
      Form_fitosanitary_doseReturn: 'Doza vraćena u magacin',
      Form_machinery_title: 'Mašina',
      Form_fitosanitary_title: 'Fitosanitarni',
      Form_machinery_KMInicial: 'KM Start',
      Form_machinery_KMFinal: 'KM Kraj ',
      Form_machinery_horometroInicial: 'Horometro Start',
      Form_machinery_horometroFinal: 'Horometro Kraj',
      Form_machinery_literConsumed: 'Potrošeni litri',
      Form_machinery_observations: 'Zapažanja',
      Register_registerText: 'REGISTRUJ',
      Register_btnEnter: 'PRIHVATI',
      Register_inpName_name: 'IME',
      Register_inpName_placeholder: 'IME',
      Register_inpSurnames_name: 'PREZIME',
      Register_inpSurnames_placeholder: 'PREZIME',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Lozinka',
      Register_inpPassword_placeholder: 'Lozinka',
      Register_inpPasswordVerification_name: 'Ponovite lozinku',
      Register_inpPasswordVerification_placeholder: 'Ponovite lozinku',
      Register_inpUsername_name: 'Korisničko ime',
      Register_inpUsername_placeholder: 'Korisničko ime',
      Nfc_into_error_404: 'Zaposleni nije registrovan',
      Nfc_into_error_400: 'NFC format nije odgovarajući',
      Nfc_into_error_406: 'Zadatak je već kreiran na ovoj lokaciji',
      Nfc_into_error_409: 'Zaposleni već radi na drugom zadatku',
      Nfc_info_txtTitle: 'Čitam karticu',
      Nfc_info_txtSubTitle: 'Prinesite karticu radi čitanja',
      Nfc_navbarpresence_incidence: 'Prijava',
      Nfc_navbarpresence_incidence_checkout: 'Odjava',
      Nfc_navbarTask_checkout: 'Produktivnost',
      Nfc_navbarTask_startorder: 'Počni nalog',
      Editorder_title: 'Izmeni nalog',
      Editorder_driver: 'VOZAČ',
      Nfc_navbarpresence_manual: 'Unesi NFC ručno',
      Nfc_navbarpresence_btnContinue: 'Nastavi',
      NfcManually_navbarpresence_gotoproduct: 'Izaberi proizvod',
      NfcManually_navbarpresence_skipall: 'Preskoči sve',
      NfcManually_navbarpresence_btnContinue: 'Nastavi',
      NfcManually_listInput_placeholder: 'Ime ili prezime',
      LeftPanel_title_taskManagement: 'Upravljanje zadacima',
      LeftPanel_Subtitle_DAILYsUMMARY: 'Dnevni izveštaj',
      LeftPanel_Subtitle_searchworker: 'Potraži zaposlene',
      LeftPanel_Subtitle_historyboards: 'Istorija table',
      LeftPanel_title_information: 'Informacije',
      LeftPanel_Subtitle_SIGNOUT: 'Odjava',
      Profile_info_profile: 'Informacije o profilu',
      Profile_name_placeholder: 'Ime',
      Home_info_txtTitle: 'Nema zaposlenih',
      editorder_noSelectSameVehicle_error: 'Greška: Ne možete izabrati isto vozilo na dva različite selekcije, odaberit drugo vozilo',
      SearchEmployee_title: 'Pretraga zaposlenih',
      SheetExport_sheet_export_ok: 'Preuzmi fajl',
      SheetExport_sheet_export_error: 'Greška u preuzimanju',
      StartOrder_WorkOrderId: 'ID Radnog naloga ',
      StartOrder_date: 'Datum',
      StartOrder_activityName: 'Naziv aktivnosti',
      StartOrder_activityCode: 'Šifra aktivnosti',
      StartOrder_taskName: 'Ime zadatka',
      StartOrder_taskCode: 'Šifra zadatka',
      StartOrder_grouped: 'Grupisano',
      StartOrder_group: 'Grupa',
      StartOrder_shift: 'Smena',
      startorder_dosistotal: 'Doza u rastvoru',
      startorder_productname: 'Naziv',
      startorder_producttype: 'Tip',
      Startorder_ProductTitle: 'Proizvodi',
      Startorder_Tools: 'Alati',
      Startorder_attachments: 'Prolog',
      yes: 'Da',
      no: 'Ne ',
      StartOrder_productivity_unit: 'Jedinica produktivnosti',
      StartOrder_productivity_goal: 'Cilj produktivnosti',
      StartOrder_farm: 'Farma',
      StartOrder_area: 'Površina',
      StartOrder_lote: 'Lot',
      StartOrder_parcel: 'Parcela',
      StartOrder_subParcel: 'Podparcela',
      StartOrder_ha: 'Ha',
      StartOrder_machineFamily: 'Porodica mašina',
      StartOrder_machineDriver: 'Vozač',
      StartOrder_taskUnit: 'Jedinica zadatka',
      StartOrder_unitPrice: 'Jednična cena',
      Start_order: 'Izaberi zaposlene',
      Startorder_titlePrice: 'Cena',
      Startorder_titleMachinery: 'Mašina',
      Startorder_additionalInformation: 'Dodatne informacije',
      Startorder_titleLocation: 'Lokacija',
      Editorder_titleLocation: 'Lokacija - Ukupna površina =',
      Startorder_titleGeneral: 'Opšte',
      TasksList_navbar_text: 'Dnevni izveštaj',
      Hisotry_navbar_text: 'Istorija table',
      TasksList_lstNewTasks_title: 'Pokreni novi zadatak',
      TasksList_lstInProgressTasks_title: 'Zadaci u toku',
      TasksList_lstCompletedTasks_title: 'Završeni zadaci',
      TasksList_Workers_title: 'Zaposleni',
      TasksList_WorkersSelection_title: 'Broj randika na zadatku',
      TasksList_WorkersSelection_subtitle: 'Kliknite na gornje dugme da bi povezali zaposlene',
      AccordionList_workers: 'Zaposleni',
      AccordionList_worker: 'Radnik',
      Home_conductor: 'Traktorista',
      ManualList_listItem_title: 'Nije pronađen zaposleni',
      checkOut_Productivity_placeholder: 'Broj polja',
      checkOut_Productivity: 'Produktivnost',
      checkOut_Task: 'Zadatak  ',
      checkOut_observations: 'Zapažanja',
      checkOut_assigned: 'Dodeljena Vam je produktivnost za',
      checkOut_assigned_single: 'Individualni radnik',
      checkOut_assigned_multiple: 'Svi radnici',
      list_content_farm_img_alt: 'Promeni farmu',
      farmSelection_navbar_text: 'Izaberite farmu',
      locationSelect_navbar_text: 'Izaberite lokaciju',
      WorkersInTask: 'Zaposleni',
      TasksList_filterTask_title: 'Izaberite zadatak',
      workersSelection_buttomPanelConfig_multiple: 'Više radnika',
      workersSelection_buttomPanelConfig_individual: 'Individualna dodela',
      SyncingModal_infoUpdate_title: 'Ažuriranje podataka',
      SyncingModal_infoUpdate_text: 'inhronizacija sa serverom je neophodna da biste mogli da nastavite da radite čak i van mreže',
      SyncingModal_infoLoading_title: 'Učitavam podatke…',
      SyncingModal_infoLoading_text: 'Ovaj postupak može potrajati nekoliko minuta, u zavisnosti od brzine veze uređaja.',
      SyncingModal_txtError: 'Greška.',
      SyncingModal_btnSync: 'Sinhronizuj',
      Status_Open: 'NEREŠEN',
      Status_InProgress: 'U TOKU',
      Status_Completed: 'ZAVRŠENO',
      EditOrder_BinCode: 'Bin-Code',
      EditOrder_Nursery: 'Nursery',
      LeftPanel_title_scouting: 'Izviđanje',
      LeftPanel_Subtitle_PestsAndDiseases: 'Štetnici i bolesti',
      LeftPanel_Subtitle_PhenologicalStates: 'Fenološka stanja',
      LeftPanel_Subtitle_FixedMonitoringPoint: 'Fiksna točka za nadzor',
      LeftPanel_Subtitle_CasualRegister: 'Slučajni registar',
      pestsAndDiseases_search_text: 'Ime štetnika ili bolesti',
      pestsAndDiseases_listInput_placeholder: 'Ime',
      PestsAndDiseases_listItem_title: 'Nisu pronađene štetočine i bolesti',
      pestAndDiseasesContent_title_ImageDescription: 'Opis slike',
      CasualRegister_Title_Date: 'Datum',
      CasualRegister_Title_IncidentName: 'Ime incidenta',
      CasualRegister_Title_Type: 'Tip',
      CasualRegister_Title_Species: 'Vrsta',
      CasualRegister_Title_Comments: 'Komentari',
      CasualRegister_Title_Location: 'Mjesto',
      CasualRegister_Button_Finish: 'Završi',
      FixedMonitoringPoint_Title_MonitoringPoint: 'Točka za nadzor',
      FixedMonitoringPoint_Button_Continue: 'Nastaviti',
      SelectAction_Title: 'Odaberite Akcija',
      formAction_Title_Pests: 'Štetočine',
      formAction_Title_Diseases: 'Bolesti',
      formAction_Title_Phenological: 'Fenologija',
      formAction_Title_WeatherConditions: 'Ekstremni vremenski uvjeti',
      formAction_Title_Other: 'Drugi',
      SelectAction_Button_Finish: 'Završi',
      Pests_Phenological_State_of_the_Crop: 'Fenološko stanje usjeva',
      Pests_Species: 'Vrsta',
      Pests_State_of_Development: 'Stanje razvoja',
      Pests_Comments: 'Komentari',
      Pests_NDetected: 'Nº Otkriveno',
      Pests_Next: 'Sljedeći',
      Pests_Finish: 'Završi',
      Pests_TakePicture: 'Uslikaj',
      ExtremeWeatherConditions_Type: 'Tip',
      phenologicalStates_search_text: 'Fenološki državni kod ili naziv',
      phenologicalStates_listInput_placeholder: 'Ime ili kod',
      phenologicalStates_listItem_title: 'Nije pronađeno nijedno fenološko stanje',
      formAction_Dialog_Confirm: 'Jeste li sigurni da se želite vratiti? Ako izađete, neće se spremiti dodani podaci.',
      formAction_Alert_MissingData: 'Nedostaju podaci za popunjavanje',
      formAction_Dialog_Finish: 'Ako završite, elementi trenutnog zaslona neće se spremiti, ali će se sačuvati do sada dodani elementi. Jesi li siguran?',
      AccordionItem_Notes: 'Bilješke',
      TakePicture_ConfirmDeletePicture: 'Jeste li sigurni da želite izbrisati ovu sliku?',
      pestAndDiseases_ViewAll: 'Pogledaj sve',
    },
  },
};
export default dictionary;
