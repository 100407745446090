import Api from './Api';
import db from './Database';
import EventBus from '../js/event-bus';
import Bins from '../js/models/Bins';
import ScoutingPoint from '../js/models/ScoutingPoint';
import ModifiedRecords from '../js/models/ModifiedRecords';

export default {
  // TODO: Revisar el parámetro que le llega.
  downloadNewData() {
    return Promise.all([
      Api.getAllRecords('sync/tasks/definition'),
      Api.getAllRecords('sync/tasks'),
      Api.getAllRecords('sync/employees'),
      Api.getAllRecords('sync/locations/tree'),
      Api.getAllRecords('sync/tasks/activities'),
      Api.getAllRecords('sync/keyvalues'),
      Api.getAllRecords('sync/items?type=MACHINERY'),
      // Api.getAllRecords('sync/items?type=PRODCUT'),
      Api.getAllRecords('sync/bins'),
      Api.getAllRecords('nursery/variety'),
      Api.getAllRecords('nursery/category'),
      Api.getAllRecords('/nursery/classtype'),
      Api.getAllRecords('plagues_and_disease'),
      Api.getAllRecords('phenological_phase'),
      Api.getAllRecords('scouting_point'),
      Api.getAllRecords('nursery/egreso'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
          db.tasksDefinition.bulkPut(values[0]);
        }
        if (values[1] !== 0) {
          db.tasksSync.clear();
          db.tasksSync.bulkPut(values[1]);
        }
        if (values[2] !== 0) {
          db.employees.clear();
          db.employees.bulkPut(values[2]);
        }
        if (values[3] !== 0) {
          db.locations.bulkPut(values[3]);
        }
        if (values[4] !== 0) {
          db.tasksActivities.bulkPut(values[4]);
        }
        if (values[5] !== 0) {
          db.families.bulkPut(values[5]);
        }
        if (values[6] !== 0) {
          db.items.bulkPut(values[6]);
        }
        if (values[7] !== 0) {
          db.bins.bulkPut(values[7]);
        }
        if (values[8] !== 0) {
          db.binsVariety.bulkPut(values[8]);
        }
        if (values[9] !== 0) {
          db.binsCategory.bulkPut(values[9]);
        }
        if (values[10] !== 0) {
          db.binsClassification.bulkPut(values[10]);
        }
        if (values[11] !== 0) {
          db.plaguesAndDiseases.bulkPut(values[11]);
        }
        if (values[12] !== 0) {
          db.phenologicalStates.bulkPut(values[12]);
        }
        if (values[13] !== 0) {
          db.scoutingPoint.bulkPut(values[13]);
        }
        if (values[14] !== 0) {
          db.egress.bulkPut(values[14]);
        }
        return values;
      });
  },

  downloadTasksSync() {
    return Promise.all([
      Api.getAllRecords('sync/tasks'),
      Api.getAllRecords('bins'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
          db.tasksSync.clear();
          db.bins.clear();
          db.scoutingForm.clear();
          db.tasksSync.bulkPut(values[0]);
          db.bins.bulkPut(values[1]);
        }
      });
  },

  downloadSyncData() {
    return Promise.all([
      Api.getAllRecords('sync/tasks'),
      Api.getAllRecords('bins'),
      Api.getAllRecords('sync/employees'),
      Api.getAllRecords('scouting_point'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
          db.tasksSync.clear();
          db.tasksSync.bulkPut(values[0]);
        }
        if (values[1] !== 0) {
          db.bins.clear();
          db.scoutingForm.clear();
          db.bins.bulkPut(values[1]);
        }
        if (values[2] !== 0) {
          db.employees.clear();
          db.employees.bulkPut(values[2]);
        }
        if (values[3] !== 0) {
          db.scoutingPoint.clear();
          db.scoutingPoint.bulkPut(values[3]);
        }
      });
  },

  clearDatabase() {
    db.employees.clear();
    db.families.clear();
    db.items.clear();
    db.locations.clear();
    db.modifiedRecords.clear();
    db.tasksActivities.clear();
    db.tasksDefinition.clear();
    db.tasksSync.clear();
    db.bins.clear();
    db.binsVariety.clear();
    db.binsCategory.clear();
    db.binsClassification.clear();
    db.plaguesAndDiseases.clear();
    db.phenologicalStates.clear();
    db.scoutingPoint.clear();
    db.scoutingForm.clear();
    db.egress.clear();
  },

  async uploadPendingData(uuid) {
    const deleted = await db.modifiedRecords.where({ type: 'taskDeleted' }).toArray();
    for (let i = 0; i < deleted.length; i += 1) {
      Api.deleteTask(deleted[i].idTask);
    }
    const filterTasks = await db.tasksSync.toArray();
    const responseTasks = await Api.sendChangesRecords('sync/tasks', filterTasks, uuid);
    const filterBins = await Bins.getModifiedBins();
    const responseBins = await Api.sendChangesRecords('sync/bins', filterBins, uuid);
    const scoutingForms = await ScoutingPoint.getAllScoutingFormsToSync();
    const responseScoutingForms = await Api.sendChangesRecords('sync/scouting', { data: scoutingForms }, uuid);

    // Se controlan las excepciones de la sincronización
    if (responseTasks === 204 && responseBins === 204 && responseScoutingForms === 204) {
      EventBus.$emit('SetNew_SyncErrorText', '');
      return 204;
    }
    return 'Error de uploadPendingData';
  },

  async syncWithUpstream(uuid) {
    const countElementsToUpload = await ModifiedRecords.countElement();
    if (countElementsToUpload === 0) {
      return this.downloadNewData().catch(
        (e) => {
          console.log('Error DEL FRONT:', e);
          const jsonError = JSON.stringify({
            error_code: e,
          });
          EventBus.$emit('SetNew_SyncErrorText', jsonError);
          return [0];
        },
      ).finally(() => EventBus.$emit('refresh-taskslist', true));
    }
    try {
      const response = await this.uploadPendingData(uuid);
      if (response === 204) {
        db.modifiedRecords.clear();
        this.downloadNewData().finally(() => EventBus.$emit('refresh-taskslist', true));
      }
    } catch (e) {
      console.log('Error DEL FRONT:', e);
      const jsonError = JSON.stringify({
        error_code: e,
      });
      EventBus.$emit('SetNew_SyncErrorText', jsonError);
    }
    return [0];
  },

  async syncTasksSync(uuid) {
    const countElementsToUpload = await ModifiedRecords.countElement();
    if (countElementsToUpload === 0) {
      return this.downloadTasksSync().finally(() => EventBus.$emit('refresh-taskslist', true));
    }
    try {
      const response = await this.uploadPendingData(uuid);
      if (response === 204) {
        db.modifiedRecords.clear();
        this.downloadTasksSync().finally(() => EventBus.$emit('refresh-taskslist', true));
      }
    } catch (e) {
      console.log('Error DEL FRONT:', e);
      const jsonError = JSON.stringify({
        error_code: e,
      });
      EventBus.$emit('SetNew_SyncErrorText', jsonError);
    }
    return countElementsToUpload;
  },

  async forceSync(uuid) {
    const countElementsToUpload = await ModifiedRecords.countElement();
    if (countElementsToUpload === 0) {
      return this.downloadSyncData().finally(() => EventBus.$emit('refresh-taskslist', true));
    }
    try {
      const response = await this.uploadPendingData(uuid);
      if (response === 204) {
        db.modifiedRecords.clear();
        this.downloadSyncData().finally(() => EventBus.$emit('refresh-taskslist', true));
      }
    } catch (e) {
      console.log('Error DEL FRONT:', e);
      const jsonError = JSON.stringify({
        error_code: e,
      });
      EventBus.$emit('SetNew_SyncErrorText', jsonError);
    }
    return countElementsToUpload;
  },
};
